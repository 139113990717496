export const SEND_TRIGGERS_QUALTRICS = {
  QSI_POLICY_OTHER_BRANCH_COLLECTIVE: 'QSI_POLICY_OTHER_BRANCH_COLLECTIVE',
  QSI_POLICY_OTHER_BRANCH_INDIVIDUAL: 'QSI_POLICY_OTHER_BRANCH_INDIVIDUAL',
  QSI_SINGLE_CAR: 'QSI_SINGLE_CAR',
  QSI_SINGLE_MOD_PERSON: 'QSI_SINGLE_MOD_PERSON',
  QSI_SINGLE_MOD_AMPAROS: 'QSI_SINGLE_MOD_AMPAROS',
  QSI_SINGLE_MOD_RISK: 'QSI_SINGLE_MOD_RISK',
  QSI_SINGLE_ANNUL_POLICY: 'QSI_SINGLE_ANNUL_POLICY',
  QSI_CERTIFICATE_DETAIL: 'QSI_CERTIFICATE_DETAIL',
  QSI_DOWNLOAD_PDF: 'QSI_DOWNLOAD_PDF',
  QSI_DOWNLOAD_PRIOR_RENEW: 'QSI_DOWNLOAD_PRIOR_RENEW',
  QSI_RENEW_POLICY_SINGLE_CAR: 'QSI_RENEW_POLICY_SINGLE_CAR',
  QSI_RENEW_POLICY_OTHER_BRANCH: 'QSI_RENEW_POLICY_OTHER_BRANCH',
  QSI_ANNULMENT_POLICY_OTHER_BRANCH: 'QSI_ANNULMENT_POLICY_OTHER_BRANCH',
  QSI_ANNULMENT_POLICY_AUTOS: 'QSI_ANNULMENT_POLICY_AUTOS',
  QSI_MODIFY_PERSON_POLICY_OTHER_BRANCH: 'QSI_MODIFY_PERSON_POLICY_OTHER_BRANCH'
};
