import {
  DETAIL_ROUTES_BASE,
  INFORMATION_POLICIES,
  INFO_DETAIL_ROUTES,
  RoutesBase,
  CERTIFICATE_DETAIL
} from './baseRoutes';

const QUERIES_INFO = `${RoutesBase.QUERIES}/${INFORMATION_POLICIES}`;
const QUERIES_INFO_DETAIL = `${QUERIES_INFO}/${DETAIL_ROUTES_BASE.SINGLE_CARS_DETAIL}`;
const QUERIES_ANNULMENT_POLICY = `${QUERIES_INFO}/${DETAIL_ROUTES_BASE.ANNULMENT_POLICY}`;
const QUERIES_TRACKING = `${QUERIES_INFO}/${DETAIL_ROUTES_BASE.TRACKING_POLICY}`;
const QUERIES_INFO_POLICIES = `${QUERIES_INFO}/${DETAIL_ROUTES_BASE.OTHER_BRANCHES_DETAIL}`;
const QUERIES_INFO_POLICIES_CERTIFICATE = `${QUERIES_INFO_POLICIES}/${CERTIFICATE_DETAIL}`;
const QUERIES_ANNULMENT_POLICY_OTHER_BRANCHES = `${QUERIES_INFO}/${DETAIL_ROUTES_BASE.ANNULMENT_POLICY_OTHER_BRANCHES}`;

export const QueriesRoutes = {
  QUERIES_INFO,
  QUERIES_INFO_DETAIL,
  QUERIES_TRACKING,
  QUERIES_ANNULMENT_POLICY,
  QUERIES_ANNULMENT_POLICY_OTHER_BRANCHES,
  QUERIES_INFO_DETAIL_ROUTES: INFO_DETAIL_ROUTES,
  QUERIES_INFO_DETAIL_MOVEMENTS: `${QUERIES_INFO_DETAIL}/${INFO_DETAIL_ROUTES.MOVEMENTS}`,
  QUERIES_INFO_DETAIL_CERTIFICATE: `${QUERIES_INFO_DETAIL}/${INFO_DETAIL_ROUTES.CERTIFICATE}`,
  QUERIES_INFO_DETAIL_PERSONS: `${QUERIES_INFO_DETAIL}/${INFO_DETAIL_ROUTES.PERSONS}`,
  QUERIES_INFO_DETAIL_RISKS: `${QUERIES_INFO_DETAIL}/${INFO_DETAIL_ROUTES.RISKS}`,
  QUERIES_INFO_DETAIL_COVERAGES: `${QUERIES_INFO_DETAIL}/${INFO_DETAIL_ROUTES.COVERAGES}`,
  QUERIES_INFO_DETAIL_CLAIMS: `${QUERIES_INFO_DETAIL}/${INFO_DETAIL_ROUTES.CLAIMS}`,
  QUERIES_INFO_DETAIL_MOD_TAKER: `${QUERIES_INFO_DETAIL}/${INFO_DETAIL_ROUTES.MOD_TAKER}`,
  QUERIES_INFO_POLICIES_MOD_TAKER: `${QUERIES_INFO_POLICIES}/${INFO_DETAIL_ROUTES.MOD_TAKER}`,
  QUERIES_INFO_DETAIL_MOD_INSURED: `${QUERIES_INFO_DETAIL}/${INFO_DETAIL_ROUTES.MOD_INSURED}`,
  QUERIES_INFO_DETAIL_PLATE: `${QUERIES_INFO_DETAIL}/${INFO_DETAIL_ROUTES.PLATE}`,
  QUERIES_INFO_DETAIL_ACCESSORY: `${QUERIES_INFO_DETAIL}/${INFO_DETAIL_ROUTES.ACCESSORY}`,
  QUERIES_INFO_DETAIL_AMPAROS: `${QUERIES_INFO_DETAIL}/${INFO_DETAIL_ROUTES.AMPAROS}`,
  QUERIES_INFO_DETAIL_RISKS_DATA: `${QUERIES_INFO_DETAIL}/${INFO_DETAIL_ROUTES.RISKS_DATA}`,
  QUERIES_INFO_POLICIES,
  QUERIES_INFO_POLICIES_MOVIMIENTOS: `${QUERIES_INFO_POLICIES}/${INFO_DETAIL_ROUTES.MOVEMENTS}`,
  QUERIES_INFO_POLICIES_PERSONS: `${QUERIES_INFO_POLICIES}/${INFO_DETAIL_ROUTES.PERSONS}`,
  QUERIES_INFO_POLICIES_CERTIFICATE,
  QUERIES_INFO_POLICIES_CERTIFICATE_MOVIMIENTOS: `${QUERIES_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.MOVEMENTS}`,
  QUERIES_INFO_POLICIES_CERTIFICATE_PERSONS: `${QUERIES_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.PERSONS}`,
  QUERIES_INFO_POLICIES_CERTIFICATE_RISKS: `${QUERIES_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.RISKS}`,
  QUERIES_INFO_POLICIES_CERTIFICATE_COVERAGES: `${QUERIES_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.COVERAGES}`,
  QUERIES_INFO_POLICIES_CERTIFICATE_CLAIMS: `${QUERIES_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.CLAIMS}`
};
