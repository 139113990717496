const expRegEmailOutlookHotmail =
  /^[a-z][a-z0-9_.-]{0,62}[a-z0-9_-]{0,1}(?<!\.)@(?:outlook|hotmail)(\.[a-z]{2,})+$/;

const expRegEmailYahoo = /^[a-z][a-z0-9_.]{0,62}[a-z0-9]{0,1}(?<![_.])@yahoo(\.[a-z]{2,})+$/;

const expRegEmailGmail = /^[a-z0-9][a-z0-9.]{0,62}[a-z0-9]{0,1}(?<!\.)@gmail(\.[a-z]{2,})+$/;

const expRegEmailOthers =
  /^[a-z0-9!#$%&'*\\+/=?^_`{|}~-][a-z0-9!.#$%&'*\\+/=?^_`{|}~-]{0,62}[a-z0-9!#$%&'*\\+/=?^_`{|}~-]{0,1}@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2,}$/;

export const verifyEmail = (email = '') => {
  email = email.toLowerCase();
  if (!email || email.includes('..') || email.includes('ñ')) {
    return !email;
  }

  const [prefix, domain] = email.split('@');
  if (!prefix || !domain || prefix.length > 64 || domain.length > 255) {
    return false;
  }

  const domainKey = '@' + domain.split('.')[0];
  const getDomainSpecificRegex = (domainKey, prefix) => {
    switch (domainKey) {
      case '@outlook':
      case '@hotmail':
        return expRegEmailOutlookHotmail;
      case '@yahoo':
        if (prefix.includes('..') || prefix.includes('__')) {
          return false;
        } else {
          return expRegEmailYahoo;
        }
      case '@gmail':
        return expRegEmailGmail;
      default:
        return expRegEmailOthers;
    }
  };
  const regex = getDomainSpecificRegex(domainKey, prefix);
  return !!email.match(regex);
};
