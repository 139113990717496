import {
  makeStyles,
  Tooltip as TooltipComponent,
  TooltipProps as TooltipPropsBase
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

interface TooltipProps extends Partial<TooltipPropsBase> {
  container: any;
  placement?: any;
  open?: boolean;
  className?: string;
  arrowClassName?: string;
  setOpen?: (state: boolean) => void;
  padding?: string;
}

const LibertyTooltip = ({
  children = null,
  container,
  placement = null,
  open,
  className = null,
  arrowClassName = null,
  setOpen,
  padding = '15px !important',
  ...props
}: TooltipProps) => {
  const useStyles = makeStyles(() => ({
    customTooltip: {
      zIndex: 1000,
      backgroundColor: '#1E1045',
      borderRadius: '3px',
      padding,
      boxSizing: 'border-box !important' as any,
      boxShadow: '1px 1px 5px 2px rgba(0,0,0,0.10)',
      fontSize: '14px',
      lineHeight: '22px',
      textAlign: 'justify',
      maxWidth: 'fit-content !important',
      maxHeigth: 'fit-content !important'
    },
    customArrow: {
      color: '#1E1045'
    }
  }));

  const classes = useStyles();

  function onClose() {
    setOpen?.(false);
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <TooltipComponent
        arrow
        interactive
        enterTouchDelay={0}
        disableFocusListener={false}
        disableHoverListener={false}
        disableTouchListener={false}
        open={open}
        placement={placement}
        title={container}
        classes={{
          tooltip: `${classes.customTooltip} ${className}`,
          arrow: `${classes.customArrow} ${arrowClassName}`
        }}
        {...props}
      >
        {children}
      </TooltipComponent>
    </ClickAwayListener>
  );
};

export default LibertyTooltip;
