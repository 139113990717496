export enum IdTypes {
  taker = 'Tomador',
  insured = 'Asegurado'
}

export enum IdOptions {
  diplomaticCard = 'C.D.',
  idCard = 'C.C.',
  foreignerID = 'C.E.',
  nit = 'NIT',
  foreignerNIT = 'NIT E.',
  nuip = 'N.U.I.P.',
  passport = 'P.P.',
  ppt = 'P.P.T.'
}
