import { lazy, useMemo } from 'react';
import { PathRoutes } from 'common/routes';
import useBreadcrumbInformation from './useBreadcrumbInformation';

const Renovations = lazy(() => import('pages/Renovations'));
const RenovationsInfo = lazy(() => import('pages/RenovationsInfo'));
const SingleCarsDetail = lazy(() => import('pages/SingleCarsDetail/SingleCarsDetail'));
const OtherBranchDetail = lazy(() => import('pages/OtherBranchDetail/OtherBranchDetail'));
const CertificateDetail = lazy(() => import('pages/CertificateDetail/CertificateDetail'));
const AnnulmentPolicy = lazy(() => import('pages/AnnulmentPolicy/AnnulmentPolicy'));
const AnnulmentPolicyOtherBranch = lazy(() => import('pages/AnnulmentPolicyOtherBranches/AnnulmentPolicy'));
const Tracking = lazy(() => import('pages/Tracking/Tracking'));

export const useRenovationsRoutes = () => {
  const { policyNumber, certificateNumber } = useBreadcrumbInformation();
  return useMemo(
    () => [
      {
        path: PathRoutes.RENOVATIONS,
        exact: true,
        name: `Gestión de renovaciones`,
        title: `Gestión de renovaciones`,
        pathToGo: () => {
          return PathRoutes.RENOVATIONS;
        },
        element: Renovations
      },
      {
        path: PathRoutes.RENOVATIONS_INFO,
        exact: true,
        name: `Información de pólizas`,
        title: `Información de pólizas`,
        pathToGo: () => {
          return PathRoutes.RENOVATIONS_INFO;
        },
        element: RenovationsInfo
      },
      {
        path: PathRoutes.RENOVATIONS_INFO_DETAIL + '/*',
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.RENOVATIONS_INFO_DETAIL_MOVEMENTS;
        },
        element: SingleCarsDetail
      },
      {
        path: PathRoutes.RENOVATIONS_INFO_POLICIES + '/*',
        exact: true,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.RENOVATIONS_INFO_POLICIES;
        },
        element: OtherBranchDetail
      },
      {
        path: PathRoutes.RENOVATIONS_INFO_POLICIES_CERTIFICATE + '/*',
        exact: true,
        name: `Certificado ${certificateNumber}`,
        title: `Certificado ${certificateNumber}`,
        pathToGo: () => {
          return PathRoutes.RENOVATIONS_INFO_POLICIES_CERTIFICATE;
        },
        element: CertificateDetail
      },
      {
        path: PathRoutes.RENOVATIONS_ANNULMENT_POLICY,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.RENOVATIONS_INFO_DETAIL_MOVEMENTS;
        },
        element: AnnulmentPolicy
      },
      {
        path: PathRoutes.RENOVATIONS_ANNULMENT_POLICY_OTHER_BRANCHES,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.RENOVATIONS_ANNULMENT_POLICY_OTHER_BRANCHES;
        },
        element: AnnulmentPolicyOtherBranch
      },
      {
        path: PathRoutes.RENOVATIONS_TRACKING,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.RENOVATIONS_INFO_DETAIL_CLAIMS;
        },
        element: Tracking
      }
    ],
    [policyNumber, certificateNumber]
  );
};
