import { Suspense, useMemo } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import LibertyLoader from 'components/basics/LibertyLoader/LibertyLoader';
import { useSession } from 'context/SessionContext';
import LibertyPage from 'layout/LibertyPage';
import { useRoutes } from './context';
import { PathRoutes } from 'common/constants';
import NotFound from 'components/NotFound';
import LibertyBreadcrumbs from 'components/basics/LibertyBreadcrumbs/LibertyBreadcrumbs';
import LibertyBreadcrumbsResponsive from 'components/basics/LibertyBreadcrumbsResponsive/LibertyBreadcrumbsResponsive';

const InnerRouter = () => {
  const { routes } = useRoutes();
  const { loading, session, logout } = useSession();

  const user = useMemo(() => {
    if (!session) return;
    else if (session.dynamoData?.message == 'Data Obtained Successfully') {
      return {
        ...session.dynamoData.session_data,
        last_session: new Date(session.dynamoData.session_data.last_session),
        name: session.name,
        ip: session.ip
      };
    } else {
      return { ...session, last_session: new Date() };
    }
  }, [session]);

  if (loading) return <LibertyLoader />;

  return (
    <Suspense fallback={<LibertyLoader />}>
      <Routes>
        {routes?.map((route) => (
          <Route
            key={route.name}
            path={route.path}
            element={
              <LibertyPage user={user}>
                <div
                  className="liberty__container"
                  style={{
                    position: 'absolute',
                    margin: '27px 0',
                    zIndex: '100',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <LibertyBreadcrumbs location={location} />
                  <LibertyBreadcrumbsResponsive location={location} />
                </div>

                <route.element />
              </LibertyPage>
            }
          />
        ))}
        <Route path={PathRoutes.MAIN} element={<Navigate to={PathRoutes.QUERIES} replace />} />
        <Route
          path={PathRoutes.NOT_FOUND}
          element={
            <div>
              <NotFound onLogout={() => logout()} />
            </div>
          }
        />
        <Route path="*" element={<Navigate to={PathRoutes.QUERIES} replace />} />
      </Routes>
    </Suspense>
  );
};

export default InnerRouter;
