import { Types } from 'components/collections/Notifications/constants';
import { RenovationNotification } from './modules';
import { QueryNotification } from './modules/QueryNotification/QueryNotification';
import { NotificationUnion } from 'components/collections/Notifications/types';
import { NotificationDownloadPDF } from './modules/QueryNotification/othersNotifications/NotificationDownloadPDF';

export interface NotificationProps {
  notification: NotificationUnion;
}

export const Notification = ({ notification }: NotificationProps) => {
  if (notification.type === Types.RENOVATIONS) {
    const { data } = notification;
    return <RenovationNotification renovation={data} />;
  }
  if (notification.type === Types.NOTIFICATION) {
    const { data } = notification;
    return data.type == 'COLLECTIVE_PDF_DOWNLOAD_REPORT' ? (
      <NotificationDownloadPDF notification={data} />
    ) : (
      <QueryNotification notification={data} />
    );
  }
  return null;
};
