import { createSlice } from '@reduxjs/toolkit';
import { fetchLoadCarousel, fetchLoadSearch } from './ActionsConsultationSlice';

const initialState = {
  search: {
    isLoading: false,
    query: null,
    results: [],
    totalResults: 0,
    totalPages: 0,
    error: null
  },
  carousel: {
    results: [],
    isLoading: false,
    error: null
  }
};

export const consultation = createSlice({
  name: 'consultation',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchLoadSearch.pending, (state) => {
      state.search = {
        ...state.search,
        isLoading: true
      };
    });
    builder.addCase(fetchLoadSearch.fulfilled, (state, { payload }) => {
      state.search = {
        ...payload,
        isLoading: false
      };
    });
    builder.addCase(fetchLoadCarousel.pending, (state) => {
      state.carousel = {
        ...state.carousel,
        isLoading: true
      };
    });
    builder.addCase(fetchLoadCarousel.fulfilled, (state, { payload }) => {
      state.carousel = {
        ...state.carousel,
        ...payload,
        isLoading: false
      };
    });
  },
  reducers: {
    onResetState: (state) => {
      state.search = initialState.search;
      state.carousel = initialState.carousel;
    },
    onResetSearch: (state) => {
      state.search = initialState.search;
    },
    onSetResults: (state, { payload }) => {
      state.search = { ...payload };
    }
  }
});

export const { onResetState, onSetResults, onResetSearch } = consultation.actions;
