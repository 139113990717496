import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './Button.module.scss';

interface ButtonProps {
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

export const Button = ({ children, onClick, disabled }: ButtonProps) => {
  return (
    <div
      className={clsx(styles.button, disabled && styles.disabled)}
      onClick={() => {
        if (!disabled) onClick?.();
      }}
    >
      {children}
    </div>
  );
};

export default Button;
