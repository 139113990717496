import { createSlice } from '@reduxjs/toolkit';
import {
  fetchLoadCertificateDetail,
  fetchLoadPoliceOthers,
  reLoadPoliceOthers
} from '../policesOthers/ActionsPoliceOthersSlice';

const initialState = {
  risks: []
};

export const riskSlice = createSlice({
  name: 'policeRisks',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchLoadPoliceOthers.fulfilled, (state, { payload }) => {
      const { risks } = payload.storageRiskSlice;
      state.risks = risks;
    });
    builder.addCase(reLoadPoliceOthers.fulfilled, (state, { payload }) => {
      const { risks } = payload.storageRiskSlice;
      state.risks = risks;
    });
    builder.addCase(fetchLoadCertificateDetail.fulfilled, (state, { payload }) => {
      const { risks } = payload.storageRiskSlice;
      state.risks = risks;
    });
  },
  reducers: {
    onRestartStateRisks: (state) => {
      state.risks = [];
    }
  }
});

// Action creators are generated for each case reducer function
export const { onRestartStateRisks } = riskSlice.actions;
