import { useDispatch } from 'react-redux';
import { onRestartStateClaims } from 'store/Claims/claimSlice';
import { onRestartStateCoverages } from 'store/coverages/coverageSlice';
import { onRestartStateMovement } from 'store/movements/movementsSlice';
import { onRestartPersonsState } from 'store/persons/personsSlice';
import { onRestartState } from 'store/policesAutosIndividual/PolicesSliceAutosIndividualSlice';
import { onRestartStateRisks } from 'store/risks/riskSlice';
import {
  onRestartCertificateDetail,
  onRestartState as onRestartStateOB
} from 'store/policesOthers/policesOthersSlice';
import { SESSION_STORAGE_VARIABLES } from 'common/sessionStorageVariables';

const useReset = () => {
  const dispatch = useDispatch();
  const resetTabsPolicy = () => {
    dispatch(onRestartStateMovement());
    dispatch(onRestartPersonsState());
    dispatch(onRestartStateRisks());
    dispatch(onRestartStateCoverages());
    dispatch(onRestartStateClaims());
  };
  const resetSingleCarsPolicy = () => {
    sessionStorage.removeItem(SESSION_STORAGE_VARIABLES.POLICY_SINGLE_CAR);
    dispatch(onRestartState());
  };
  const resetOtherBranchPolicies = () => {
    sessionStorage.removeItem(SESSION_STORAGE_VARIABLES.POLICY_OTHER_BRANCH);
    dispatch(onRestartStateOB());
  };
  const resetCertificateDetail = () => {
    sessionStorage.removeItem(SESSION_STORAGE_VARIABLES.CERTIFICATE);
    dispatch(onRestartCertificateDetail());
  };
  const resetSinisterSearch = () => {
    sessionStorage.removeItem(SESSION_STORAGE_VARIABLES.QUERY_SINISTERS);
    sessionStorage.removeItem(SESSION_STORAGE_VARIABLES.CLAIM_SELECTED);
  };
  return {
    resetTabsPolicy,
    resetSingleCarsPolicy,
    resetOtherBranchPolicies,
    resetCertificateDetail,
    resetSinisterSearch
  };
};

export default useReset;
