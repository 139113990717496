import { useState } from 'react';
import { CircularProgress } from '@mui/material';

import { PolicyRenewal } from 'models/renovation';
import Button from '../Button';
import styles from './DownloandPdf.module.scss';
import { useDownloadAlertsStore } from 'hooks/useDownloadAlertsStore';
import { LibertyCustomTooltip } from 'components/basics/Customs';
import Iconinfowarning from 'assets/icons/Iconinfowarning.svg';

interface DownloadPdfProps {
  idNotification: string | null;
  createDate: string;
  disable: boolean;
  pdfName?: string;
  showIconInfo: boolean;
}

export const DownloadPdf = ({
  disable,
  idNotification,
  createDate,
  pdfName = `${createDate}_renovaciones.zip`,
  showIconInfo
}: DownloadPdfProps) => {
  const { startDownloadZip } = useDownloadAlertsStore();
  const [loading, setLoading] = useState(false);
  return (
    <div className={styles.root}>
      <Button
        onClick={async () => {
          setLoading(true);
          await startDownloadZip(idNotification, pdfName, { type: 'zip' });
          setLoading(false);
        }}
        disabled={disable}
      >
        Descargar PDFs {'>'}
      </Button>
      {loading && (
        <div className={styles.loading}>
          <CircularProgress size={'17px'} color="inherit" />
        </div>
      )}
      {showIconInfo && (
        <img className={styles.icon} src={Iconinfowarning} alt="Iconinfowarning" id="iconInfo" />
      )}
      <LibertyCustomTooltip
        id={'iconInfo'}
        tooltipInfo={
          <div>
            Lo sentimos no ha sido posible <br />
            generar los PDFs. Podrás <br />
            descargarlos realizando la consulta <br />
            de la póliza utilizando el botón <br />
            <b>Descargar póliza.</b>
          </div>
        }
      />
    </div>
  );
};
