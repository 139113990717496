import { format } from 'date-fns';
import { getRequest, postRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';
import { parseDate } from 'utils/formatDate';

export const getCheckDownload = async () => {
  const { data } = await getRequest('check-zip-generation', BaseTypes.policies);
  return {
    pending: data.pending
  };
};

export const generateZipPDFS = async (
  productCode,
  policyNumber,
  expirationDate,
  totalCertificates
) => {
  const expiration = parseDate(expirationDate);
  const year = +format(expiration, 'yyyy');
  const month = +format(expiration, 'MM');
  const bodyPolicy = {
    data: {
      productCode,
      policyNumber,
      totalCertificates,
      year,
      month
    }
  };
  await postRequest('get-collective-pdf', bodyPolicy, BaseTypes.policies);
};
