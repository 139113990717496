export const getNumDaysMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

export const subMonths = (date, numMonths) => {
  let dateTime = new Date(date.getFullYear(), date.getMonth(), getNumDaysMonth(date)).getTime(); //Día final del mes actual en mes

  for (let month = 1; month <= numMonths; month++) {
    let numDaysOfMonthCurrent = getNumDaysMonth(new Date(dateTime)); //Cantidad de días del mes
    dateTime = dateTime - 3600000 * 24 * numDaysOfMonthCurrent;
  }

  return new Date(dateTime);
};

export const addMonths = (date, numMonths) => {
  let dateTime = new Date(date.getFullYear(), date.getMonth(), 1).getTime(); //Día 1 del mes actual en mes

  for (let month = 1; month <= numMonths; month++) {
    let numDaysOfMonthCurrent = getNumDaysMonth(new Date(dateTime)); //Cantidad de días del mes
    dateTime = dateTime + 3600000 * 24 * numDaysOfMonthCurrent;
  }

  return new Date(dateTime);
};

export const isBefore = (currentDate: Date, comparedDate: Date) => {
  return comparedDate < currentDate;
};

export const isAfter = (currentDate: Date, comparedDate: Date) => {
  return comparedDate > currentDate;
};

export const isEqual = (currentDate: Date, comparedDate: Date) => {
  if (!currentDate || !comparedDate) return false;
  const timeCurrentDate = +currentDate.setHours(0, 0, 0, 0);
  const timeComparedDate = +comparedDate.setHours(0, 0, 0, 0);
  return timeCurrentDate == timeComparedDate;
};

export const firstDateOfTheMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const transformDate = (day, month, year) => {
  return new Date(`${year},${month},${day}`);
};

export const getMonthById = (id) => {
  const month = {
    0: { abv: 'Ene', complete: 'Enero' },
    1: { abv: 'Feb', complete: 'Febrero' },
    2: { abv: 'Mar', complete: 'Marzo' },
    3: { abv: 'Abr', complete: 'Abril' },
    4: { abv: 'May', complete: 'Mayo' },
    5: { abv: 'Jun', complete: 'Junio' },
    6: { abv: 'Jul', complete: 'Julio' },
    7: { abv: 'Ago', complete: 'Agosto' },
    8: { abv: 'Sep', complete: 'Septiembre' },
    9: { abv: 'Oct', complete: 'Octubre' },
    10: { abv: 'Nov', complete: 'Noviembre' },
    11: { abv: 'Dic', complete: 'Diciembre' }
  };
  return month[id];
};

export const createArrayDays = (
  startDay,
  endDay,
  month,
  minDate,
  maxDate,
  messagesToolTips = {
    pastTooltip: '',
    futureTooltip: ''
  }
) => {
  let tempDays = [];
  for (let day = startDay; day <= endDay; day++) {
    const dateOfDay = transformDate(day, month.getMonth() + 1, month.getFullYear());
    let tooltipDate = '';
    if (minDate && isBefore(minDate, dateOfDay) && !isEqual(dateOfDay, minDate)) {
      tooltipDate = messagesToolTips.pastTooltip;
    }
    if (maxDate && isAfter(maxDate, dateOfDay) && !isEqual(dateOfDay, maxDate)) {
      tooltipDate = messagesToolTips.futureTooltip;
    }
    tempDays = [
      ...tempDays,
      {
        dateOfDay,
        numDay: day,
        tooltipDate
      }
    ];
  }
  return tempDays;
};

export const formatDate = (date, format = 'dd/MMM') => {
  if (!date) return '';

  let correctDate = date;
  if (typeof date === 'string') {
    correctDate = new Date(date);
  }
  const arrayFormat = format.split('');

  let separator = '';
  const finSeparator = (sep) => arrayFormat.some((letter) => letter == sep);
  if (finSeparator('/')) separator = '/';
  else if (finSeparator('-')) separator = '-';
  else if (finSeparator(',')) separator = ',';
  const numSep = arrayFormat.filter((letter) => letter == separator).length;

  const numM = arrayFormat.filter((letter) => letter == 'M').length;

  const month = correctDate.getMonth();
  let monthString = '';
  if (numM == 4) monthString = getMonthById(month).complete;
  else if (numM == 3) monthString = getMonthById(month).abv;
  else if (numM == 2) monthString = `${getMonthById(month).abv[0]}${getMonthById(month).abv[1]}`;

  const numY = arrayFormat.filter((letter) => letter == 'y').length;
  let yearString = '';
  if (numY == 4) yearString = correctDate.getFullYear();
  if (numY == 2) yearString = `${correctDate.getFullYear()[2]}${correctDate.getFullYear()[3]}`;

  const day = correctDate.getDate();

  let totalDateFormat = '';
  if (numSep > 0) {
    for (let partOfDate = 0; partOfDate <= numSep; partOfDate++) {
      if (arrayFormat[0] == 'y') {
        if (partOfDate == 0) totalDateFormat = totalDateFormat + yearString;
        else if (partOfDate == 1) totalDateFormat = totalDateFormat + separator + monthString;
        else if (partOfDate == 2) totalDateFormat = totalDateFormat + separator + day;
      } else if (arrayFormat[0] == 'M') {
        if (partOfDate == 0) totalDateFormat = totalDateFormat + monthString;
        else if (partOfDate == 1) totalDateFormat = totalDateFormat + separator + day;
      } else if (arrayFormat[0] == 'd') {
        if (partOfDate == 0) totalDateFormat = totalDateFormat + day;
        else if (partOfDate == 1) totalDateFormat = totalDateFormat + separator + monthString;
        else if (partOfDate == 2) totalDateFormat = totalDateFormat + separator + yearString;
      }
    }
  } else {
    totalDateFormat = totalDateFormat + day;
  }

  return totalDateFormat;
};

export const initDateContainer = (minDate) => {
  if (!minDate || new Date() > minDate)
    return {
      currentDate: new Date(),
      selectedDate: new Date()
    };

  const nextDayAfterMinDate = new Date(+minDate);
  return {
    currentDate: nextDayAfterMinDate,
    selectedDate: nextDayAfterMinDate
  };
};

export const currentAndPreviousMonth = (minDate) => {
  if (!minDate || new Date() > minDate)
    return { currentMonth: new Date(), previousMonth: subMonths(new Date(), 1) };

  const nextDayAfterMinDate = new Date(+minDate);
  return { currentMonth: nextDayAfterMinDate, previousMonth: subMonths(nextDayAfterMinDate, 1) };
};

export const initDateContainerRange = (minDate) => {
  if (!minDate || new Date() > minDate)
    return {
      currentDateInit: new Date(),
      currentDateEnd: null,
      selectedDateInit: new Date(),
      selectedDateEnd: null
    };

  const nextDayAfterMinDate = new Date(minDate);
  return {
    currentDate: nextDayAfterMinDate,
    currentDateEnd: null,
    selectedDateInit: nextDayAfterMinDate,
    selectedDateEnd: null
  };
};

export const currentAndPreviousMonthRange = (minDate) => {
  if (!minDate || new Date() > minDate)
    return { currentMonth: new Date(), previousMonth: subMonths(new Date(), 1) };

  const nextDayAfterMinDate = new Date(+minDate);
  return { currentMonth: nextDayAfterMinDate, previousMonth: subMonths(nextDayAfterMinDate, 1) };
};
