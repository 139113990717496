//*REACT
import { useEffect, useMemo, useRef, useState } from 'react';
//*COMPONENTS
import { Option } from './Option/Option';
//*ICONS
import arrowIcon from 'assets/icons/arrowDown.svg';
//*STYLES
import styles from './LibertyCustomCheckBox.module.scss';

export const LibertyCustomCheckBox = ({
  optionsSelected = [],
  onChange: changeOptionSelected,
  listOptions = [],
  placeholder = '-',
  inactive = false
}) => {
  const headerRef = useRef();
  const boxRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const optionIsChecked = (value) => {
    return optionsSelected.some((option) => option.value == value);
  };

  const onChange = (isChecked, option) => {
    if (isChecked) {
      return changeOptionSelected([...optionsSelected, option]);
    }
    setSelectAll(false);
    changeOptionSelected(
      optionsSelected.filter((optionSelected) => optionSelected.value != option.value)
    );
  };
  const changeSelectAll = (isChecked) => {
    setSelectAll(isChecked);
    if (isChecked) {
      return changeOptionSelected([...listOptions]);
    }
    changeOptionSelected([]);
  };

  const styleSelected = useMemo(() => optionsSelected?.length > 0, [optionsSelected?.length]);

  useEffect(() => {
    if (optionsSelected.length == listOptions.length) {
      return setSelectAll(true);
    }
    setSelectAll(false);
  }, [optionsSelected.length, listOptions.length]);

  useEffect(() => {
    const closeSelect = (e) => {
      if (
        !e.composedPath().includes(boxRef.current) &&
        !e.composedPath().includes(headerRef.current)
      ) {
        setIsOpen(false);
      }
    };
    document.body.addEventListener('click', closeSelect);
    return () => {
      document.body.removeEventListener('click', closeSelect);
    };
  }, []);

  return (
    <div className={styles.container_check_box}>
      <div
        className={`${styles.header_check_box} ${isOpen && styles.focus} ${
          inactive && styles.inactive
        }`}
        ref={headerRef}
        onClick={() => !inactive && setIsOpen(!isOpen)}
      >
        <span className={styles.container_options_selected}>
          {optionsSelected?.length > 0 &&
            optionsSelected
              .sort((x, y) => x.value.localeCompare(y.value))
              .map((optionSelected, i) => (
                <div
                  className={styles.container_option_selected}
                  key={optionSelected.value + '-selected'}
                >
                  {optionSelected.color && (
                    <div
                      className={styles.color_option}
                      style={{ backgroundColor: optionSelected.color }}
                    />
                  )}
                  <div className={styles.name_option}>{optionSelected.name}</div>
                  {i != optionsSelected.length - 1 ? ',' : ''}
                </div>
              ))}
        </span>
        <label
          className={styles.placeholder}
          htmlFor=""
          style={{ top: styleSelected ? '23%' : '50%', fontSize: styleSelected ? '12px' : '16px' }}
        >
          {placeholder}
        </label>
        <div className={styles.container_arrow}>
          <img
            style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
            src={arrowIcon}
            alt="arrowIcon"
          />
        </div>
      </div>
      <div
        className={styles.option_box}
        ref={boxRef}
        style={{
          height: isOpen ? `${listOptions.length * 51}px` : '0px'
        }}
      >
        <Option
          name="Seleccionar todo"
          onChange={(isChecked) => changeSelectAll(isChecked)}
          isChecked={selectAll}
        />
        {listOptions.map((option) => (
          <Option
            key={option.value}
            name={option.name}
            color={option.color}
            onChange={(isChecked) => onChange(isChecked, option)}
            isChecked={optionIsChecked(option.value)}
          />
        ))}
      </div>
    </div>
  );
};
