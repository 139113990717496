import { createSlice } from '@reduxjs/toolkit';
import { fetchCheckDownloadPdfs, fetchGenerateDownloadPDF } from './ActionsProcessDownloadPdf';

const initialState = {
  pending: []
};

export const ProcessDownloadSlice = createSlice({
  name: 'processDownload',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCheckDownloadPdfs.fulfilled, (state, { payload }) => {
      state.pending = payload.pending || [];
    });
    builder.addCase(fetchGenerateDownloadPDF.fulfilled, (state, { payload }) => {
      const oldPending = state.pending || [];
      state.pending = [...oldPending, payload];
    });
  },
  reducers: {
    onRestartProcessDownload: (state) => {
      state.pending = [];
    }
  }
});

export const { onRestartProcessDownload } = ProcessDownloadSlice.actions;
