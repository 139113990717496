import styles from './TooltipCalendar.module.scss';

export const TooltipCalendar = ({ tooltipInfo, children }) => {
  return (
    <div className={styles.container}>
      {children}
      {tooltipInfo && (
        <div className={styles.tooltip}>
          <div className={styles.triangle} />
          <div className={styles.info_tooltip}>{tooltipInfo}</div>
        </div>
      )}
    </div>
  );
};
