import { SESSION_STORAGE_VARIABLES } from 'common/sessionStorageVariables';
import { postRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';
import { mapFilters } from 'utils/renovations/filters';

export const actionBaseSearch = async (query, page, perPage) => {
  if (!query) {
    sessionStorage.removeItem(SESSION_STORAGE_VARIABLES.QUERY_RENOVATION);
    return {
      query,
      page: 0,
      totalPages: 0,
      totalItems: 0,
      pageItems: 0,
      policies: [],
      error: null
    };
  }
  try {
    const data = await getSearchRenew(query, page, perPage);
    return {
      ...data,
      query,
      pagination: {
        page,
        limit: perPage
      },
      errorSearch: null
    };
  } catch (errorSearch) {
    console.error('Error advance search: ', errorSearch);
    return {
      query,
      page: 0,
      totalPages: 0,
      totalItems: 0,
      pageItems: 10,
      policies: [],
      pagination: {
        page: 0,
        limit: 0
      },
      errorSearch
    };
  }
};

export const changePoliciesPending = async (policies) => {
  let changedPolicies = [];
  for (let index = 0; index < policies.length; index++) {
    try {
      const body = {
        data: {
          intermediary: {},
          filters: {
            branch: {},
            holder: {},
            insured: {},
            policy_number: policies[index]
          },
          pagination: {
            page: 1,
            perPage: 10
          }
        }
      };
      const {
        data: { data }
      } = await postRequest('advanced-search', body, BaseTypes.renewals);
      changedPolicies = [...changedPolicies, ...data.policies];
    } catch (error) {
      //
    }
  }
  return changedPolicies;
};

export const getDashboardCards = async (date) => {
  const body = {
    data: {
      intermediary: {},
      date
    }
  };
  const { data } = await postRequest('dashboard', body, BaseTypes.renewals);
  return data;
};

export const getSearchRenew = async (query, page, perPage) => {
  const filters = mapFilters(query);
  const body = {
    data: {
      intermediary: {},
      filters,
      pagination: {
        page,
        perPage
      }
    }
  };
  const {
    data: { data }
  } = await postRequest('advanced-search', body, BaseTypes.renewals);
  return data;
};
