import { createSlice } from '@reduxjs/toolkit';
import { AnnulmentSlice } from './types';
import { fetchCancelPolicy, fetchLoadAnnulment } from './ActionAnnulmentSlice';

const initialState: AnnulmentSlice = {
  isLoading: false,
  annulmentsData: null,
  error: null,
  cancellationStatus: false
};

export const annulmentSlice = createSlice({
  name: 'annulment',
  initialState,
  reducers: {
    onReset: (state /* action */) => {
      state.isLoading = false;
      state.annulmentsData = null;
      state.cancellationStatus = false;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLoadAnnulment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLoadAnnulment.fulfilled, (state, { payload }) => {
      state.annulmentsData = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchLoadAnnulment.rejected, (state, { error }) => {
      state.error = error;
      state.isLoading = false;
      state.annulmentsData = null;
      state.cancellationStatus = false;
    });
    builder.addCase(fetchCancelPolicy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCancelPolicy.fulfilled, (state, { payload }) => {
      state.cancellationStatus = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchCancelPolicy.rejected, (state, { error }) => {
      state.error = error;
      state.isLoading = false;
      state.annulmentsData = null;
      state.cancellationStatus = false;
    });
  }
});

// Action creators are generated for each case reducer function
export const { onReset } = annulmentSlice.actions;
