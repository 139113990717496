import { createAsyncThunk } from '@reduxjs/toolkit';
import { postRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';

export const fetchLoadActions = createAsyncThunk(
  'ActionsOptions/fetchActions',
  async ({ query, from_certificates = false }) => {
    if (!query) {
      return {
        query: null,
        error: null
      };
    }
    try {
      const body = {
        data: {
          ...query,
          from_certificates: from_certificates
        }
      };

      const {
        data: { data }
      } = await postRequest('supplements/allowed-supplements', body, BaseTypes.endorsement);
      return {
        query: { ...data.actions },
        error: null
      };
    } catch (error) {
      console.error(error);
      return {
        query: [],
        error
      };
    }
  }
);
