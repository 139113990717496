import styles from './LibertyIncompleteDownloadResponsive.module.scss';
import close from 'assets/icons/close.svg';
import { useEffect, useMemo, useState } from 'react';
import { useDownloadAlertsStore } from 'hooks/useDownloadAlertsStore';

import badDownload from './warningRed.svg';
import Container from 'components/Container';

export const LibertyIncompleteDownloadResponsive = () => {
  const { bad, title, description } = useDownloadAlertsStore();
  const [isClose, setIsClose] = useState(false);

  useEffect(() => {
    if (bad) {
      setIsClose(true);
    } else {
      setIsClose(false);
    }
  }, [bad]);

  const message = useMemo(() => {
    let arrayMessage = [];
    if (description.first) {
      arrayMessage = [<span className={styles.sub_title}>{description.first}</span>];
      if (description.second) {
        arrayMessage = [
          ...arrayMessage,
          <span className={styles.sub_title.second}>{description.second}</span>
        ];
      }
      return arrayMessage;
    } else {
      return <span className={styles.sub_title}>{description}</span>;
    }
  }, [description, description.first, description.second]);

  return (
    <Container>
      <div
        className={styles.alert_download_incomplete}
        style={{
          opacity: bad ? '1' : '0',
          zIndex: bad ? '101' : '-1',
          display: !isClose ? 'none' : 'flex'
        }}
      >
        <div className={styles.icon_bad}>
          <img src={badDownload} alt="badDownload" />
        </div>

        <div className={styles.info}>
          <span className={styles.title}>{title}</span>
          {message}
        </div>

        <div
          className={styles.close}
          onClick={() => {
            setIsClose(false);
          }}
        >
          <img src={close} alt="close" />
        </div>
      </div>
    </Container>
  );
};
