// @ts-check
import { useCallback, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { startSession, getUser } from 'services/session';
import { isDev } from 'utils';
import { getTokens, saveTokens } from './utils';

export const useRestoreSession = () => {
  const [params, setParams] = useSearchParams();
  const initalRefer = useRef(params.get('refer'));

  const getSession = useCallback(
    /**
     * @param {string} refer
     */
    (refer) =>
      startSession(refer).then(({ data }) => {
        saveTokens(refer, data.accessToken, data.jwtRefer);
        return data;
      }),
    []
  );

  const getSessionRefer = useCallback(
    (refer) =>
      getSession(refer).then((data) => {
        return data;
      }),
    [getSession]
  );

  useEffect(() => {
    const refer = params.get('refer');
    if (refer && initalRefer.current !== refer) getSessionRefer(refer);
    if (refer) {
      params.delete('refer');
      setParams(params);
    }
  }, [getSessionRefer, params, setParams]);

  const restore = useCallback(async () => {
    const refer = initalRefer.current;
    if (refer) {
      initalRefer.current = null;
      return getSessionRefer(refer);
    }
    if (getTokens().refer) {
      const { data } = await getUser(getTokens().refer);
      return data;
    } else {
      if (isDev()) {
        const { devRefer } = await import('common/devRefer');
        return getSession(devRefer);
      } else throw new Error('No refer or jwt');
    }
  }, [getSession, getSessionRefer]);

  return { restore, getSession };
};
