import { createAsyncThunk } from '@reduxjs/toolkit';
import { generateZipPDFS, getCheckDownload } from './services';
import { getDownloadPdfsPending, setDownloadPdfsPending } from 'utils/modifyDonwloadPdfsPeding';

export const fetchCheckDownloadPdfs = createAsyncThunk(
  'processDownload/fetchCheckDownloadPdfs',
  async () => {
    try {
      const { pending } = await getCheckDownload();
      const DPP = getDownloadPdfsPending();
      if (DPP.length > pending.length) {
        setDownloadPdfsPending([...pending]);
      }
      return {
        pending
      };
    } catch (error) {
      console.error(error);
      return {
        pending: []
      };
    }
  }
);

export const fetchGenerateDownloadPDF = createAsyncThunk(
  'processDownload/fetchGenerateDownloadPDF',
  async ({ productCode, policyNumber, expirationDate, totalCertificates }) => {
    if (
      !productCode ||
      !policyNumber ||
      !expirationDate ||
      (!totalCertificates && totalCertificates != 0)
    )
      return console.error(
        `Missing parameters : 
      ${!productCode ? 'productCode, ' : ''}
      ${!policyNumber ? 'policyNumber, ' : ''}
      ${!expirationDate ? 'expirationDate, ' : ''}
      ${!totalCertificates && totalCertificates != 0 ? 'totalCertificates' : ''}`
      );
    try {
      setDownloadPdfsPending([...getDownloadPdfsPending(), policyNumber]);
      await generateZipPDFS(productCode, policyNumber, expirationDate, totalCertificates);
      return policyNumber;
    } catch (error) {
      setDownloadPdfsPending(
        getDownloadPdfsPending().filter((policyPending) => policyPending != policyNumber)
      );
      console.error('Error get-collective-pdf : ', error);
      return null;
    }
  }
);
