import { getRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';

export const getCheckProcess = async () => {
  const { data } = await getRequest('check-process', BaseTypes.renewals);
  return {
    pending: data.pending,
    success: data.success.map((policy) => ({ policy, reload: false })),
    errors: data.errors.map((policy) => ({ policy, reload: false }))
  };
};
