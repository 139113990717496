import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccessoriesInfo } from 'models/modifications';
import { PolicesSlice, ModifyRate, IRisk } from './types';
import {
  fetchLoadClaims,
  fetchLoadCoverageAndDeductibles,
  fetchLoadCoverages,
  fetchLoadIndividualCarDetail,
  fetchLoadMovements,
  fetchLoadPersons,
  fetchLoadRisks,
  reLoadPolicyAI
} from './ActionsPolicySingleCars';

const initialState: PolicesSlice = {
  isLoading: false,
  policeDashboard: null,
  documentPDF: null,
  isDownloadComplete: false,
  isDownloadInomplete: false,
  renewalProcessAccepted: null,
  isModalRenovationRequest: false,
  policeSinisters: {
    sinisters: [],
    trackingClaim: null,
    errorSinisters: null
  },
  policeRisk: {
    infoResponse: null,
    risks: [],
    totalRegistros: 0,
    errorRisk: null
  },
  policePersonsAI: {
    holders: null,
    insured: null,
    beneficiaries: null,
    drivers: null,
    dental_beneficiaries: null,
    modTakerSuccess: null,
    errorPersons: null
  },
  policeCoberturas: {
    totalRegisters: 0,
    warranties: [],
    errorCoverage: null
  },
  movementsAI: {
    movements: [],
    notes: [],
    errorMovements: null
  },
  risk: {
    loading: false,
    error: null
  },
  accessories: {
    data: [],
    loading: false,
    error: null
  },
  modifies: {
    rates: null
  },
  modAmparos: {
    amparos: [],
    deductibles: [],
    detailsDeductibles: [],
    error: null
  },
  modTaker: null,
  modInsured: null,
  errorDashboard: null,
  errorRiskAutos: null,
  errorTaker: null,
  errorInsured: null
};

export const PolicesSliceAutosIndividualSlice = createSlice({
  name: 'Polices',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchLoadIndividualCarDetail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLoadIndividualCarDetail.fulfilled, (state, { payload }) => {
      state.policeDashboard = payload.policyDashboard;
      state.policePersonsAI = payload.persons;
      state.errorDashboard = payload.error;
      state.isLoading = false;
    });
    builder.addCase(reLoadPolicyAI.fulfilled, (state, { payload }) => {
      state.policeDashboard = payload.policyDashboard;
      state.policePersonsAI = payload.persons;
      state.errorDashboard = payload.error;
      state.isLoading = false;
    });
    builder.addCase(fetchLoadClaims.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLoadClaims.fulfilled, (state, { payload }) => {
      state.policeSinisters = { ...payload };
      state.isLoading = false;
    });
    builder.addCase(fetchLoadCoverages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLoadCoverages.fulfilled, (state, { payload }) => {
      state.policeCoberturas = {
        ...payload
      };
      state.isLoading = false;
    });
    builder.addCase(fetchLoadRisks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLoadRisks.fulfilled, (state, { payload }) => {
      state.policeRisk = {
        ...payload
      };
      state.isLoading = false;
    });
    builder.addCase(fetchLoadPersons.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLoadPersons.fulfilled, (state, { payload }) => {
      state.policePersonsAI = {
        ...payload
      };
      state.isLoading = false;
    });
    builder.addCase(fetchLoadMovements.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLoadMovements.fulfilled, (state, { payload }) => {
      state.movementsAI = {
        ...payload
      };
      state.isLoading = false;
    });
    builder.addCase(fetchLoadCoverageAndDeductibles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLoadCoverageAndDeductibles.fulfilled, (state, { payload }) => {
      state.modAmparos = {
        amparos: payload.amparos,
        deductibles: payload.deductibles,
        detailsDeductibles: payload.detailsDeductibles,
        error: payload.error
      };
      state.isLoading = false;
    });
  },
  reducers: {
    onRestartState: (state) => {
      state.isLoading = false;
      state.policeDashboard = null;
      state.policeCoberturas = initialState.policeCoberturas;
      state.policeRisk = initialState.policeRisk;
      state.policeSinisters = initialState.policeSinisters;
      state.documentPDF = null;
      state.isDownloadComplete = false;
      state.isDownloadInomplete = false;
      state.renewalProcessAccepted = null;
      state.isModalRenovationRequest = false;
      state.modTaker = null;
      state.modInsured = null;
      state.errorDashboard = null;
      state.errorRiskAutos = null;
      state.errorTaker = null;
      state.errorInsured = null;
      state.risk = initialState.risk;
      state.policePersonsAI = initialState.policePersonsAI;
      state.movementsAI = initialState.movementsAI;
      state.modAmparos = initialState.modAmparos;
    },
    onLoadPersonUpdating: (state, { payload }) => {
      const personsUpdating = payload;
      state.policeDashboard.personsUpdating = personsUpdating;
    },
    onLoadTrackingClaim: (state, { payload }) => {
      state.policeSinisters = {
        ...state.policeSinisters,
        trackingClaim: payload
      };
    },
    onLoadModTaker: (state, { payload }) => {
      state.modTaker = payload;
    },
    onLoadModInsured: (state, { payload }) => {
      state.modInsured = payload;
    },
    onOpenCloseModalRenovationRequest: (state, { payload }) => {
      state.isModalRenovationRequest = payload;
    },
    onLoadRenewalProcessAccepted: (state, { payload }) => {
      state.renewalProcessAccepted = payload;
    },
    onStartLoad: (state) => {
      state.isLoading = true;
    },
    onStopLoad: (state) => {
      state.isLoading = false;
    },
    onStopDownload: (state) => {
      state.isDownloadComplete = false;
      state.isDownloadInomplete = false;
    },
    onIncompleteDownload: (state) => {
      state.isLoading = false;
      state.isDownloadInomplete = true;
    },
    onLoadDashboard: (state, { payload }) => {
      state.policeDashboard = payload;
      state.isLoading = false;
      state.errorDashboard = null;
    },
    onLoadDataDashboard: (state, { payload }) => {
      state.policeDashboard = payload;
      state.errorDashboard = null;
    },
    onLoadRisk: (state, { payload }) => {
      state.policeRisk = { ...payload };
      state.isLoading = false;
    },
    onLoadPersons: (state, { payload }) => {
      state.policePersonsAI = {
        ...payload,
        errorPersons: null
      };
      state.isLoading = false;
    },
    onLoadModAmparos: (state, { payload }) => {
      state.modAmparos = {
        amparos: payload.amparos,
        deductibles: payload.deductibles,
        detailsDeductibles: payload.detailsDeductibles,
        error: payload.error
      };
    },
    onErrorDashboard: (state, { payload }) => {
      state.errorDashboard = payload;
      state.isLoading = false;
    },

    onErrorRisk: (state, { payload }) => {
      state.isLoading = false;
    },
    onErrorTaker: (state, { payload }) => {
      state.errorTaker = payload;
      state.isLoading = false;
    },
    onErrorInsured: (state, { payload }) => {
      state.errorInsured = payload;
      state.isLoading = false;
    },
    onLoadRiskAutos: (state, { payload }: PayloadAction<IRisk>) => {
      state.risk = {
        ...payload
      };
    },
    onLoadAccessories: (state, { payload }: PayloadAction<AccessoriesInfo[]>) => {
      state.accessories.data = payload;
      state.accessories.loading = false;
    },
    setAccessoriesLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.accessories.loading = payload;
    },
    setAccessoriesError: (state, { payload }: PayloadAction<Error | undefined | null>) => {
      state.accessories.error = payload;
    },
    setRates: (state, { payload }: PayloadAction<ModifyRate>) => {
      state.modifies.rates = payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  onRestartState,
  onStartLoad,
  onStopLoad,
  onLoadTrackingClaim,
  onLoadDashboard,
  onStopDownload,
  onIncompleteDownload,
  onLoadRisk,
  onLoadPersons,
  onLoadModAmparos,
  onLoadDataDashboard,
  onLoadRenewalProcessAccepted,
  onLoadModTaker,
  onLoadModInsured,
  onErrorDashboard,
  onErrorRisk,
  onErrorTaker,
  onErrorInsured,
  onLoadRiskAutos,
  onLoadAccessories,
  onOpenCloseModalRenovationRequest,
  setRates,
  setAccessoriesLoading,
  setAccessoriesError,
  onLoadPersonUpdating
} = PolicesSliceAutosIndividualSlice.actions;
