import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCheckProcess } from './services';

export const fetchCheckProcess = createAsyncThunk(
  'processRenovation/fetchCheckProcess',
  async () => {
    try {
      return await getCheckProcess();
    } catch (error) {
      return {
        pending: [],
        success: [],
        errors: []
      };
    }
  }
);
