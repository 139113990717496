import { createAsyncThunk } from '@reduxjs/toolkit';
import { postRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';

export const fetchLoadSearch = createAsyncThunk(
  'SuppleAnnul/fetchSearch',
  async ({ query, page, per_page }) => {
    if (!query) {
      return {
        query: null,
        results: [],
        totalResults: 0,
        totalPages: 0,
        error: null
      };
    }
    try {
      const body = {
        data: {
          filters: {
            ...query
          },
          pagination: {
            page,
            per_page
          }
        }
      };

      const {
        data: { data }
      } = await postRequest('supplements/search', body, BaseTypes.endorsement);
      return {
        query: { ...data.filters },
        results: data.policies,
        totalResults: data.pagination.total_items,
        totalPages: data.pagination.total_pages,
        error: null
      };
    } catch (error) {
      console.error(error);
      return {
        query: { ...query },
        results: [],
        totalResults: 0,
        totalPages: 0,
        error
      };
    }
  }
);
