import { createSlice } from '@reduxjs/toolkit';
import {
  changePageListCertificates,
  fetchInfoHolderStatus,
  fetchLoadCertificateDetail,
  fetchLoadPoliceOthers,
  reLoadPoliceOthers,
  fetchFilterCertificatesOnline
} from './ActionsPoliceOthersSlice';

const initialState = {
  policyOthersBranches: {
    isLoading: false,
    policyDashboard: null,
    certificateDetail: null,
    queryCertificates: null,
    listCertificates: [],
    listCertificatesFiltered: [],
    infoPage: {
      page: 0,
      total_items: 0,
      total_pages: 0
    },
    errorDashboard: null,
    errorCertificateList: null,
    errorCertificateDetail: null
  }
};

export const policesOthersSlice = createSlice({
  name: 'policesOthers',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchLoadPoliceOthers.pending, (state) => {
      state.policyOthersBranches = {
        ...state.policyOthersBranches,
        isLoading: true
      };
    });
    builder.addCase(fetchLoadPoliceOthers.fulfilled, (state, { payload }) => {
      state.policyOthersBranches = {
        ...state.policyOthersBranches,
        ...payload.storagePoliceSlice,
        isLoading: false
      };
    });
    builder.addCase(reLoadPoliceOthers.fulfilled, (state, { payload }) => {
      state.policyOthersBranches = {
        ...state.policyOthersBranches,
        ...payload.storagePoliceSlice,
        isLoading: false
      };
    });
    builder.addCase(fetchLoadCertificateDetail.pending, (state) => {
      state.policyOthersBranches = {
        ...state.policyOthersBranches,
        isLoading: true
      };
    });
    builder.addCase(fetchLoadCertificateDetail.fulfilled, (state, { payload }) => {
      state.policyOthersBranches = {
        ...state.policyOthersBranches,
        ...payload.storagePoliceSlice,
        isLoading: false
      };
    });
    builder.addCase(changePageListCertificates.pending, (state) => {
      state.policyOthersBranches = {
        ...state.policyOthersBranches,
        isLoading: true
      };
    });
    builder.addCase(changePageListCertificates.fulfilled, (state, { payload }) => {
      state.policyOthersBranches = {
        ...state.policyOthersBranches,
        ...payload,
        isLoading: false
      };
    });
    builder.addCase(fetchInfoHolderStatus.fulfilled, (state, { payload }) => {
      state.policyOthersBranches = {
        ...state.policyOthersBranches,
        policyDashboard: {
          ...state.policyOthersBranches.policyDashboard,
          ...payload
        }
      };
    });
    builder.addCase(fetchFilterCertificatesOnline.pending, (state) => {
      state.policyOthersBranches = {
        ...state.policyOthersBranches,
        isLoading: true
      };
    });
    builder.addCase(fetchFilterCertificatesOnline.fulfilled, (state, { payload }) => {
      state.policyOthersBranches = {
        ...state.policyOthersBranches,
        ...payload,
        isLoading: false
      };
    });
  },
  reducers: {
    onRestartState: (state) => {
      state.policyOthersBranches = initialState.policyOthersBranches;
    },
    onRestartCertificateDetail: (state) => {
      state.policyOthersBranches = {
        ...state.policyOthersBranches,
        certificateDetail: null
      };
    }
  }
});

// Action creators are generated for each case reducer function
export const { onRestartState, onRestartCertificateDetail } = policesOthersSlice.actions;
