import { createAsyncThunk } from '@reduxjs/toolkit';
import { SESSION_STORAGE_VARIABLES } from 'common/sessionStorageVariables';
import { IResponse } from 'common/sinisters';
import { getRequest, postRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';

export const fetchLoadSearchSinister = createAsyncThunk(
  'sinisters/fetchLoadSearchSinister',
  async ({ query, page, items_on_page }: any) => {
    try {
      if (!query) {
        sessionStorage.removeItem(SESSION_STORAGE_VARIABLES.QUERY_SINISTERS);
        return {
          resultsBase: [],
          querySearch: null,
          infoSearch: {
            page: 1,
            totalPages: 0,
            pageItems: 0,
            totalItems: 0
          }
        };
      }
      sessionStorage.setItem(SESSION_STORAGE_VARIABLES.QUERY_SINISTERS, JSON.stringify(query));
      const body = {
        data: {
          filters: {
            ...query
          },
          pagination: {
            page,
            items_on_page
          }
        }
      };

      const {
        data: { data }
      } = await postRequest<{ data: IResponse }>('search', body, BaseTypes.sinisters);
      return {
        resultsBase: data.claims,
        querySearch: query,
        infoSearch: {
          page: data.info.page,
          totalPages: data.info.total_pages,
          pageItems: data.info.page_items,
          totalItems: data.info.total_items
        },
        errorSearch: null
      };
    } catch (error) {
      console.error(error);
      return {
        infoSearch: {
          page: 1,
          totalPages: 0,
          pageItems: 0,
          totalItems: 0
        },
        errorSearch: error,
        querySearch: query,
        resultsBase: []
      };
    }
  }
);

export const fetchLoadCarouselSinisters = createAsyncThunk(
  'sinisters/fetchLoadCarouselSinisters',
  async () => {
    try {
      const {
        data: { data }
      } = await getRequest<{ data: any }>('dashboard', BaseTypes.sinisters);
      return {
        results: data.branches,
        error: null
      };
    } catch (error) {
      console.error(error);
      return {
        results: [],
        error
      };
    }
  }
);
