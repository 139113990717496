import { StatesRenewalCars } from 'common';
import { getGroup } from 'components/ActionsPages/renovations/Tables/Policies/utils';
import { postRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';
import { getRenewalState } from 'services/getRenewalState/getRenewalState';
import { policyMessageStatus } from 'services/policyMessageStatus/policyMessageStatus';
import { getDateISO, parseDate } from 'utils/formatDate';
import { maskState } from 'utils/maskState';
import { validitionDownloadPDF } from 'utils/options';

export const errorBodyDashboard = (error) => ({
  storagePoliceSlice: {
    policyDashboard: null,
    certificateDetail: null,
    queryCertificates: null,
    listCertificatesFiltered: [],
    listCertificates: [],
    infoPage: {
      page: 0,
      total_items: 0,
      total_pages: 0
    },
    errorDashboard: error,
    errorCertificateList: null,
    errorCertificateDetail: null
  },
  storageMovementsSlice: {
    movements: [],
    notes: []
  },
  storagePersonsSlice: {
    persons: []
  },
  storageRiskSlice: {
    risks: []
  },
  storageClaimsSlice: {
    claims: []
  },
  storageCoverageSlice: {
    coverages: []
  }
});

export const getInfoPolicesOthers = async (query, isRenewals = false, isSuppleAnnul = false) => {
  let dataToLoad = {};

  //*Si es una poliza individual, de newCore debe traerse desde IAXIS
  //*si es de otro tipo debe venir de ADP
  if ((query.isIndividual && query.newCore == '1') || (query.is_individual && query.new_core)) {
    try {
      const respIndividualNewCoreInfo = await getIndividualNewCoreInfo(query);

      dataToLoad = {
        ...respIndividualNewCoreInfo
      };
    } catch (error) {
      return errorBodyDashboard(error);
    }
  } else {
    //* Si es individual de oldCore
    //* Si es colectiva de newCore
    //* Si es colectiva de oldCore
    try {
      const respADPInfo = await getCollectiveADPInfo(query);
      dataToLoad = {
        ...respADPInfo
      };
    } catch (error) {
      return errorBodyDashboard(error);
    }
  }
  //*THIS IS THE CONDITION TO GET THE ONLINE INFO TO THE POLICY IN CASE TO NEEDED
  if (dataToLoad?.dataDashboard?.validate_online_info) {
    try {
      const respOnlineInfo = await getOnlineInfo(query);

      dataToLoad = {
        ...dataToLoad,
        dataDashboard: {
          ...dataToLoad.dataDashboard,
          ...respOnlineInfo
        }
      };
    } catch (error) {
      console.error('Error load online Info');
    }
  }

  //*THIS IS THE CONDITION TO GET THE CERTIFICATES LIST IN CASE THAT ARE COLLECTIVE POLICY
  if (!query.isIndividual || !query?.is_individual) {
    const { dataDashboard = null } = dataToLoad;
    const group = query.branch.group;
    try {
      const respCertificates =
        dataToLoad.dataDashboard.limit_valid_certificates || isSuppleAnnul
          ? await getCertificatesOnline(dataDashboard, isSuppleAnnul)
          : await getCertificates(
              { ...dataDashboard, branch: { ...dataDashboard?.branch, group } },
              isRenewals
            );
      const { certificates = [], page = 0, total_items = 0, total_pages = 0 } = respCertificates;
      dataToLoad = {
        ...dataToLoad,
        dataCertificateList: {
          listCertificates: certificates,
          infoPage: {
            page,
            total_items,
            total_pages
          },
          errorCertificateList: null
        }
      };
    } catch (errorCertificateList) {
      console.error(errorCertificateList);
      dataToLoad = {
        ...dataToLoad,
        dataCertificateList: {
          listCertificates: [],
          infoPage: {
            page: 0,
            total_items: 0,
            total_pages: 0
          },
          errorCertificateList
        }
      };
    }
  }
  //*THIS IS THE SERVICES TO OBTAIN MESSAGE OF RENEWAL STATUS
  const renewalMessageStatus = await policyMessageStatus(dataToLoad.dataDashboard);
  //*THIS IS THE ONLY WAY WE HAVE TO OBTAIN THE DATE OF RENEWAL
  const { dateRenewal, renewal_status } = await getRenewalState(query);


  //* THIS SERVICES IS CALL TO GET THE SLIP INFO, TO KNOW IF IS NECESSARY GET DOCUMENTS FROM NEBULA
  const {
    policy_number,
    holder: { id_type, id_number },
    expiration_date,
    product_code,
    modality
  } = dataToLoad.dataDashboard;

  const slipInfo = await getSlipInfo(
    +policy_number,
    String(id_type),
    String(id_number),
    getDateISO(expiration_date),
    String(product_code),
    +modality,
    //*THIS ATTRIBUTE (risks) COUNT THE NUMBER OF CERTIFICATES "VIGENTES"
    +dataToLoad.dataDashboard?.risks
  );

  return {
    storagePoliceSlice: {
      policyDashboard: {
        ...dataToLoad.dataDashboard,
        renewalMessageStatus,
        dateRenewal: renewal_status == StatesRenewalCars.Otro ? null : dateRenewal,
        ...slipInfo
      },
      certificateDetail: null,
      queryCertificates: null,
      listCertificatesFiltered: [],
      errorDashboard: null,
      ...(dataToLoad.dataCertificateList || {})
    },
    storageMovementsSlice: {
      movements: dataToLoad.movements || [],
      notes: dataToLoad.notes || []
    },
    storagePersonsSlice: {
      persons: dataToLoad.persons || []
    },
    storageRiskSlice: {
      risks: dataToLoad.risks_tab || []
    },
    storageClaimsSlice: {
      claims: dataToLoad.claims || []
    },
    storageCoverageSlice: {
      coverages: dataToLoad.coverages || []
    }
  };
};

export const getSlipInfo = async (
  policyNumber,
  idType,
  idNumber,
  expirationDate,
  productCode,
  modality,
  totalCertificates
) => {
  if (
    (!policyNumber && policyNumber != 0) ||
    !idType ||
    !idNumber ||
    !expirationDate ||
    !productCode ||
    (!modality && modality != 0) ||
    (!totalCertificates && totalCertificates != 0)
  ) {
    return console.error(`Error get slip info, missed parameters: 
    ${!policyNumber ? 'policyNumber, ' : ''}
    ${!idType ? 'typeId, ' : ''}
    ${!idNumber ? 'idNumber, ' : ''}
    ${!expirationDate ? 'expirationDate, ' : ''}
    ${!productCode ? 'productCode, ' : ''}
    ${!modality ? 'modality, ' : ''}
    ${!totalCertificates ? 'totalCertificates, ' : ''}
    `);
  }
  const body = {
    data: {
      policyNumber,
      idType,
      idNumber,
      expirationDate,
      productCode,
      modality,
      totalCertificates
    }
  };
  try {
    const {
      data: { data }
    } = await postRequest('slip-info', body, BaseTypes.policies, {
      withCredentials: true
    });
    return data;
  } catch (error) {
    console.error('Error get slip info :', error);
    return null;
  }
};

export const getIndividualNewCoreInfo = async (query) => {
  const bodyPolicy = {
    data: {
      sseguro: query.sseguro
    }
  };
  const {
    data: { data: dataPolicy }
  } = await postRequest('policy-detail', bodyPolicy, BaseTypes.policies);

  const { movements, notes, persons, risks_tab, claims, coverages, ...policyDashboard } =
    dataPolicy;
  const notHaveNewPolicy = !policyDashboard?.new_policy_number;
  return {
    dataDashboard: {
      ...query,
      ...policyDashboard,
      newCore: query.newCore || query.new_core,
      downloadPDF: query.downloadPDF || query.download_pdf || validitionDownloadPDF(query),
      isIndividual: query.isIndividual || query.is_individual,
      renewal_status: notHaveNewPolicy
        ? maskState(
            policyDashboard?.product_code,
            policyDashboard?.modality,
            policyDashboard.renewal_status,
            policyDashboard?.risks
          )
        : policyDashboard.renewal_status
    },
    movements,
    persons,
    notes,
    risks_tab,
    claims,
    coverages
  };
};

export const getCollectiveADPInfo = async (query) => {
  const bodyPolicy = {
    data: {
      policyNumber: query.policyNumber || query.policy_number,
      policyProduct: query.productCode || query.product_code
    }
  };
  const {
    data: { data: dataPolicy }
  } = await postRequest('policy-adp', bodyPolicy, BaseTypes.policies);
  return {
    dataDashboard: {
      ...query,
      ...dataPolicy,
      newCore: query.newCore || query?.new_core,
      downloadPDF: query.downloadPDF || query?.download_pdf || validitionDownloadPDF(query),
      isIndividual: query.isIndividual || query?.is_individual
    },
    movements: [],
    persons: [],
    risks_tab: [],
    claims: [],
    coverages: [],
    notes: []
  };
};

export const getOnlineInfo = async (query) => {
  const bodyPolicy = {
    data: {
      sseguro: query.sseguro,
      product: query?.productCode || query?.product_code
    }
  };
  const {
    data: { data: data_info_axis }
  } = await postRequest('info-axis-collective', bodyPolicy, BaseTypes.policies);
  const queryProduct = query.product_code || query.productCode;

  const notHaveNewPolicy = !data_info_axis?.new_policy_number;
  const isDifferentOfEmp = 10024 != queryProduct;
  // const isEmpLimitInvalidCertificates =
  //   !isDifferentOfEmp && !data_info_axis.limit_valid_certificates;

  //este cambio solo aplica para produccion en nonprod usar la validacion de arriba
  //hasta la fecha el unico producto que se verifica los certificados es el 900742
  const isEmpLimitInvalidCertificates = !isDifferentOfEmp;

  return {
    ...data_info_axis,
    renewal_status:
      notHaveNewPolicy && (isEmpLimitInvalidCertificates || isDifferentOfEmp)
        ? maskState(
            query.product_code || query.productCode,
            data_info_axis.modality,
            data_info_axis.renewal_status,
            data_info_axis.risks
          )
        : data_info_axis.renewal_status
  };
};

export const getCertificates = async (query, isRenewals) => {
  const bodyCertificates = {
    data: {
      numeroPoliza: query.policy_number || query.policyNumber,
      agrupacion: isRenewals ? getGroup(query.branch.group) : query.branch.group,
      producto: query.product_code,
      fechaEmision: parseDate(query.issue_date || query.issueDate),
      fechaFin: parseDate(query.expiration_date || query.expirationDate),
      filters: {},
      pagination: {
        page: 1,
        per_page: 10
      },
      fromRenewals: isRenewals
    }
  };
  const {
    data: { data: dataCertificates }
  } = await postRequest('certificates', bodyCertificates, BaseTypes.policies);
  return {
    ...dataCertificates,
    errorCertificateList: null
  };
};

export const getCertificatesOnline = async ({ product_code, policy_number }, isSuppleAnnul) => {
  const bodyCertificates = {
    data: {
      product_code,
      policy_number,
      from_supplements: isSuppleAnnul
    }
  };
  const {
    data: { data: dataCertificates }
  } = await postRequest('certificates-online', bodyCertificates, BaseTypes.policies);
  return {
    ...dataCertificates,
    errorCertificateList: null
  };
};

export const filterCertificatesOnline = async ({
  policy_number,
  product_code,
  type,
  value,
  page,
  per_page
}) => {
  try {
    const body = {
      data: {
        policy_number,
        product_code,
        filters: {
          type,
          value
        },
        pagination: {
          page,
          per_page
        }
      }
    };
    const {
      data: { data }
    } = await postRequest('certificates-online', body, BaseTypes.policies);
    const { certificates, total_items, total_pages } = data;
    return {
      queryCertificates: { type, value },
      listCertificatesFiltered: [...certificates],
      infoPage: {
        page,
        total_items,
        total_pages
      },
      errorCertificateList: null
    };
  } catch (error) {
    console.error('Error filter certificates online :', error);
    return {
      queryCertificates: { type, value },
      listCertificatesFiltered: [],
      infoPage: {
        page: 0,
        total_items: 0,
        total_pages: 0
      },
      errorCertificateList: error
    };
  }
};
