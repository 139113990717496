import { SESSION_STORAGE_VARIABLES } from 'common/sessionStorageVariables';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useBreadcrumbInformation = () => {
  const policyAI = useSelector((state) => state.policesAI.policeDashboard);
  const policyOB = useSelector((state) => state.policesOthers.policyOthersBranches.policyDashboard);
  const certificateDetailNumber = useSelector(
    (state) => state.policesOthers.policyOthersBranches.certificateDetail?.numeroCertificado
  );

  const policyNumber = useMemo(() => {
    const policyOBSave = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_VARIABLES.POLICY_OTHER_BRANCH)
    );
    const policyAISave = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_VARIABLES.POLICY_SINGLE_CAR)
    );
    return (
      policyAI?.policyNumber ||
      policyAI?.policy_number ||
      policyAISave?.policyNumber ||
      policyAISave?.policy_number ||
      policyOB?.policyNumber ||
      policyOB?.policy_number ||
      policyOBSave?.policyNumber ||
      policyOBSave?.policy_number ||
      ''
    );
  }, [
    policyAI?.policyNumber,
    policyAI?.policy_number,
    policyOB?.policyNumber,
    policyOB?.policy_number
  ]);

  const certificateNumber = useMemo(() => {
    const certificateSave = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_VARIABLES.CERTIFICATE)
    );
    return certificateDetailNumber || certificateSave?.numeroCertificado || '';
  }, [certificateDetailNumber]);

  return { policyNumber, certificateNumber };
};

export default useBreadcrumbInformation;
