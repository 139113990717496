import {
  CERTIFICATE_DETAIL,
  DETAIL_ROUTES_BASE,
  INFO_DETAIL_ROUTES,
  RoutesBase
} from './baseRoutes';

const SUPPLE_ANNUL_INFO = `${RoutesBase.SUPPLEMENTS_ANNULMENTS}`;
const SUPPLE_ANNUL_INFO_DETAIL = `${SUPPLE_ANNUL_INFO}/${DETAIL_ROUTES_BASE.SINGLE_CARS_DETAIL}`;
const SUPPLE_ANNUL_TRACKING = `${SUPPLE_ANNUL_INFO}/${DETAIL_ROUTES_BASE.TRACKING_POLICY}`;
const SUPPLE_ANNUL_ANNULMENT_POLICY = `${SUPPLE_ANNUL_INFO}/${DETAIL_ROUTES_BASE.ANNULMENT_POLICY}`;
const SUPPLE_ANNUL_INFO_POLICIES = `${SUPPLE_ANNUL_INFO}/${DETAIL_ROUTES_BASE.OTHER_BRANCHES_DETAIL}`;
const SUPPLE_ANNUL_INFO_POLICIES_CERTIFICATE = `${SUPPLE_ANNUL_INFO_POLICIES}/${CERTIFICATE_DETAIL}`;
const SUPPLE_ANNUL_ANNULMENT_POLICY_OTHER_BRANCHES = `${SUPPLE_ANNUL_INFO}/${DETAIL_ROUTES_BASE.ANNULMENT_POLICY_OTHER_BRANCHES}`;

export const SupplementsAnnulmentsRoutes = {
  SUPPLE_ANNUL_INFO,
  SUPPLE_ANNUL_INFO_DETAIL,
  SUPPLE_ANNUL_TRACKING,
  SUPPLE_ANNUL_ANNULMENT_POLICY,
  SUPPLE_ANNUL_ANNULMENT_POLICY_OTHER_BRANCHES,
  SUPPLE_ANNUL_INFO_DETAIL_ROUTES: INFO_DETAIL_ROUTES,
  SUPPLE_ANNUL_INFO_DETAIL_CERTIFICATE: `${SUPPLE_ANNUL_INFO_DETAIL}/${INFO_DETAIL_ROUTES.CERTIFICATE}`,
  SUPPLE_ANNUL_INFO_DETAIL_MOVEMENTS: `${SUPPLE_ANNUL_INFO_DETAIL}/${INFO_DETAIL_ROUTES.MOVEMENTS}`,
  SUPPLE_ANNUL_INFO_DETAIL_PERSONS: `${SUPPLE_ANNUL_INFO_DETAIL}/${INFO_DETAIL_ROUTES.PERSONS}`,
  SUPPLE_ANNUL_INFO_DETAIL_RISKS: `${SUPPLE_ANNUL_INFO_DETAIL}/${INFO_DETAIL_ROUTES.RISKS}`,
  SUPPLE_ANNUL_INFO_DETAIL_COVERAGES: `${SUPPLE_ANNUL_INFO_DETAIL}/${INFO_DETAIL_ROUTES.COVERAGES}`,
  SUPPLE_ANNUL_INFO_DETAIL_CLAIMS: `${SUPPLE_ANNUL_INFO_DETAIL}/${INFO_DETAIL_ROUTES.CLAIMS}`,
  SUPPLE_ANNUL_INFO_POLICIES_MOD_TAKER: `${SUPPLE_ANNUL_INFO_POLICIES}/${INFO_DETAIL_ROUTES.MOD_TAKER}`,
  SUPPLE_ANNUL_INFO_DETAIL_MOD_TAKER: `${SUPPLE_ANNUL_INFO_DETAIL}/${INFO_DETAIL_ROUTES.MOD_TAKER}`,
  SUPPLE_ANNUL_INFO_DETAIL_MOD_INSURED: `${SUPPLE_ANNUL_INFO_DETAIL}/${INFO_DETAIL_ROUTES.MOD_INSURED}`,
  SUPPLE_ANNUL_INFO_DETAIL_PLATE: `${SUPPLE_ANNUL_INFO_DETAIL}/${INFO_DETAIL_ROUTES.PLATE}`,
  SUPPLE_ANNUL_INFO_DETAIL_ACCESSORY: `${SUPPLE_ANNUL_INFO_DETAIL}/${INFO_DETAIL_ROUTES.ACCESSORY}`,
  SUPPLE_ANNUL_INFO_DETAIL_AMPAROS: `${SUPPLE_ANNUL_INFO_DETAIL}/${INFO_DETAIL_ROUTES.AMPAROS}`,
  SUPPLE_ANNUL_INFO_DETAIL_RISKS_DATA: `${SUPPLE_ANNUL_INFO_DETAIL}/${INFO_DETAIL_ROUTES.RISKS_DATA}`,
  SUPPLE_ANNUL_INFO_POLICIES,
  SUPPLE_ANNUL_INFO_POLICIES_MOVIMIENTOS: `${SUPPLE_ANNUL_INFO_POLICIES}/${INFO_DETAIL_ROUTES.MOVEMENTS}`,
  SUPPLE_ANNUL_INFO_POLICIES_PERSONS: `${SUPPLE_ANNUL_INFO_POLICIES}/${INFO_DETAIL_ROUTES.PERSONS}`,
  SUPPLE_ANNUL_INFO_POLICIES_RISKS: `${SUPPLE_ANNUL_INFO_POLICIES}/${INFO_DETAIL_ROUTES.RISKS}`,
  SUPPLE_ANNUL_INFO_POLICIES_COVERAGES: `${SUPPLE_ANNUL_INFO_POLICIES}/${INFO_DETAIL_ROUTES.COVERAGES}`,
  SUPPLE_ANNUL_INFO_POLICIES_CLAIMS: `${SUPPLE_ANNUL_INFO_POLICIES}/${INFO_DETAIL_ROUTES.CLAIMS}`,
  SUPPLE_ANNUL_INFO_POLICIES_CERTIFICATE,
  SUPPLE_ANNUL_INFO_POLICIES_CERTIFICATE_MOVIMIENTOS: `${SUPPLE_ANNUL_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.MOVEMENTS}`,
  SUPPLE_ANNUL_INFO_POLICIES_CERTIFICATE_PERSONS: `${SUPPLE_ANNUL_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.PERSONS}`,
  SUPPLE_ANNUL_INFO_POLICIES_CERTIFICATE_RISKS: `${SUPPLE_ANNUL_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.RISKS}`,
  SUPPLE_ANNUL_INFO_POLICIES_CERTIFICATE_COVERAGES: `${SUPPLE_ANNUL_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.COVERAGES}`,
  SUPPLE_ANNUL_INFO_POLICIES_CERTIFICATE_CLAIMS: `${SUPPLE_ANNUL_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.CLAIMS}`
};
