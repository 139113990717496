import { useDispatch } from 'react-redux';
import { getListNotifications } from 'services/notifications/notifications';
import { fetchCheckDownloadPdfs } from 'store/processDownloadPDF/ActionsProcessDownloadPdf';
import { fetchCheckProcess } from 'store/processRenovation/ActionsProcessRenovation';

interface INotificationsResponse {
  downloadExcelNotification: any;
  massiveRenewalNotification: any;
}

export const useBackGroundProcess = () => {
  const dispatch = useDispatch();

  async function getBackGroundProcess() {
    let data: INotificationsResponse = {
      downloadExcelNotification: [],
      massiveRenewalNotification: []
    };
    try {
      data = await getListNotifications();
    } catch (error) {
      console.error('Error load List Notifications: ', error);
    }

    try {
      await dispatch(fetchCheckProcess());
    } catch (error) {
      console.error('Error load process renovation: ', error);
    }

    try {
      await dispatch(fetchCheckDownloadPdfs());
    } catch (error) {
      console.error('Error Download PDFS', error);
    }

    return data;
  }

  return { getBackGroundProcess };
};
