import { createAsyncThunk } from '@reduxjs/toolkit';
import { postRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';
import { AnnulmentsData, queryState } from './types';

export const fetchLoadAnnulment = createAsyncThunk(
  'annulment/fetchLoadAnnulment',
  async ({ query }: { query: queryState }) => {
    const body = {
      data: {
        ...query
      }
    };
    const {
      data: { data }
    } = await postRequest<{ data: AnnulmentsData }>('cancellation-data', body, BaseTypes.policies);
    return data;
  }
);

export const fetchCancelPolicy = createAsyncThunk(
  'annulment/fetchCancelPolicy',
  async ({ cancellationData, logData }: { cancellationData: any; logData: any }) => {
    const body = { cancellationData, logData };
    const {
      data: { cancellationStatus }
    } = await postRequest<{
      cancellationStatus: boolean;
    }>('cancellation', body, BaseTypes.policies);
    return cancellationStatus;
  }
);
