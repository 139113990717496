import { postRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';

export const policyMessageStatus = async (query) => {
  const bodyStatus = {
    data: {
      sseguro: query.sseguro,
      product: query.product_code,
      status: query.renewal_status,
      modality: String(query.modality),
      csituac: query.situationCode || query.situation_code,
      creteni: query.statusCode || query.status_code,
      numeroCertificado: query.numeroCertificado
    }
  };
  try {
    const {
      data: { data }
    } = await postRequest('policy-status-message', bodyStatus, BaseTypes.policies);
    return data.policy_status_message;
  } catch (error) {
    console.error('Error load message policy-status-message: ', error);
    return '';
  }
};
