export const getDateFormatDDMesAAAA = () => {
  const today = new Date();
  let day = today.getDate();
  let month = today.toLocaleString('es-ES', { month: 'short' });
  const year = today.getFullYear();
  month = month.charAt(0).toUpperCase() + month.slice(1);
  day = day < 10 ? '0' + day : day;

  return `${day}${month}${year}`;
};
