import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';
// import { AnnulmentsData, queryState } from './types';

export const fetchLoadAnnulmentOtherBranch = createAsyncThunk(
  'annulmentOtherBranch/fetchLoadAnnulmentOtherBranch',
  async ({ sseguro }) => {
    try {
      const {
        data: { data }
      } = await getRequest(`cancel-data?sseguro=${sseguro}`, BaseTypes.cancellation);
      return data;
    } catch (error) {
      console.error(error);
      return {
        data: null
      };
    }
  }
);

// export const fetchCancelPolicy = createAsyncThunk(
//   'annulment/fetchCancelPolicy',
//   async ({ cancellationData, logData }: { cancellationData: any; logData: any }) => {
//     const body = { cancellationData, logData };
//     const {
//       data: { cancellationStatus }
//     } = await postRequest<{
//       cancellationStatus: boolean;
//     }>('cancellation', body, BaseTypes.policies);
//     return cancellationStatus;
//   }
// );
