import { RoutesBase } from './baseRoutes';
import { QueriesRoutes } from './queriesRoutes';
import { RenovationsRoutes } from './renovationsRoutes';
import { SinistersRoutes } from './sinisters';
import { SupplementsAnnulmentsRoutes } from './supplementsAnnulmentsRoutes';

export const PathRoutes = {
  ...RoutesBase,
  ...QueriesRoutes,
  ...RenovationsRoutes,
  ...SinistersRoutes,
  ...SupplementsAnnulmentsRoutes,
  NOT_FOUND: '/not-found'
};
