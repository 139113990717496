import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';

export const fetchClaimDetail = createAsyncThunk(
  'claim/fetchClaimDetail',
  async ({ claimNumber }) => {
    try {
      const {
        data: { data }
      } = await getRequest(`/claim-detail/${claimNumber}`, BaseTypes.policies);
      return {
        id: claimNumber,
        claim: { ...data }
      };
    } catch (error) {
      console.error(error);
      return {
        id: claimNumber,
        claim: null
      };
    }
  }
);
