import { createSlice } from '@reduxjs/toolkit';
import {
  fetchLoadCertificateDetail,
  fetchLoadPoliceOthers,
  reLoadPoliceOthers
} from '../policesOthers/ActionsPoliceOthersSlice';
import { fetchClaimDetail } from './ActionsClaimsSlice';

const initialState = {
  sinisters: [],
  isLoadingClaim: false
};

export const claimsSlice = createSlice({
  name: 'policeClaims',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchClaimDetail.pending, (state) => {
      state.isLoadingClaim = true;
    });
    builder.addCase(fetchClaimDetail.fulfilled, (state, { payload }) => {
      state.sinisters = state.sinisters.map((sinister) => ({
        ...sinister,
        claims: sinister.claims.map((claim) => {
          if (claim.claim_number == payload.id) {
            return { ...claim, claimDetail: payload.claim };
          }
          return claim;
        })
      }));
      state.isLoadingClaim = false;
    });
    builder.addCase(fetchLoadPoliceOthers.fulfilled, (state, { payload }) => {
      const { claims } = payload.storageClaimsSlice;
      state.sinisters = claims;
    });
    builder.addCase(reLoadPoliceOthers.fulfilled, (state, { payload }) => {
      const { claims } = payload.storageClaimsSlice;
      state.sinisters = claims;
    });
    builder.addCase(fetchLoadCertificateDetail.fulfilled, (state, { payload }) => {
      const { claims } = payload.storageClaimsSlice;
      state.sinisters = claims;
    });
  },
  reducers: {
    onRestartStateClaims: (state) => {
      state.sinisters = [];
    }
  }
});

export const { onRestartStateClaims } = claimsSlice.actions;
