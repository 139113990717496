import { createSlice } from '@reduxjs/toolkit';
import { fetchLoadLastDateUpdate } from './ActionsGeneralSlice';

interface IState {
  lastDate: any;
  isLoadingGeneralData: boolean;
  errorStatesAndCity: any;
  statesAndCities: any;
}

const initialState: IState = {
  lastDate: null,
  isLoadingGeneralData: false,
  errorStatesAndCity: null,
  statesAndCities: null
};
export const generalSlice = createSlice({
  name: 'general',
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchLoadLastDateUpdate.rejected, (state) => {
      state.lastDate = null;
    });
    builder.addCase(fetchLoadLastDateUpdate.fulfilled, (state, { payload }) => {
      state.lastDate = payload;
    });
  },
  reducers: {
    onRestartState: (state) => {
      state.lastDate = null;
      state.isLoadingGeneralData = false;
      state.errorStatesAndCity = null;
      state.statesAndCities = null;
    },
    onLoadUpdateDate: (state, { payload }) => {
      state.lastDate = payload;
    },
    onLoadStatesAndCities: (state, { payload }) => {
      state.statesAndCities = { cities: payload.cities, states: payload.states };
    },
    onStartLoad: (state) => {
      state.isLoadingGeneralData = true;
    },
    onStopLoad: (state) => {
      state.isLoadingGeneralData = false;
    },
    onLoadError: (state, { payload }) => {
      state.errorStatesAndCity = payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  onRestartState,
  onLoadUpdateDate,
  onStartLoad,
  onStopLoad,
  onLoadStatesAndCities,
  onLoadError
} = generalSlice.actions;
