import { ReactNode } from 'react';
import clsx from 'clsx';
import styles from './ActionsPage.module.scss';

interface ActionsPageProps {
  title: string;
  switchAction: ReactNode;
}

const ActionsPage = ({ title, switchAction }: ActionsPageProps) => {
  return (
    <div className={clsx('liberty__container', styles.root)}>
      <div className={styles.actions}>
        <span className={styles.title}>{title}</span>
        <div className={styles.buttons}>{switchAction}</div>
      </div>
    </div>
  );
};

export default ActionsPage;
