import { lazy, useMemo } from 'react';
import { PathRoutes } from 'common/routes';
import useBreadcrumbInformation from './useBreadcrumbInformation';

const SupplementsAnnulmentsOther = lazy(() =>
  import('pages/SupplementsAnnulmentsOther/SupplementsAnnulmentsOther')
);
const ClaimsInformation = lazy(() => import('pages/ClaimsInfo/ClaimsInfo'));
const SingleCarDetail = lazy(() => import('pages/SingleCarsDetail/SingleCarsDetail'));
const OtherBranchDetail = lazy(() => import('pages/OtherBranchDetail/OtherBranchDetail'));
const Certificate = lazy(() => import('pages/CertificateDetail/CertificateDetail'));
const AnnulmentPolicy = lazy(() => import('pages/AnnulmentPolicy/AnnulmentPolicy'));
const AnnulmentPolicyOtherBranch = lazy(() => import('pages/AnnulmentPolicyOtherBranches/AnnulmentPolicy'));
const Tracking = lazy(() => import('pages/Tracking/Tracking'));

export const useSupplementsAnnulmentsRoutes = () => {
  const { policyNumber, certificateNumber } = useBreadcrumbInformation();
  return useMemo(
    () => [
      {
        path: PathRoutes.SUPPLEMENTS_ANNULMENTS,
        exact: true,
        name: 'Gestión de suplementos y anulaciones',
        title: 'Gestión de suplementos y anulaciones',
        pathToGo: () => {
          return PathRoutes.SUPPLEMENTS_ANNULMENTS;
        },
        element: SupplementsAnnulmentsOther
      },
      // {
      //   path: PathRoutes.SUPPLE_ANNUL_INFO,
      //   exact: true,
      //   name: 'Gestión de suplementos y anulaciones',
      //   title: 'Gestión de suplementos y anulaciones',
      //   pathToGo: () => {
      //     return PathRoutes.SUPPLE_ANNUL_INFO;
      //   },
      //   element: ClaimsInformation
      // },
      {
        path: PathRoutes.SUPPLE_ANNUL_INFO_DETAIL + '/*',
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.SUPPLE_ANNUL_INFO_DETAIL_MOVEMENTS;
        },
        element: SingleCarDetail
      },
      {
        path: PathRoutes.SUPPLE_ANNUL_INFO_POLICIES + '/*',
        exact: true,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.SUPPLE_ANNUL_INFO_POLICIES_MOVIMIENTOS;
        },
        element: OtherBranchDetail
      },
      {
        path: PathRoutes.SUPPLE_ANNUL_INFO_POLICIES_CERTIFICATE + '/*',
        exact: true,
        name: `Certificado ${certificateNumber || ''}`,
        title: `Certificado ${certificateNumber || ''}`,
        pathToGo: () => {
          return PathRoutes.SUPPLE_ANNUL_INFO_POLICIES_CERTIFICATE_MOVIMIENTOS;
        },
        element: Certificate
      },
      {
        path: PathRoutes.SUPPLE_ANNUL_ANNULMENT_POLICY,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.SUPPLE_ANNUL_INFO_DETAIL_MOVEMENTS;
        },
        element: AnnulmentPolicy
      },
      {
        path: PathRoutes.SUPPLE_ANNUL_ANNULMENT_POLICY_OTHER_BRANCHES,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.SUPPLE_ANNUL_INFO_POLICIES_MOVIMIENTOS;
        },
        element: AnnulmentPolicyOtherBranch
      },
      {
        path: PathRoutes.SUPPLE_ANNUL_TRACKING,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.SUPPLE_ANNUL_INFO_DETAIL_CLAIMS;
        },
        element: Tracking
      }
    ],
    [certificateNumber, policyNumber]
  );
};
