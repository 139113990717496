import { Groupings } from 'common/constants';

const groupingsMap = {
  [Groupings.AUT]: 'Autos',
  [Groupings.EMP]: 'Empresariales',
  [Groupings.HOG]: 'Hogar',
  [Groupings.SAL]: 'Salud',
  [Groupings.TRA]: 'Transportes',
  [Groupings.VID]: 'Vida'
};

export const getGroup = (key: Groupings) => {
  return groupingsMap[key] || '';
};
