import styles from './LibertyCustomRadioButton.module.scss';

export const LibertyCustomRadioButton = ({
  height = '18px',
  width = '18px',
  marginTop = '35.75px',
  onChange,
  name,
  id,
  value,
  checkSelected
}) => {
  return (
    <label className={styles.content_input} style={{ height, width, marginTop }}>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        onChange={(e) => onChange?.(e)}
        checked={checkSelected === name}
      />
      <div className={styles.circle} />
    </label>
  );
};
