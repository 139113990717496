export enum Types {
  RENOVATIONS = 'RENOVATIONS',
  NOTIFICATION = 'NOTIFICATION',
  DOCUMENTS = 'DOCUMENTS',
  COLLECTIVE_PDF_DOWNLOAD_REPORT = 'COLLECTIVE_PDF_DOWNLOAD_REPORT'
}

export enum TabsNotificationTypes {
  RENOVATIONS = 'RENOVATIONS',
  DOWNLOAD_EXCEL = 'DOWNLOAD_EXCEL',
  DOWNLOAD_PDF = 'DOWNLOAD_PDF',
  COLLECTIVE_PDF_DOWNLOAD_REPORT = 'COLLECTIVE_PDF_DOWNLOAD_REPORT'
}
