import userIcon from 'assets/icons/user.svg';
import hamburgerMenu from 'assets/icons/hamburger-menu.svg';
import close from 'assets/icons/close.svg';
import emailIcon from 'assets/icons/email.svg';
import TriangleWhiteUp from 'assets/icons/TriangleWhiteUp.svg';
import LibertyButton from '../LibertyButton/LibertyButton';
import LibertyFooter from 'components/basics/LibertyFooter/LibertyFooter';
import styles from './LibertyProfileInfo.module.scss';
import { dateToString, parseDateHours } from 'utils/formatDate';
import React, { useState, useEffect } from 'react';

export const LibertyProfileInfo = ({
  name,
  email,
  ip,
  lastConnection,
  dateToday,
  children,
  open = false
}) => {
  return (
    <div className={styles.container_profile_info}>
      {children}
      {open && (
        <div className={`${styles.tooltip_user}`}>
          <div className={styles.triangle_up}>
            <img src={TriangleWhiteUp} alt="TriangleWhiteUp" />
          </div>
          <div className={styles.info_user_icon}>
              <span className={styles.title_tooltip}>Perfil</span>

            <div className={styles.container_profile}>
              <div className={styles.container_image_info}>
                <div className={styles.icon}>
                  <img src={userIcon} alt="userIcon" />
                </div>
                <div className={styles.container_info}>
                  <span className={styles.title}>Nombre</span>
                  <span className={styles.info}>{name || ''}</span>
                </div>
              </div>
              <div className={styles.container_image_info}>
                <div className={styles.icon}>
                  <img
                    src={emailIcon}
                    alt="emailIcon"
                    style={{
                      width: '16px',
                      height: '17.91px'
                    }}
                  />
                </div>
                <div className={styles.container_info}>
                  <span className={styles.title}>Email</span>
                  <span className={styles.info}>{email || ''}</span>
                </div>
              </div>
            </div>
            <div className={styles.container_info_session}>
              <div className={styles.container_info}>
                <span className={styles.title}>IP</span>
                <span className={styles.info}>{ip}</span>
              </div>
              <div className={styles.container_info}>
                <span className={styles.title}>Hora última conexión</span>
                <span className={styles.info}>{parseDateHours(lastConnection)}</span>
              </div>
              <div className={styles.container_info}>
                <span className={styles.title}>Fecha última conexión</span>
                <span className={styles.info}>{dateToString(lastConnection)}</span>
              </div>
              <div className={styles.container_info}>
                <span className={styles.title}>Fecha actual</span>
                <span className={styles.info}>{dateToday}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
