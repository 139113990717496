import { PathRoutes } from 'common/constants';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import LibertyChangePageButton from 'components/basics/LibertyChangePageButton/LibertyChangePageButton';

export const SwitchSinisters = () => {
  const navigate = useNavigate();
  const optionsList = useMemo(
    () => [
      {
        name: 'Producción',
        tooltip: null,
        action: () => navigate(PathRoutes.QUERIES),
        id: 'id_to_consultation_button'
      },
      {
        name: 'Renovación',
        action: () => navigate(PathRoutes.RENOVATIONS),
        id: 'id_to_renovation_button'
      },
      {
        name: 'Suplementos y anulaciones',
        action: () => navigate(PathRoutes.SUPPLEMENTS_ANNULMENTS),
        id: 'id_to_supplements_button'
      }
    ],
    [navigate]
  );

  return (
    <LibertyChangePageButton title={'Otras consultas'} options={optionsList} buttonStyles={true} />
  );
};
