import { useEffect, useMemo, useRef, useState } from 'react';

import arrowIcon from 'assets/icons/arrowDown.svg';

import styles from './LibertyCustomSearchBox.module.scss';

export const LibertyCustomSearchBox = ({
  disable = false,
  width = '100%',
  height = '48px',
  onChange,
  optionSelected = null,
  title = 'Placeholder',
  list: listBase = []
}) => {
  const searchBoxRef = useRef();
  const inputSearchBoxRef = useRef();
  const optionsBoxRef = useRef();

  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const [coordinateY, setCoordinateY] = useState(0);
  const [liPosition, setLiPosition] = useState(0);

  const stylePlaceholder = useMemo(
    () =>
      isOpen || inputValue || optionSelected ? styles.placeholder_up : styles.placeholder_down,
    [isOpen, inputValue, optionSelected]
  );

  const handlerChange = (optionSelected) => {
    setInputValue(optionSelected.name);
    onChange?.(optionSelected);
  };

  const resetCoordinatesAndLiPosition = () => {
    setLiPosition(0);
    setCoordinateY(0);
    optionsBoxRef.current?.scroll(0, 0);
  };

  const list = useMemo(() => {
    resetCoordinatesAndLiPosition();
    if (!inputValue) return listBase;
    return listBase.filter((element) =>
      element.name.toUpperCase().match(inputValue.toString().toUpperCase())
    );
  }, [listBase, inputValue]);

  const getRandomInt = () => {
    const crypto = window.crypto || window.msCrypto;
    const array = new Uint32Array(1);
    const uint32 = crypto.getRandomValues(array); // Compliant for security-sensitive use cases
    return uint32[0];
  };

  const makeScroll = (value) => {
    const maxScroll = Math.abs(value) * (list.length - 6);
    let newY = coordinateY + value;
    if (newY < 0) newY = 0;
    else if (newY > maxScroll) newY = maxScroll;
    setCoordinateY(newY);
    optionsBoxRef.current.scroll(0, newY);
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    const value = e.target.value;
    if (value) {
      setInputValue(value);
    } else {
      setInputValue('');
      onChange?.(null);
    }
  };

  const changeOption = (e) => {
    if (!optionsBoxRef.current) {
      return; // No existe la lista
    }
    const items = optionsBoxRef.current.querySelectorAll('li');
    // Saber si alguno está activo
    let actual = 0;
    if (liPosition) actual = liPosition;
    // Analizar tecla pulsada
    if (e.keyCode == 13) {
      // Tecla Enter, evitar que se procese el formulario
      e.preventDefault();
      // ¿Hay un elemento activo?
      if (items[actual]) {
        // Hacer clic
        items[actual].click();
      }
    }
    if (e.keyCode == 38 || e.keyCode == 40) {
      // Calcular posición del siguiente
      // Time out para no sobracargar la funcion
      setTimeout(() => {
        if (e.keyCode == 38) {
          actual = actual - 1;
          makeScroll(-45);
        } else {
          actual = actual + 1;
          makeScroll(45);
        }

        // Asegurar que está dentro de los límites
        if (actual < 0) {
          actual = 0;
        } else if (actual > items.length - 1) {
          actual = items.length - 1;
        }
        setLiPosition(actual);
      }, 100);
    }
  };

  useEffect(() => {
    if (!disable) {
      setInputValue('');
    }
  }, [disable]);

  useEffect(() => {
    if (optionSelected && !inputValue && !isOpen) {
      setInputValue(optionSelected);
    } else if (optionSelected && !isOpen) {
      setInputValue(optionSelected);
    } else if (!optionSelected && !isOpen) {
      setInputValue('');
    }
  }, [optionSelected, inputValue, isOpen]);

  useEffect(() => {
    if (isOpen) {
      resetCoordinatesAndLiPosition();
      inputSearchBoxRef.current.focus();
    } else {
      if (!inputValue) onChange?.(null);
      else if (inputValue !== optionSelected) setInputValue('');
      inputSearchBoxRef.current.blur();
    }
  }, [isOpen, inputValue, onChange]);

  useEffect(() => {
    const closeSelect = (e) => {
      if (
        (!e.composedPath().includes(searchBoxRef.current) &&
          !e.composedPath().includes(inputSearchBoxRef.current)) ||
        e.composedPath().includes(optionsBoxRef.current)
      ) {
        setIsOpen(false);
      }
    };
    document.body.addEventListener('click', closeSelect);
    return () => {
      document.body.removeEventListener('click', closeSelect);
    };
  }, []);

  return (
    <div
      ref={searchBoxRef}
      className={`${styles.container_search_box} ${disable && styles.disable}`}
      style={{ width, height, border: isOpen && !disable && '2px solid #28a3af' }}
    >
      <div
        className={styles.header}
        onClick={() => {
          !disable && setIsOpen(true);
        }}
      >
        <label htmlFor="placeholder" className={`${styles.placeholder} ${stylePlaceholder}`}>
          {title}
        </label>
        <input
          onKeyDown={changeOption}
          disabled={disable}
          value={inputValue}
          onChange={onChangeInput}
          style={{
            display: isOpen || optionSelected ? 'inline-block' : 'none'
          }}
          ref={inputSearchBoxRef}
          type="text"
          className={styles.input_search}
        />
        <div className={styles.container_arrow}>
          <img className={styles.arrow} src={arrowIcon} alt="arrowIcon" />
        </div>
      </div>
      <ul
        ref={optionsBoxRef}
        className={styles.options_box}
        style={{ height: isOpen ? list.length * 45 + 'px' : ' 0px' }}
      >
        {list.map((element, i) => {
          const idElement = `${new Date().getTime() + getRandomInt()}-${getRandomInt()}-${
            element.name
          }`;
          return (
            <li
              className={`${styles.option} ${liPosition == i && styles.active}`}
              key={idElement}
              onClick={() => handlerChange(element)}
            >
              <span>{element.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
