const JWT_KEY = 'jwt';
const JWT_REFER = 'jwt-refer';
const REFER = 'refer';

export const saveTokens = (refer, jwtCognito, jwtRefer) => {
  sessionStorage.setItem(REFER, refer);
  sessionStorage.setItem(JWT_KEY, jwtCognito);
  sessionStorage.setItem(JWT_REFER, jwtRefer);
};

export const getTokens = () => {
  return {
    refer: sessionStorage.getItem(REFER),
    jwt: sessionStorage.getItem(JWT_KEY),
    jwtRefer: sessionStorage.getItem(JWT_REFER)
  };
};

export const clearAll = () => {
  sessionStorage.removeItem(JWT_KEY);
};

export function buildSession(data) {
  return {
    name:
      data.name || data.nombre || data.username || data.nombreUsuario || data.nombreIntermediario,
    broker: data.broker,
    accessToken: data.accessToken,
    email: data.email,
    dynamoData: data.dynamo_data,
    ip:data.ip
  };
}
