import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { LibertyCrumbsResponsive } from './LibertyCrumbsResponsive/LibertyCrumbsResponsive';
import { useRoutes } from 'router';
import styles from './LibertyBreadcrumbsResponsive.module.scss';
import { useSelector } from 'react-redux';

const LibertyBreadcrumbsResponsive = ({ location }) => {
  const policeDashboard = useSelector((state) => state.policesAI.policeDashboard);
  const policyDashboardOB = useSelector(
    (state) => state.policesOthers.policyOthersBranches.policyDashboard
  );
  const certificateDetail = useSelector(
    (state) => state.policesOthers.policyOthersBranches.certificateDetail
  );

  const { routes } = useRoutes();
  const [crumbs, setCrumbs] = useState([]);
  const id = location?.pathname?.match(/(\d+)/);
  const b = !!id && id;

  useEffect(() => {
    const Crumbs = routes
      .filter(
        ({ path }) =>
          location.pathname.includes(path) || location.pathname.includes(path.slice(0, -3))
      )
      .map(({ path, ...rest }) => ({
        path,
        style: {},
        ...rest
      }));

    Crumbs[Crumbs.length - 1] = { ...Crumbs[Crumbs.length - 1], style: { color: '#28A3AF' } };

    setCrumbs(Crumbs);
  }, [
    location.pathname,
    policyDashboardOB?.numeroPoliza,
    certificateDetail?.codigoCertificado,
    policeDashboard?.numeroPoliza,
    routes
  ]);

  return (
    <div className={styles.liberty_bread_crumbs}>
      {crumbs.map((e, index) =>
        index === crumbs.length - 1 ? (
          <LibertyCrumbsResponsive key={e.path} e={e} b={b} location={location} styles={styles} />
        ) : null
      )}
    </div>
  );
};
LibertyBreadcrumbsResponsive.propTypes = {
  location: PropTypes.object
};

export default LibertyBreadcrumbsResponsive;
