import qs from 'qs';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import es from 'date-fns/locale/es';
import enUS from 'date-fns/locale/en-US';
import enAU from 'date-fns/locale/en-AU';
import parse from 'date-fns/parse';
import isNetworkOrIdempotentRequestError from 'axios-retry';

import { RenewalsQuery } from 'store/renewals/types';
import { Branches, DATE_FORMAT, PersonRoles } from 'common';
import { FilePersonResponse } from 'services/policies/files/types';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isAfter from 'date-fns/isAfter';
import { Legal, Natural, PersonBase } from 'store/persons/types';
import axiosRetry from 'axios-retry';

export const idsToBlockAttachFunction = ['T.I.', 'BIC', 'R.C.'];

//*Variables para gestionar los ambientes
export const isDev = () => process.env.NODE_ENV === 'development';
export const isProd = process.env.REACT_APP_IS_PROD == 'true';

export const isBranch = (branch: string): branch is Branches => branch in Branches;
export const getRenewalsQueryParams = (search: string): RenewalsQuery => {
  const { period, branch } = qs.parse(search) as Record<keyof RenewalsQuery, string>;
  return {
    period: period && parseISO(period),
    branch: isBranch(branch) ? branch : undefined
  };
};

export function saveAs(blob: Blob | MediaSource, name: string) {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface RetryPromiseOptions {
  retries?: number;
  retryDelay?: number;
  retryOn?: ((error: any) => boolean) | number[];
}

export const retryPromise = <T, A>(
  func: (...args: A[]) => Promise<T> | T,
  options?: RetryPromiseOptions
) => {
  const { retries = 5, retryDelay = 1500, retryOn } = { ...options };
  // eslint-disable-next-line
  return async (...args: A[]) => {
    let retry = 0;
    do {
      try {
        // eslint-disable-next-line
        const result = await func(...args);
        return result;
      } catch (e) {
        if (
          (Array.isArray(retryOn) ? retryOn.includes(+e?.response?.status) : retryOn?.(e)) ||
          (e.isAxiosError && axiosRetry.isNetworkOrIdempotentRequestError(e))
        ) {
          if (retry >= retries) throw e;
          await sleep(retryDelay * retry);
        } else throw e;
      }
      retry++;
      // eslint-disable-next-line
    } while (true);
  };
};

export const isSameOrAfter = (date: Date | number, dateToCompare: Date | number) =>
  isAfter(date, dateToCompare) || isEqual(date, dateToCompare);

export const isSameOrBefore = (date: Date | number, dateToCompare: Date | number) =>
  isBefore(date, dateToCompare) || isEqual(date, dateToCompare);

export const hasSarlaft = ({ without_sarlaft }: Pick<PersonBase, 'without_sarlaft'>) =>
  without_sarlaft !== '1';
export const hasVerify = ({ without_verif }: Pick<PersonBase, 'without_verif'>) =>
  without_verif !== '1';

export const TIME_HABEAS_DATA = 10;
export const hasHabeasData = (
  person: Legal | Natural,
  roles: PersonRoles[],
  filesPerson?: (FilePersonResponse | null | undefined)[]
) => {
  const filesPersonDispatched = filesPerson?.filter((filePerson) => !!filePerson?.dispatched);

  if (filesPersonDispatched?.length) {
    const valid = filesPersonDispatched.some(
      ({ dispatched }) => differenceInMinutes(new Date(), dispatched) >= TIME_HABEAS_DATA
    );
    if (valid) return true;
  }
  return roles.some(sarlaftRequired) && hasSarlaft(person);
};

export const hasHabeasDataRequired = (role: PersonRoles) =>
  role === PersonRoles.insured ||
  role === PersonRoles.beneficiary ||
  role === PersonRoles.beneficiaryDental;

export const sarlaftRequired = (role: PersonRoles) => role === PersonRoles.taker;
export const identityRequired = (role: PersonRoles) => role === PersonRoles.taker;

/**
 *
 * @param date Date to format
 * 12/Dic/2022, 12/Dec/2022
 */
export const parseDate = (date: string) => {
  const langs = [es, enUS, enAU];
  const formats = [DATE_FORMAT, 'yyyy-MMM-dd'];
  try {
    for (const lang of langs) {
      for (const format of formats) {
        const parsedDate = parse(date, format, new Date(), {
          locale: lang
        });
        if (!isNaN(+parsedDate)) return parsedDate;
      }
    }
    throw new Error(`Invalid date format: ${date}`);
  } catch (e) {
    console.error(e);
    return new Date();
  }
};

export function getIAxisError(resp?: { terror?: string; coderror: number }) {
  if (resp?.coderror === 0) return null;
  const code = resp?.terror?.trim().split(' ')[0];
  const message = resp?.terror.replace(resp?.terror.trim().split(' ')[0], '').replace(/\\n/g, '');
  return { code, message };
}

export function setTerror(resp?: { terror?: string }) {
  if (!resp?.terror) return null;
  const { msg: message, code } = errorParser(resp?.terror);
  return { code, message };
}

export const errorParser = (error) => {
  if (!error) return { msg: null, code: null };
  const codeError = String(error).match(/(\d+)/g)?.[0];
  if (String(error).match('Emisión correcta')) {
    return { msg: null, code: 'ok' };
  } else if (codeError == '107804') {
    return {
      msg: 'No se ha realizado ningún cambio',
      code: '107804'
    };
  } else if (codeError == '103308') {
    return {
      msg: 'La fecha del suplemento no puede ser superior a la fecha de la próxima cartera',
      code: '103308'
    };
  } else if (codeError == '180386') {
    return {
      msg: 'La fecha del suplemento no puede ser inferior a la fecha de alta o última renovación',
      code: '180386'
    };
  } else if (codeError == '89906663') {
    return {
      msg: 'La fecha del suplemento no puede ser igual o superior a la fecha de programación anulada al vencimiento',
      code: '89906663'
    };
  } else if (codeError == '89906872') {
    return {
      msg: 'Tarifa no válida. Verifica los datos ingresados e intenta nuevamente',
      code: '89906872'
    };
  } else if (codeError == '9905731') {
    return {
      msg: 'El amparo  esta sujeto al departamento y ciudad de circulación del conductor, no se permite su contratación',
      code: '9905731'
    };
  } else if (codeError == '9904748') {
    return {
      msg: 'Formato de chasis incorrecto',
      code: '9904748'
    };
  } else if (codeError == '105577') {
    return {
      msg: 'Error al modificar los amparo',
      code: '105577'
    };
  } else if (codeError == '9906318') {
    return {
      msg: 'Capital no permitido por el tipo de moto seleccionado Gastos médicos',
      code: '9906318'
    };
  } else if (codeError == '0') {
    return {
      msg: 'Se ha producido un error en la emisi: 23008240El suplemento no se ha podido registrar.Póngase en contacto con el departamento de vida. 1 Ficha ya existente',
      code: '0'
    };
  } else if (codeError) {
    return {
      msg: '',
      code: 'not-found'
    };
  } else {
    return { msg: null, code: 'ok' };
  }
};
