import {
  CERTIFICATE_DETAIL,
  DETAIL_ROUTES_BASE,
  INFORMATION_POLICIES,
  INFO_DETAIL_ROUTES,
  RoutesBase
} from './baseRoutes';

const RENOVATIONS_INFO = `${RoutesBase.RENOVATIONS}/${INFORMATION_POLICIES}`;
const RENOVATIONS_INFO_DETAIL = `${RENOVATIONS_INFO}/${DETAIL_ROUTES_BASE.SINGLE_CARS_DETAIL}`;
const RENOVATIONS_TRACKING = `${RENOVATIONS_INFO}/${DETAIL_ROUTES_BASE.TRACKING_POLICY}`;
const RENOVATIONS_ANNULMENT_POLICY = `${RENOVATIONS_INFO}/${DETAIL_ROUTES_BASE.ANNULMENT_POLICY}`;
const RENOVATIONS_INFO_POLICIES = `${RENOVATIONS_INFO}/${DETAIL_ROUTES_BASE.OTHER_BRANCHES_DETAIL}`;
const RENOVATIONS_INFO_POLICIES_CERTIFICATE = `${RENOVATIONS_INFO_POLICIES}/${CERTIFICATE_DETAIL}`;
const RENOVATIONS_ANNULMENT_POLICY_OTHER_BRANCHES = `${RENOVATIONS_INFO}/${DETAIL_ROUTES_BASE.ANNULMENT_POLICY_OTHER_BRANCHES}`;

export const RenovationsRoutes = {
  RENOVATIONS_INFO,
  RENOVATIONS_INFO_DETAIL,
  RENOVATIONS_TRACKING,
  RENOVATIONS_INFO_DETAIL_ROUTES: INFO_DETAIL_ROUTES,
  RENOVATIONS_ANNULMENT_POLICY,
  RENOVATIONS_ANNULMENT_POLICY_OTHER_BRANCHES,
  RENOVATIONS_INFO_DETAIL_MOVEMENTS: `${RENOVATIONS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.MOVEMENTS}`,
  RENOVATIONS_INFO_DETAIL_CERTIFICATE: `${RENOVATIONS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.CERTIFICATE}`,
  RENOVATIONS_INFO_DETAIL_PERSONS: `${RENOVATIONS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.PERSONS}`,
  RENOVATIONS_INFO_DETAIL_RISKS: `${RENOVATIONS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.RISKS}`,
  RENOVATIONS_INFO_DETAIL_COVERAGES: `${RENOVATIONS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.COVERAGES}`,
  RENOVATIONS_INFO_DETAIL_CLAIMS: `${RENOVATIONS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.CLAIMS}`,
  RENOVATIONS_INFO_DETAIL_MOD_TAKER: `${RENOVATIONS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.MOD_TAKER}`,
  RENOVATIONS_INFO_POLICIES_MOD_TAKER: `${RENOVATIONS_INFO_POLICIES}/${INFO_DETAIL_ROUTES.MOD_TAKER}`,
  RENOVATIONS_INFO_DETAIL_MOD_INSURED: `${RENOVATIONS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.MOD_INSURED}`,
  RENOVATIONS_INFO_DETAIL_PLATE: `${RENOVATIONS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.PLATE}`,
  RENOVATIONS_INFO_DETAIL_AMPAROS: `${RENOVATIONS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.AMPAROS}`,
  RENOVATIONS_INFO_POLICIES,
  RENOVATIONS_INFO_POLICIES_MOVIMIENTOS: `${RENOVATIONS_INFO_POLICIES}/${INFO_DETAIL_ROUTES.MOVEMENTS}`,
  RENOVATIONS_INFO_POLICIES_PERSONS: `${RENOVATIONS_INFO_POLICIES}/${INFO_DETAIL_ROUTES.PERSONS}`,
  RENOVATIONS_INFO_POLICIES_CERTIFICATE,
  RENOVATIONS_INFO_POLICIES_CERTIFICATE_MOVIMIENTOS: `${RENOVATIONS_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.MOVEMENTS}`,
  RENOVATIONS_INFO_POLICIES_CERTIFICATE_PERSONS: `${RENOVATIONS_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.PERSONS}`,
  RENOVATIONS_INFO_POLICIES_CERTIFICATE_RISKS: `${RENOVATIONS_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.RISKS}`,
  RENOVATIONS_INFO_POLICIES_CERTIFICATE_COVERAGES: `${RENOVATIONS_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.COVERAGES}`,
  RENOVATIONS_INFO_POLICIES_CERTIFICATE_CLAIMS: `${RENOVATIONS_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.CLAIMS}`
};
