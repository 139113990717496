import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { LibertyCrumbs } from './LibertyCrumbs/LibrertyCrums';
import { useRoutes } from 'router';
import styles from './LibertyBreadcrumbs.module.scss';
import { useSelector } from 'react-redux';

import arrowRight from 'assets/icons/arrowRight.svg';
import { useSession } from 'context/SessionContext';

const LibertyBreadcrumbs = ({ location }) => {
  const policeDashboard = useSelector((state) => state.policesAI.policeDashboard);
  const { logout } = useSession();
  const policyDashboardOB = useSelector(
    (state) => state.policesOthers.policyOthersBranches.policyDashboard
  );
  const certificateDetail = useSelector(
    (state) => state.policesOthers.policyOthersBranches.certificateDetail
  );

  const { routes } = useRoutes();
  const [crumbs, setCrumbs] = useState([]);
  const id = location?.pathname?.match(/(\d+)/);
  const b = !!id && id;

  useEffect(() => {
    const Crumbs = routes
      .filter(
        ({ path }) =>
          location.pathname.includes(path) || location.pathname.includes(path.slice(0, -3))
      )
      .map(({ path, ...rest }) => ({
        path,
        style: {},
        ...rest
      }));

    Crumbs[Crumbs.length - 1] = { ...Crumbs[Crumbs.length - 1], style: { color: '#28A3AF' } };

    setCrumbs(Crumbs);
  }, [
    location.pathname,
    policyDashboardOB?.numeroPoliza,
    certificateDetail?.codigoCertificado,
    policeDashboard?.numeroPoliza,
    routes
  ]);

  return (
    <div className={styles.liberty_bread_crumbs}>
      <span className={styles.title} onClick={logout}>
        Oficina en línea
      </span>
      <img src={arrowRight} alt="arrow" className={styles.arrow} />
      <span className={styles.title} onClick={logout}>
        Consultas
      </span>
      {crumbs.map((e) => (
        <LibertyCrumbs key={e.path} e={e} b={b} location={location} styles={styles} />
      ))}
    </div>
  );
};
LibertyBreadcrumbs.propTypes = {
  location: PropTypes.object
};

export default LibertyBreadcrumbs;
