import React, { useEffect, useRef } from 'react';
import getMinutes from 'date-fns/getMinutes';
import sub from 'date-fns/sub';
import getSeconds from 'date-fns/getSeconds';
import { ClockDigitContainer } from './ClockDigitContainer';
import { ClockDigit } from './ClockDigit';
import styles from './DigitalClock.module.scss';
import { Grid } from '@mui/material';
import clsx from 'clsx';

const padZero = (num) => {
  if (num < 10) {
    num = `${0}${num}`;
  }
  return num;
};

interface DigitalClockProps {
  time: number;
  onChangeSecond?(nextSecond: number): void;
}

const DigitalClock = ({ time, onChangeSecond }: DigitalClockProps) => {
  const nextDigit = sub(time, { seconds: 1 });
  const iterationTime = useRef<Date>();

  const seconds = getSeconds(time);
  const nextSeconds = getSeconds(nextDigit);

  useEffect(() => {
    const ms = 1000;

    const timeOut = setTimeout(() => {
      const last = +iterationTime.current || 0;
      const waiter = +new Date() - last;
      if (waiter > ms || !last) onChangeSecond?.(+nextDigit);
    }, ms);
    return () => {
      clearTimeout(timeOut);
    };
  }, [nextDigit, onChangeSecond]);

  return (
    <Grid container justifyContent="center">
      <ClockDigitContainer>
        <ClockDigit>{padZero(getMinutes(!time ? time : nextDigit))}</ClockDigit>
      </ClockDigitContainer>
      <div className={styles.twoPoints}>:</div>
      <ClockDigitContainer>
        <ClockDigit className={clsx(time && styles.digitDownAnim)}>{padZero(seconds)}</ClockDigit>
        {!!time && (
          <ClockDigit
            className={styles.digitDownAnim}
            onAnimationIteration={() => {
              const now = new Date();
              iterationTime.current = now;
              onChangeSecond?.(+nextDigit);
            }}
          >
            {padZero(nextSeconds)}
          </ClockDigit>
        )}
      </ClockDigitContainer>
    </Grid>
  );
};

export default DigitalClock;
