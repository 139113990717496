import { createSlice } from '@reduxjs/toolkit';
import { fetchLoadActions } from './ActionsOptionsPolicyDasboardSlice';

const initialState = {
  actions: {
    isLoading: false,
    query: null,
    error: null
  }
};

export const OptionsActionsSlice = createSlice({
  name: 'OptionsActions',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchLoadActions.pending, (state) => {
      state.actions = {
        ...state.actions,
        isLoading: true
      };
    });
    builder.addCase(fetchLoadActions.fulfilled, (state, { payload }) => {
      state.actions = {
        ...payload,
        isLoading: false
      };
    });
  },
  reducers: {
    onResetState: (state) => {
      state.actions = initialState.actions;
    },
    onResetSearch: (state) => {
      state.actions = initialState.actions;
    },
    onSetResults: (state, { payload }) => {
      state.actions = { ...payload };
    }
  }
});

export const { onResetState, onSetResults, onResetSearch } = OptionsActionsSlice.actions;
