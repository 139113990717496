import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest, postRequest } from 'services/client';
import {
  onRestartState,
  onStartLoad,
  onStopLoad,
  onError,
  onStartLoadReprint,
  onStopLoadRePrint,
  onErrorReprintPDF,
  onSetSuccessDownload,
  onSetErrorsDownload
} from 'store/downloadAlerts/downloadAlertsSlices';

import { RootState } from '../../store/store';
import { retryPromise, saveAs, sleep } from 'utils';
import { QueryAttributes } from './types';
import { NotificationActions } from 'models/sqs';
import { STATUS_CODE_TO_RETRY } from 'common';
import { BaseTypes } from 'services/client/const';
import triggerQSI from 'hooks/useQSITrigger/triggerQSI';
import { SEND_TRIGGERS_QUALTRICS } from 'common/sendTriggersQualtrics';
import { reLoadPoliceOthers } from 'store/policesOthers/ActionsPoliceOthersSlice';
import LibertyButton from 'components/basics/LibertyButton/LibertyButton';
import LibertyModal from 'components/basics/LibertyModal/LibertyModal';
import shieldModal from 'assets/icons/shieldModal.svg';
import { ButtonTypes } from 'components/basics/LibertyButton/constants';

export const useDownloadAlertsStore = () => {
  const {
    bad,
    good,
    nameDocument,
    data,
    isLoading: isLoadingDownload,
    isLoadingRePrint,
    errorReprintPDF,
    title,
    description
  } = useSelector((state: RootState) => state.alertDownload);
  const dispatch = useDispatch();

  const clearDocumentDownload = () => {
    dispatch(onRestartState());
  };

  const getPDF = useCallback(
    async (query: QueryAttributes, nameDocument: string) => {
      let documentsSuccess = [];
      let documentsErrors = [];
      try {
        dispatch(onStartLoad());
        const body = {
          data: { ...query }
        };

        const getPdfRetry = retryPromise(
          () => postRequest<{ data: any }>('get-pdf', body, BaseTypes.policies),
          {
            retryOn: STATUS_CODE_TO_RETRY
          }
        );

        const {
          data: { data }
        } = await getPdfRetry();
        const documents = data?.documents || [];
        for (let indexDocuments = 0; indexDocuments < documents.length; indexDocuments++) {
          const file = documents[indexDocuments]?.file;
          const endorsementClause = documents[indexDocuments]?.endorsementClause;
          if (!file) {
            documentsErrors = [
              ...documentsErrors,
              {
                title: 'Descarga incompleta',
                message: (
                  <>
                    Algo ha sucedido descargando{' '}
                    {endorsementClause ? 'la Cláusula de endoso beneficiario' : nameDocument} <br />
                    intenta nuevamente
                  </>
                ),
                id: `${endorsementClause ? 'CEB' : 'POL'}-${indexDocuments}`
              }
            ];
          } else {
            const nameDownloadDocument = endorsementClause
              ? 'Cláusula de endoso beneficiario'
              : nameDocument;
            const linkSource = `data:application/pdf;base64,${file}`;
            const downloadLink = document.createElement('a');
            downloadLink.href = linkSource;
            downloadLink.download = nameDownloadDocument;
            downloadLink.click();
            documentsSuccess = [
              ...documentsSuccess,
              {
                name: nameDownloadDocument,
                id: `${endorsementClause ? 'CEB' : 'POL'}-${indexDocuments}`
              }
            ];
          }
        }
        dispatch(onSetSuccessDownload([...documentsSuccess]));
        dispatch(onSetErrorsDownload([...documentsErrors]));
      } catch (error) {
        console.error('Error download:', error);
        dispatch(onError(error));
        dispatch(
          onSetErrorsDownload([
            ...documentsErrors,
            {
              title: 'Descarga incompleta',
              message: (
                <>
                  {' '}
                  Algo ha sucedido intenta <br /> nuevamente
                </>
              ),
              id: 'Error-service'
            }
          ])
        );
      }
      dispatch(onStopLoad());
      triggerQSI(SEND_TRIGGERS_QUALTRICS.QSI_DOWNLOAD_PDF);
    },

    [dispatch]
  );

  const getPrioRenewal = async (numPolicy, nameDocument: string) => {
    try {
      dispatch(onStartLoad());
      const body = {
        data: { numeroPoliza: numPolicy }
      };
      const {
        data: { data }
      } = await postRequest<{ data: any }>('get-pre-renovation', body, BaseTypes.policies); //*Para generar la descarga del pdf
      const { consultarPDFPolizasRs } = data;
      if (
        consultarPDFPolizasRs.infoResponse.estado.descripcion ===
        'Consulta de PDF polizas ejecutada correctamente'
      ) {
        const linkSource = `data:application/pdf;base64,${consultarPDFPolizasRs.PDFPoliza.base64pdf}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = nameDocument;
        downloadLink.click();
        dispatch(
          onSetSuccessDownload([
            {
              name: nameDocument,
              id: `prior-renewal`
            }
          ])
        );
      } else {
        dispatch(
          onSetErrorsDownload([
            {
              title: 'Descarga incompleta',
              message: (
                <>
                  Algo ha sucedido intenta <br /> nuevamente
                </>
              ),
              id: 'Error-service'
            }
          ])
        );
      }
    } catch (error) {
      console.error('Error download:', error);
      dispatch(onError(error));
      dispatch(
        onSetErrorsDownload([
          {
            title: 'Descarga incompleta',
            message: (
              <>
                Algo ha sucedido intenta <br /> nuevamente
              </>
            ),
            id: 'Error-service'
          }
        ])
      );
    }
    triggerQSI(SEND_TRIGGERS_QUALTRICS.QSI_DOWNLOAD_PRIOR_RENEW);
  };

  const getSlip = useCallback(
    async (slips: any[]) => {
      let documentsSuccess = [];
      let documentsErros = [];
      for (let index = 0; index < slips.length; index++) {
        const slip = slips[index];
        try {
          dispatch(onStartLoad());
          const body = {
            data: { contentId: slip.contentId, nameFile: slip.nameFile }
          };

          const {
            data: { data }
          } = await postRequest<{ data: any }>('get-slip', body, BaseTypes.policies, {
            withCredentials: true
          });
          const slips = data?.slip;
          const nameDownloadDocument = slip.nameFile;
          // const file = await (await fetch(slips)).blob();
          // saveAs(file, nameDownloadDocument);
          const downloadLink = document.createElement('a');
          downloadLink.href = slips;
          downloadLink.target = '_blank';
          downloadLink.download = nameDownloadDocument;
          downloadLink.click();
          documentsSuccess = [
            ...documentsSuccess,
            {
              name: nameDownloadDocument,
              id: `slip-renewal`
            }
          ];
        } catch (error) {
          console.error('Error download:', error);
          documentsErros = [
            ...documentsErros,
            {
              title: 'Descarga incompleta',
              message: (
                <>
                  Algo ha sucedido intenta <br /> nuevamente
                </>
              ),
              id: 'Error-service'
            }
          ];
        }
      }
      dispatch(onStopLoad());
      dispatch(onSetSuccessDownload([...documentsSuccess]));
      dispatch(onSetErrorsDownload([...documentsErros]));
    },
    [dispatch]
  );

  const createDocumentExcel = async ({ type, payload }: NotificationActions) => {
    try {
      dispatch(onStartLoad());
      const body = {
        type,
        payload
      };
      await postRequest('sqs', body, BaseTypes.policies);
      dispatch(onStopLoad());
    } catch (error) {
      dispatch(onStopLoad());
      console.error('Generate document ', error);
    }
  };

  const createNotificationMassively = async ({ type, payload }) => {
    try {
      dispatch(onStartLoad());
      const body = {
        type,
        payload
      };
      await postRequest('sqs', body, BaseTypes.policies);
      dispatch(onStopLoad());
    } catch (error) {
      dispatch(onStopLoad());
      console.error('Generate document ', error);
    }
  };

  const startDownloadExcel = async (id, nameDocument: string, queryParams = {}) => {
    dispatch(onStartLoad());
    try {
      const {
        data: { url }
      } = await getRequest<{ url: string }>(`notifications/${id}/download`, BaseTypes.policies, {
        params: { ...queryParams }
      });
      if (url) {
        const file = await (await fetch(url)).blob();
        saveAs(file, nameDocument);
        dispatch(
          onSetSuccessDownload([
            {
              name: nameDocument,
              id: `download-excel`
            }
          ])
        );
      } else {
        dispatch(
          onSetErrorsDownload([
            {
              title: 'Descarga incompleta',
              message: (
                <>
                  Algo ha sucedido intenta <br /> nuevamente
                </>
              ),
              id: 'Error-service'
            }
          ])
        );
      }
    } catch (error) {
      console.error();
      dispatch(onError(error));
      dispatch(
        onSetErrorsDownload([
          {
            title: 'Descarga incompleta',
            message: (
              <>
                Algo ha sucedido intenta <br /> nuevamente
              </>
            ),
            id: 'Error-service'
          }
        ])
      );
    }
    dispatch(onStopLoad());
  };

  const errorRePrintPdfModal = useMemo(() => {
    if (errorReprintPDF) {
      return (
        <>
          <LibertyModal
            open={errorReprintPDF}
            onClose={() => dispatch(onErrorReprintPDF(false))}
            image={<img src={shieldModal} alt="shieldModal" />}
            title={'Lo sentimos'}
            description={
              'No hemos podido crear el documento PDF para esta Póliza. Comunicate con tu gestor comercial'
            }
            actionButtons={
              <LibertyButton
                id="btn_ModalPDF"
                type={ButtonTypes.primary}
                style={{ height: '46px' }}
                onClick={() => dispatch(onErrorReprintPDF(false))}
              >
                Aceptar
              </LibertyButton>
            }
          />
        </>
      );
    } else {
      return null;
    }
  }, [dispatch, errorReprintPDF]);

  const rePrintPDF = useCallback(
    async (sseguro, codigoBroker, numeroMovimiento, tipoImpresion, dataReload) => {
      dispatch(onStartLoadReprint());
      try {
        const body = {
          data: {
            numeroMovimiento,
            tipoImpresion,
            sseguro,
            codigoBroker
          }
        };
        await postRequest<{ data: any }>('get-pdf', body, BaseTypes.policies);
      } catch (error) {
        console.error('Error rePrintPDF:', error);
      }
      for (let i = 0; i < 12; i++) {
        try {
          const {
            data: { data: movementVerifyPDF }
          } = await getRequest<{ data: any }>(`last-movement/${sseguro}`, BaseTypes.policies);

          if (movementVerifyPDF?.pdfs?.length > 0) {
            await dispatch(reLoadPoliceOthers({ query: { ...dataReload }, where: '2' }));
            dispatch(onStopLoadRePrint());
            break;
          } else if (i == 11 && movementVerifyPDF?.pdfs?.length == 0) {
            dispatch(onErrorReprintPDF(true));
          }
          await sleep(10000);
        } catch (error) {
          console.error(error);
        }
      }
      dispatch(onStopLoadRePrint());
    },
    [dispatch]
  );

  const startDownloadZip = async (id, nameDocument: string, queryParams = {}) => {
    dispatch(onStartLoad());
    try {
      const {
        data: { url }
      } = await getRequest<{ url: string }>(`notifications/${id}/download`, BaseTypes.policies, {
        params: { ...queryParams }
      });
      if (url) {
        const file = await (await fetch(url)).blob();
        saveAs(file, nameDocument);
        dispatch(
          onSetSuccessDownload([
            {
              name: nameDocument,
              id: `prior-renewal`
            }
          ])
        );
      } else {
        dispatch(
          onSetErrorsDownload([
            {
              title: 'Descarga incompleta',
              message: (
                <>
                  Algo ha sucedido intenta <br /> nuevamente
                </>
              ),
              id: 'Error-service'
            }
          ])
        );
      }
    } catch (error) {
      console.error();
      dispatch(onError(error));
      dispatch(
        onSetErrorsDownload([
          {
            title: 'Descarga incompleta',
            message: (
              <>
                Algo ha sucedido intenta <br /> nuevamente
              </>
            ),
            id: 'Error-service'
          }
        ])
      );
    }
    dispatch(onStopLoad());
  };

  return {
    //*Params
    bad,
    good,
    nameDocument,
    data,
    isLoadingDownload,
    isLoadingRePrint,
    title,
    description,
    errorRePrintPdfModal,

    //*Methods
    clearDocumentDownload,
    getPDF,
    getSlip,
    rePrintPDF,
    getPrioRenewal,
    createDocumentExcel,
    startDownloadExcel,
    startDownloadZip,
    createNotificationMassively
  };
};
