import { ReactNode } from 'react';
import { Branches } from 'common/branches';
import { IdTypes, IdOptions } from './idTypes';
import { StartOptions } from './startOptions';

export const renovationsStartOptions = [
  { name: 'Placa', value: StartOptions.plate },
  { name: 'Número de póliza', value: StartOptions.policeNumber },
  { name: 'Identificación', value: StartOptions.idType }
];

export const renovationsBranches = [
  { name: 'Autos', value: Branches.cars },
  { name: 'Empresariales', value: Branches.business },
  { name: 'Hogar', value: Branches.home },
  { name: 'Salud', value: Branches.health },
  { name: 'Transportes', value: Branches.transportation },
  { name: 'Vida', value: Branches.life }
];

export const renovationsIds = [
  { name: 'ID Tomador', value: IdTypes.taker },
  { name: 'ID Asegurado', value: IdTypes.insured }
];

export const renovationsIdOptions = [
  { name: 'Cédula de ciudadanía - (C.C.)', shortName: 'C.C.', value: 'C.C.' },
  { name: 'NIT', shortName: 'NIT', value: 'NIT' },
  { name: 'NIT extranjero - (NIT E.)', shortName: 'NIT E.', value: 'NIT E.' },
  { name: 'Cédula extranjería - (C.E.)', shortName: 'C.E.', value: 'C.E.' },
  { name: 'Pasaporte - (P.P.)', shortName: 'P.P.', value: 'P.P.' },
  { name: 'Tarjeta de Identidad - (T.I.)', shortName: 'T.I.', value: 'T.I.' },
  { name: 'BIC', shortName: 'BIC', value: 'BIC' },
  { name: 'Carnet Diplomático - (C.D.)', shortName: 'C.D.', value: 'C.D.' },
  { name: 'Identificador del Sistema - (I.S.)', shortName: 'I.S.', value: 'I.S.' },
  { name: 'Identificador Simulaciones - (I.SIM.)', shortName: 'I.SIM.', value: 'I.SIM.' },
  {
    name: 'Número único identificación Personal - (N.U.I.P.)',
    shortName: 'N.U.I.P.',
    value: 'N.U.I.P.'
  },
  { name: 'PECP', shortName: 'PECP', value: 'PECP' },
  { name: 'Permiso por Protección Temporal - (P.P.T.)', shortName: 'P.P.T.', value: 'P.P.T.' },
  { name: 'Permiso especial de permanencia - (P.E.P.)', shortName: 'P.E.P.', value: 'P.E.P.' },
  { name: 'Registro Civil - (R.C.)', shortName: 'R.C.', value: 'R.C.' }
];

export enum StatesRenewalCars {
  ManageRenewal = 'managerenewal',
  Renovada = 'Renovada',
  SinVerif = 'SinVerif',
  PreRenovacion = 'PreRenovacion',
  SinPreRenovacion = 'SinPreRenovacion',
  Otro = 'Otro',
  SinSarlaft = 'SinSarlaft',
  Anulada = 'Anulada',
  CANNOT_RENEWED = 'CANNOT_RENEWED',
  Renewing = 'Renewing',
  RenewalError = 'RenewalError',
  Actualizar = 'Actualizar'
}

export interface InputMultiSelectType {
  placeholder?: string;
  active?: boolean;
  maxLength?: number;
  type?: string;
}
export interface RulesTypes {
  listSelects?: any[];
  optionsSelected?: string[];
  title?: string;
  listBubbles?: string[];
  activeFilter?: boolean;
  input?: InputMultiSelectType;
}

export interface StateOption {
  name: string;
  value: any;
  shortName?: string;
  tooltip?: ReactNode;
  color?: string;
  short?: string;
}

export const optionsRenewalCars: StateOption[] = [
  {
    name: 'En proceso',
    value: StatesRenewalCars.Renewing,
    color: '#FFD000'
  },
  {
    name: 'Error de renovación',
    value: StatesRenewalCars.RenewalError,
    color: '#E92243'
  },
  {
    name: 'Gestionar renovación',
    value: StatesRenewalCars.ManageRenewal,
    color: '#28A3AF'
  },
  {
    name: 'No renovable',
    value: StatesRenewalCars.Anulada,
    color: '#D32F2F'
  },
  {
    name: 'Pendiente modificación',
    value: StatesRenewalCars.Otro,
    color: '#FFD000'
  },
  {
    name: 'Pendiente renovación',
    value: StatesRenewalCars.PreRenovacion,
    color: '#EEA600'
  },
  {
    name: 'Renovada',
    value: StatesRenewalCars.Renovada,
    color: '#03AC63'
  },
  {
    name: 'Sin previo de renovación',
    value: StatesRenewalCars.SinPreRenovacion,
    color: '#E92243'
  },
  { name: 'Sin sarlaft', value: StatesRenewalCars.SinSarlaft, color: '#343741' },
  {
    name: 'Sin verificación de identidad ',
    value: StatesRenewalCars.SinVerif,
    color: '#1A1446',
    short: 'SIN VERIFICACIÓN ID'
  }
];

export const optionsRenewalCarsMap = new Map(optionsRenewalCars.map((item) => [item.value, item]));

export enum StatesRenovation {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}
