import { createContext, useContext } from 'react';
import { NotificationUnion } from '../types';
import { TabsNotificationTypes } from '../constants';
import { useNotifications as useNotificationsBase } from 'hooks/useNotifications';
interface INotificationsType {
  notifications: NotificationUnion[];
  notificationsCount: number;
  open: boolean;
  setOpen: (value: boolean) => void;
  deleteNotification: (id: string) => Promise<void>;
  totalDownload: number;
  totalRenovations: number;
  tabSelected: TabsNotificationTypes;
  setTabSelected: any;
  isLoading: boolean;
  readNotificationsExcel: any;
  readNotificationsMassively: any;
}

const NotificationsContext = createContext<INotificationsType>(Object.create(null));

export const NotificationsProvider = ({ children }) => {
  const hook = useNotificationsBase();
  return <NotificationsContext.Provider value={hook}>{children}</NotificationsContext.Provider>;
};

export const useNotifications = () => useContext(NotificationsContext);
