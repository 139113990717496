import ModalBase from '@material-ui/core/Modal';
import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './ModalBase.module.scss';

export enum Sizes {
  lg = 'lg',
  md = 'md',
  max = 'max'
}

const sizes = {
  [Sizes.lg]: styles.lg,
  [Sizes.max]: styles.max
};

export interface ModalBaseProps {
  open: boolean;
  onClose: () => void;
  containerClassName?: string;
  size?: Sizes;
  children: ReactNode;
}

export const LibertyModalBase = ({
  open,
  containerClassName = null,
  children,
  onClose = null,
  size
}: ModalBaseProps) => {
  return (
    <ModalBase
      open={open}
      onClose={onClose}
      className={styles.root}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={clsx(styles.container, containerClassName, sizes[size])}>{children}</div>
    </ModalBase>
  );
};

export default LibertyModalBase;
