import { InputHTMLAttributes } from 'react';

export enum InputType {
  text = 'text',
  number = 'number'
}

export enum LocationType {
  top = 'top',
  bottom = 'bottom'
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  capitalLetterOn?:boolean;
  error_message?: string;
  errors?: boolean;
  separation_error_message?: string;
  location_error_message?: LocationType;
  search_icon?: boolean;
  reset?: boolean;
  warning?: boolean;
  placeholder?: string;
  disabled?: boolean;
  inactive?: boolean;
  maxLength?: number;
  minLength?: number;
  fontSize?: number;
  width?: string;
  height?: string;
  type?: InputType;
  border?: boolean;
  inputClassName?: string;
}
