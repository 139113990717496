//*react
import { useEffect, useState, useRef, useMemo } from 'react';
//*THIRDS
import clsx from 'clsx';
//*TYPES
import { LibertySelectCustomProps, Option } from './types';
//*icons
import arrowIcon from 'assets/icons/arrowDown.svg';
import arrowDownDark from 'assets/icons/arrowDownDark.svg';
import alertErrorIcon from 'assets/icons/error.svg';
//*styles
import './LibertySelectCustom.scss';

export const LibertySelectCustom = <O extends Option>({
  type = 'primary',
  className,
  id,
  tooltip,
  select = '',
  darkArrow = false,
  handleChange,
  title = 'Test',
  list = [],
  reset = false,
  warning = false,
  errorMessage,
  onClose,
  disable = false,
  isLoading = false
}: LibertySelectCustomProps<O>) => {
  const selectRef = useRef();
  const onCloseRef = useRef(onClose);

  const [hidden, setHidden] = useState(true);
  const hiddenRef = useRef(hidden);
  const [optionSelect, setOptionSelect] = useState<O['name']>('');

  const listBase = useMemo(() => list || [], [list]);

  const change = (option, name) => {
    setHidden(true);
    setOptionSelect(name);
    handleChange?.(option);
  };

  const onSelect = (option) => {
    if (option.action) {
      return option.action();
    } else if (!option.disable) {
      change(option.value, option.name);
      option.onSelect?.();
    }
  };

  const spaceIcon = useMemo(() => list.some((element) => element.icon), [list]);
  const spaceColorCircle = useMemo(() => list.some((element) => element.color), [list]);

  useEffect(() => {
    if (select === '') {
      setOptionSelect(title);
    } else {
      const element =
        listBase.find((element) => {
          if (element.value instanceof Date) {
            return (
              +element.value.setHours(0, 0, 0, 0) ==
              +new Date(select.toString()).setHours(0, 0, 0, 0)
            );
          }
          return element.value.toString().toUpperCase() == select.toString().toUpperCase();
        })?.name || select;
      setOptionSelect(element);
    }
  }, [select, listBase, title]);

  useEffect(() => {
    if (hidden !== hiddenRef.current && hidden) onCloseRef.current?.();
    return () => {
      hiddenRef.current = hidden;
    };
  }, [hidden]);

  useEffect(() => {
    if (reset) {
      handleChange?.('');
      setOptionSelect(title);
    }
  }, [reset]);

  useEffect(() => {
    const closeSelect = (e) => {
      if (!e.composedPath().includes(selectRef.current)) {
        setHidden(true);
      }
    };
    document.body.addEventListener('click', closeSelect);
    return () => {
      document.body.removeEventListener('click', closeSelect);
    };
  }, []);

  return (
    <div className={clsx(className, type, disable && 'disable')}>
      {errorMessage && (
        <div className={warning ? 'msg_warning_on' : 'msg_warning_off'}>
          <img src={alertErrorIcon} alt="alertErrorIcon" />
          <span>{errorMessage}</span>
        </div>
      )}
      <div
        id={id}
        ref={selectRef}
        className={`select ${warning && 'warning'} ${!hidden && 'focus'}`}
        onClick={() => {
          const newOpen = !hidden;
          !disable && !isLoading && setHidden(newOpen);
        }}
      >
        {isLoading ? (
          <div className="container_spinner">
            <div className={`spinner_loader`} />
          </div>
        ) : (
          <>
            <span className={select !== '' ? 'info_span_on' : 'info_span_off'}>{title}</span>
            {list.find(
              (option) => option.value.toString().toLowerCase() == select.toString().toLowerCase()
            )?.color && (
              <div
                style={{
                  height: '12px',
                  width: '12px',
                  marginInline: '8px auto',
                  marginBlock: 'auto 12px',
                  borderRadius: '100%',
                  backgroundColor: list.find(
                    (option) =>
                      option.value.toString().toLowerCase() == select.toString().toLowerCase()
                  ).color
                }}
              />
            )}
            <span
              className={`selected_option ${select && 'with_value'}`}
              style={{
                marginTop: select && '10px',
                color: select ? '#343741' : '#707070'
              }}
            >
              {optionSelect || title}
            </span>

            {!disable && darkArrow ? (
              <div className="container_arrow_black">
                <img className="arrow_dark" src={arrowDownDark} alt="arrowDownDark" />
              </div>
            ) : (
              !disable && (
                <div className="container_arrow">
                  <img className="arrow" src={arrowIcon} alt="arrowIcon" />
                </div>
              )
            )}

            {tooltip !== '' && <div className="tooltip_container">{tooltip}</div>}
          </>
        )}
      </div>

      <div
        className="container_options_tooltips"
        style={{
          height: `calc(${listBase.length * 45}px + 87px)`,
          display: hidden ? 'none' : 'flex'
        }}
      >
        <div className="options">
          {listBase.map((option, i) => (
            <div
              className={`${option.disable && 'disable'} option`}
              key={i}
              onClick={() => {
                onSelect(option);
              }}
            >
              <div className="name_icon">
                {spaceIcon && (
                  <div className="icon">
                    {option.icon && <img src={option.icon} alt={option.icon} />}
                  </div>
                )}
                {spaceColorCircle && (
                  <div
                    style={{
                      height: '12px',
                      width: '12px',
                      marginRight: '8px',
                      borderRadius: '100%',
                      backgroundColor: option.color
                    }}
                  />
                )}
                {option.style ? (
                  <span style={{ ...option.style }}>{option.name}</span>
                ) : (
                  option.name
                )}
              </div>
              {option.tooltip && (
                <div className={`tooltip`}>
                  <div className="info_tooltip">
                    <span className="value">{option.tooltip}</span>
                  </div>
                  <div className="triangulo_tooltip"></div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
