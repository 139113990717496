import React, { useMemo } from 'react';
import { Route } from './types';
import { useQueriesRoutes } from './routes/queries';
import { useRenovationsRoutes } from './routes/renovations';
import { useSinistersRoutes } from './routes/sinisters';
import { useSupplementsAnnulmentsRoutes } from './routes/supplementsAnnulments';

const RouterContext = React.createContext<{
  routes: Route[];
}>(Object.create(null));
export const useRoutes = () => React.useContext(RouterContext);

export const RouterProvider = ({ children }) => {
  const queriesRoutes = useQueriesRoutes();
  const renovationsRoutes = useRenovationsRoutes();
  const sinisters = useSinistersRoutes();
  const supplementsAnnulments = useSupplementsAnnulmentsRoutes();

  const routes = useMemo<Route[]>(
    () => [...queriesRoutes, ...renovationsRoutes, ...sinisters, ...supplementsAnnulments],
    [queriesRoutes, renovationsRoutes, sinisters, supplementsAnnulments]
  );

  return <RouterContext.Provider value={{ routes }}>{children}</RouterContext.Provider>;
};

export default RouterContext;
