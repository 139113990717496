import { createAsyncThunk } from '@reduxjs/toolkit';
import { IResponseData } from 'hooks/usePoliceAutosIndividualStore/usePoliceAutosIndividualStore';
import { ICoveragesAndDeductibles } from 'models/modifications';
import { postRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';
import { LoadSingleCarPage, getPersons, getWarranties } from './services';

export const fetchLoadIndividualCarDetail = createAsyncThunk(
  'singleCars/fetchLoadIndividualCarDetail',
  async ({ query }: { query: any }) => LoadSingleCarPage(query)
);

export const reLoadPolicyAI = createAsyncThunk(
  'singleCars/reLoadPolicyAI',
  async ({ query }: { query: any }) => LoadSingleCarPage(query)
);

export const fetchLoadClaims = createAsyncThunk(
  'singleCars/fetchLoadClaims',
  async ({ query }: { query: any }) => {
    try {
      const body = {
        data: {
          ...query
        }
      };
      const {
        data: { data }
      } = await postRequest<IResponseData>('claims', body, BaseTypes.policies);
      return { sinisters: [...data], errorSinisters: null };
    } catch (error) {
      console.error('Error:', error);
      return {
        sinisters: [],
        errorSinisters: error
      };
    }
  }
);

export const fetchLoadCoverages = createAsyncThunk(
  'singleCars/fetchLoadCoverages',
  async ({ query }: { query: any }) => {
    try {
      const body = {
        data: {
          ...query
        }
      };
      const {
        data: { data }
      } = await postRequest<IResponseData>('warranties', body, BaseTypes.policies);
      return { ...data, errorCoverage: null };
    } catch (error) {
      console.error('Error:', error);
      return {
        totalRegisters: 0,
        warranties: [],
        errorCoverage: error
      };
    }
  }
);

export const fetchLoadRisks = createAsyncThunk(
  'singleCars/fetchLoadRisks',
  async ({ query }: { query: any }) => {
    try {
      const body = {
        data: {
          ...query
        }
      };
      const {
        data: { data }
      } = await postRequest<IResponseData>('risks', body, BaseTypes.policies);
      return { ...data, errorRisk: null };
    } catch (error) {
      console.error('Error:', error);
      return {
        totalRegisters: 0,
        warranties: [],
        errorRisk: error
      };
    }
  }
);

export const fetchLoadPersons = createAsyncThunk(
  'singleCars/fetchLoadPersons',
  async ({ query }: { query: any }) => {
    return await getPersons(query);
  }
);

export const fetchLoadMovements = createAsyncThunk(
  'singleCars/fetchLoadMovements',
  async ({ query }: { query: any }) => {
    try {
      const body = {
        data: {
          ...query
        }
      };
      const {
        data: { data }
      } = await postRequest<IResponseData>('movements', body, BaseTypes.policies);
      return {
        movements: data.movements,
        notes: data.notes,
        errorMovements: null
      };
    } catch (error) {
      console.error('Error:', error);
      return {
        movements: [],
        notes: [],
        errorMovements: error
      };
    }
  }
);

export const fetchLoadCoverageAndDeductibles = createAsyncThunk(
  'singleCars/fetchLoadCoverageAndDeductibles',
  async ({ query }: { query: any }) => {
    try {
      const bodyWarrantiesAndDeductibles = {
        data: {
          cuse: query.cuse,
          value: query.value,
          sseguro: query.sseguro,
          risk: query.risk
        }
      };
      const {
        data: { data }
      } = await postRequest<{ data: ICoveragesAndDeductibles }>(
        'getCoverageAndDeductibles',
        bodyWarrantiesAndDeductibles,
        BaseTypes.endorsement
      );

      const bodyDetailDeductibles = {
        data: {
          sseguro: query.sseguro,
          product_code: query.codigoProducto || query.product_code.toString()
        }
      };

      const dataDetailDeductibles = await getWarranties({ ...bodyDetailDeductibles });

      return {
        amparos: data.coverages,
        deductibles: data.deductibles,
        detailsDeductibles: dataDetailDeductibles.coverages,
        error: null
      };
    } catch (error) {
      console.error(error);
      return { amparos: null, deductibles: null, detailsDeductibles: null, error };
    }
  }
);
