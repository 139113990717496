import { useCallback, useEffect, useMemo, useState } from 'react';
import { TabsNotificationTypes, Types } from 'components/collections/Notifications/constants';
import { useQuery } from 'react-query';
import { deleteRequest, postRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';
import parseISO from 'date-fns/parseISO';
import { NotificationUnion } from 'components/collections/Notifications/types';
import { useBackGroundProcess } from './useBackGroundProcess';
import {
  getMassivelyRenovationPending,
  setMassivelyRenovationPending
} from 'utils/modifyMassivelyRenovationsPending';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { getDownloadPdfsPending, setDownloadPdfsPending } from 'utils/modifyDonwloadPdfsPeding';

export const useNotifications = () => {
  const [open, setOpenBase] = useState(false);
  const { getBackGroundProcess } = useBackGroundProcess();
  const [notificationsCount, setNotificationsCount] = useState<number | null>(null);
  const pendingRenovations = useSelector((state: RootState) => state.processRenovation.pending);
  const pendingPDF = useSelector((state: RootState) => state.processDownloadPDF.pending);

  const { data, isLoading, refetch } = useQuery(['NOTIFICATION_DOWNLOAD'], getBackGroundProcess, {
    // enabled: NotificationDelete,
    refetchInterval: 60000
  });

  const [tabSelected, setTabSelected] = useState<TabsNotificationTypes>(
    TabsNotificationTypes.DOWNLOAD_EXCEL
  );

  const [idToDelete, setIdToDelete] = useState('');

  const notifications = useMemo<NotificationUnion[]>(() => {
    const validationTabs = tabSelected == TabsNotificationTypes.DOWNLOAD_EXCEL;
    const notificationList = validationTabs
      ? data?.downloadExcelNotification
      : data?.massiveRenewalNotification;
    if (notificationList?.length == 0) return [];
    return (
      notificationList
        ?.filter((not) => not?.id.toString() != idToDelete && !not?.content?.errors)
        ?.map(
          (notification): NotificationUnion => ({
            type: validationTabs ? Types.NOTIFICATION : Types.RENOVATIONS,
            createdAt: parseISO(notification.createDate),
            data: notification,
            read: !!notification.readAt
          })
        )
        .slice()
        .sort((a, b) => +b.createdAt - +a.createdAt) || []
    );
  }, [data, idToDelete, tabSelected]);

  const { totalDownload, totalRenovations } = useMemo(
    () => ({
      totalDownload: data?.downloadExcelNotification?.filter((not) => !not.readAt).length || 0,
      totalRenovations:
        data?.massiveRenewalNotification?.filter((not) => !not.readAt && !not?.content?.errors)
          .length || 0
    }),
    [data?.downloadExcelNotification, data?.massiveRenewalNotification]
  );

  const readNotificationsExcel = useCallback(async () => {
    const notifications =
      data?.downloadExcelNotification?.filter((notification) => !notification.readAt) || [];
    if (notifications.length == 0) return;
    try {
      await Promise.all(
        notifications.map(async (notification) => {
          await postRequest(`notifications/${notification.id}/read`, {}, BaseTypes.policies);
        })
      );
    } catch (error) {
      console.error(error);
    }
    refetch();
  }, [data?.downloadExcelNotification, refetch]);

  const readNotificationsMassively = useCallback(async () => {
    const notifications =
      data?.massiveRenewalNotification?.filter((notification) => !notification.readAt) || [];
    if (notifications.length == 0) return;
    try {
      await Promise.all(
        notifications.map(async (notification) => {
          await postRequest(`notifications/${notification.id}/read`, {}, BaseTypes.policies);
        })
      );
    } catch (error) {
      console.error(error);
    }

    refetch();
  }, [data?.massiveRenewalNotification, refetch]);

  const deleteNotification = async (id: string) => {
    setIdToDelete(id);
    try {
      await deleteRequest(`notifications/${id}`, {}, BaseTypes.policies);
    } catch (error) {
      console.error(error);
    }
    try {
      await refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const setOpen = (value: boolean) => {
    setOpenBase(value);
    if (value) {
      setNotificationsCount(totalDownload + totalRenovations);
    }
  };

  useEffect(() => {
    if (isLoading) return;
    setNotificationsCount(totalDownload + totalRenovations);
  }, [totalDownload, totalRenovations, isLoading]);

  //*THIS useEffect IS THE THAT WE USE TO HAVE UPDATE THE LIST OF EVERY USER
  useEffect(() => {
    const MRP = getMassivelyRenovationPending();
    let policiesMissing = [];
    pendingRenovations.forEach((policyPending) => {
      if (!MRP.includes(policyPending)) policiesMissing = [...policiesMissing, policyPending];
    });
    if (policiesMissing.length == 0) return;
    const newArrayPending = new Set([...MRP, ...policiesMissing]);
    setMassivelyRenovationPending(Array.from(newArrayPending.values()));
  }, [pendingRenovations]);

  useEffect(() => {
    const DPP = getDownloadPdfsPending();
    let policiesDownload = [];
    pendingPDF.forEach((policyNumber) => {
      if (!DPP.includes(+policyNumber)) policiesDownload = [...policiesDownload, policyNumber];
    });
    if (policiesDownload.length == 0) return;
    const newDownloadsPDF = new Set([...DPP, ...policiesDownload]);
    setDownloadPdfsPending(Array.from(newDownloadsPDF.values()));
  }, [pendingPDF]);

  return {
    isLoading,
    notifications,
    notificationsCount,
    open,
    setOpen,
    readNotificationsExcel,
    readNotificationsMassively,
    deleteNotification,
    totalDownload,
    totalRenovations,
    setTabSelected,
    tabSelected
  };
};

export default useNotifications;
