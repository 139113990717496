import { useCallback, useEffect, useRef, useState } from 'react';
import minutesToMilliseconds from 'date-fns/minutesToMilliseconds';

import { SessionFinally } from 'components/SessionFinally';
import { Timeout } from 'components/Timeout';
import { isDev } from 'utils';

const TIMEOUT = minutesToMilliseconds(2);
const INACTIVITY = minutesToMilliseconds(8);

export function Inactivity({ logout }) {
  const [showTimeout, setShowTimeout] = useState(false);
  const [showFinally, setShowFinally] = useState(false);
  const timeout = useRef<number>(null);
  const [milliseconds, setMilliseconds] = useState(TIMEOUT);

  const resetTimeout = useCallback(() => {
    if (timeout.current) clearTimeout(timeout.current);
    if (isDev() || showFinally) return;
    timeout.current = window.setTimeout(() => {
      setShowTimeout(true);
      setMilliseconds(TIMEOUT);
    }, INACTIVITY);
  }, [showFinally]);

  useEffect(() => {
    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    events.forEach((event) => window.addEventListener(event, resetTimeout));
    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimeout));
    };
  }, [resetTimeout]);

  return (
    <>
      <Timeout
        open={showTimeout}
        counter={milliseconds}
        onChange={(newCount) => {
          setMilliseconds(newCount);
        }}
        onClose={() => {
          setShowTimeout(false);
        }}
        onTimeout={() => {
          setShowTimeout(false);
          setShowFinally(true);
        }}
        onCloseSession={() => {
          logout();
        }}
      />
      <SessionFinally
        open={showFinally}
        onLogout={() => {
          logout();
        }}
      />
    </>
  );
}
