import { LibertyCompleteDownload } from 'components/basics/AlertsDownload/LibertyCompleteDownload/LibertyCompleteDownload';
import LibertyFooter from 'components/basics/LibertyFooter/LibertyFooter';
import LibertyHeader from 'components/basics/LibertyHeader/LibertyHeader';
import { LibertyIncompleteDownload } from 'components/basics/AlertsDownload/LibertyIncompleteDownload/LibertyIncompleteDownload';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import LibertyLoader from 'components/basics/LibertyLoader/LibertyLoader';
import { useBasePath } from 'common/routes/useBasePath';
import useReset from 'hooks/useReset/useReset';
import { CERTIFICATE_DETAIL, DETAIL_ROUTES_BASE, RoutesBase } from 'common/routes/baseRoutes';
import { LibertyAlertDownloadResponsive } from 'components/basics/AlertsDownload/LibertyAlertDownloadResponsive/LibertyAlertDownloadResponsive';
import { LibertyIncompleteDownloadResponsive } from 'components/basics/AlertsDownload/LibertyIncompleteDownloadResponsive/LibertyIncompleteDownloadResponsive';
import styles from './LibertyPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  onSetErrorsDownload,
  onSetSuccessDownload
} from 'store/downloadAlerts/downloadAlertsSlices';

const { SINGLE_CARS_DETAIL, ANNULMENT_POLICY, TRACKING_POLICY, OTHER_BRANCHES_DETAIL } =
  DETAIL_ROUTES_BASE;

const LibertyPage = ({ user, children }) => {
  const dispatch = useDispatch();
  const layoutRef = useRef();
  const { actionPage } = useBasePath();
  const {
    resetTabsPolicy,
    resetSingleCarsPolicy,
    resetOtherBranchPolicies,
    resetCertificateDetail,
    resetSinisterSearch
  } = useReset();

  const {
    success,
    errors,
    isLoading: isLoadingDownload
  } = useSelector((state) => state.alertDownload);

  const showAlertsDownload = useMemo(
    () => success.length > 0 || errors.length > 0,
    [errors.length, success.length]
  );

  const { pathname } = useLocation();

  const deleteAlertSuccess = (id) => {
    dispatch(onSetSuccessDownload(success.filter((successDownload) => successDownload.id != id)));
  };

  const deleteAlertError = (id) => {
    dispatch(onSetErrorsDownload(errors.filter((errorDownload) => errorDownload.id != id)));
  };

  useEffect(() => {
    window.scroll(0, 0);
    if (!pathname.includes(OTHER_BRANCHES_DETAIL)) resetOtherBranchPolicies();
    if (!pathname.includes(CERTIFICATE_DETAIL)) resetCertificateDetail();
    if (!pathname.includes(RoutesBase.SINISTERS)) resetSinisterSearch();
    if (![OTHER_BRANCHES_DETAIL, CERTIFICATE_DETAIL].some((route) => pathname.includes(route)))
      resetTabsPolicy();
    if (
      ![SINGLE_CARS_DETAIL, ANNULMENT_POLICY, TRACKING_POLICY].some((route) =>
        pathname.includes(route)
      )
    ) {
      resetSingleCarsPolicy();
    }

    if (pathname.includes(RoutesBase.SINISTERS)) {
      document.title = 'Gestión pólizas y siniestros';
    } else {
      document.title = 'Gestión pólizas';
    }
  }, [pathname]);

  return (
    <div
      ref={layoutRef}
      style={{ position: 'relative', height: 'max-content', overflowX: 'hidden' }}
    >
      <LibertyHeader user={user} />
      <div className={styles.container_main}>
        {actionPage}
        {children}
      </div>
      {isLoadingDownload && <LibertyLoader />}
      <LibertyAlertDownloadResponsive />
      <LibertyIncompleteDownloadResponsive />
      {showAlertsDownload && (
        <div className={styles.container_cards_alerts}>
          {success.map(({ name, id }) => (
            <LibertyCompleteDownload nameDocument={name} id={id} closeAlert={deleteAlertSuccess} />
          ))}
          {errors.map(({ title, message, id }) => (
            <LibertyIncompleteDownload
              title={title}
              message={message}
              id={id}
              closeAlert={deleteAlertError}
            />
          ))}
        </div>
      )}
      <LibertyFooter />
    </div>
  );
};

export default LibertyPage;
