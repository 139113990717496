import {
  CERTIFICATE_DETAIL,
  DETAIL_ROUTES_BASE,
  INFORMATION_POLICIES,
  INFO_DETAIL_ROUTES,
  RoutesBase
} from './baseRoutes';

const SINISTERS_INFO = `${RoutesBase.SINISTERS}/${INFORMATION_POLICIES}`;
const SINISTERS_INFO_DETAIL = `${SINISTERS_INFO}/${DETAIL_ROUTES_BASE.SINGLE_CARS_DETAIL}`;
const SINISTERS_TRACKING = `${SINISTERS_INFO}/${DETAIL_ROUTES_BASE.TRACKING_POLICY}`;
const SINISTERS_ANNULMENT_POLICY = `${SINISTERS_INFO}/${DETAIL_ROUTES_BASE.ANNULMENT_POLICY}`;
const SINISTERS_INFO_POLICIES = `${SINISTERS_INFO}/${DETAIL_ROUTES_BASE.OTHER_BRANCHES_DETAIL}`;
const SINISTERS_INFO_POLICIES_CERTIFICATE = `${SINISTERS_INFO_POLICIES}/${CERTIFICATE_DETAIL}`;
const SINISTERS_ANNULMENT_POLICY_OTHER_BRANCHES = `${SINISTERS_INFO}/${DETAIL_ROUTES_BASE.ANNULMENT_POLICY_OTHER_BRANCHES}`;

export const SinistersRoutes = {
  SINISTERS_INFO,
  SINISTERS_INFO_DETAIL,
  SINISTERS_TRACKING,
  SINISTERS_ANNULMENT_POLICY,
  SINISTERS_ANNULMENT_POLICY_OTHER_BRANCHES,
  SINISTERS_INFO_DETAIL_ROUTES: INFO_DETAIL_ROUTES,
  SINISTERS_INFO_DETAIL_CERTIFICATE: `${SINISTERS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.CERTIFICATE}`,
  SINISTERS_INFO_DETAIL_MOVEMENTS: `${SINISTERS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.MOVEMENTS}`,
  SINISTERS_INFO_DETAIL_PERSONS: `${SINISTERS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.PERSONS}`,
  SINISTERS_INFO_DETAIL_RISKS: `${SINISTERS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.RISKS}`,
  SINISTERS_INFO_DETAIL_COVERAGES: `${SINISTERS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.COVERAGES}`,
  SINISTERS_INFO_DETAIL_CLAIMS: `${SINISTERS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.CLAIMS}`,
  SINISTERS_INFO_POLICIES_MOD_TAKER: `${SINISTERS_INFO_POLICIES}/${INFO_DETAIL_ROUTES.MOD_TAKER}`,
  SINISTERS_INFO_DETAIL_MOD_TAKER: `${SINISTERS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.MOD_TAKER}`,
  SINISTERS_INFO_DETAIL_MOD_INSURED: `${SINISTERS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.MOD_INSURED}`,
  SINISTERS_INFO_DETAIL_PLATE: `${SINISTERS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.PLATE}`,
  SINISTERS_INFO_DETAIL_ACCESSORY: `${SINISTERS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.ACCESSORY}`,
  SINISTERS_INFO_DETAIL_AMPAROS: `${SINISTERS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.AMPAROS}`,
  SINISTERS_INFO_DETAIL_RISKS_DATA: `${SINISTERS_INFO_DETAIL}/${INFO_DETAIL_ROUTES.RISKS_DATA}`,
  SINISTERS_INFO_POLICIES,
  SINISTERS_INFO_POLICIES_MOVIMIENTOS: `${SINISTERS_INFO_POLICIES}/${INFO_DETAIL_ROUTES.MOVEMENTS}`,
  SINISTERS_INFO_POLICIES_PERSONS: `${SINISTERS_INFO_POLICIES}/${INFO_DETAIL_ROUTES.PERSONS}`,
  SINISTERS_INFO_POLICIES_RISKS: `${SINISTERS_INFO_POLICIES}/${INFO_DETAIL_ROUTES.RISKS}`,
  SINISTERS_INFO_POLICIES_COVERAGES: `${SINISTERS_INFO_POLICIES}/${INFO_DETAIL_ROUTES.COVERAGES}`,
  SINISTERS_INFO_POLICIES_CLAIMS: `${SINISTERS_INFO_POLICIES}/${INFO_DETAIL_ROUTES.CLAIMS}`,
  SINISTERS_INFO_POLICIES_CERTIFICATE,
  SINISTERS_INFO_POLICIES_CERTIFICATE_MOVIMIENTOS: `${SINISTERS_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.MOVEMENTS}`,
  SINISTERS_INFO_POLICIES_CERTIFICATE_PERSONS: `${SINISTERS_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.PERSONS}`,
  SINISTERS_INFO_POLICIES_CERTIFICATE_RISKS: `${SINISTERS_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.RISKS}`,
  SINISTERS_INFO_POLICIES_CERTIFICATE_COVERAGES: `${SINISTERS_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.COVERAGES}`,
  SINISTERS_INFO_POLICIES_CERTIFICATE_CLAIMS: `${SINISTERS_INFO_POLICIES_CERTIFICATE}/${INFO_DETAIL_ROUTES.CLAIMS}`
};
