export const RoutesBase = {
  QUERIES: '/consultas',
  RENOVATIONS: '/renovaciones',
  SINISTERS: '/siniestros',
  SUPPLEMENTS_ANNULMENTS: '/gestion-suplementos-anulaciones',
  MAIN: '/'
};

export const INFORMATION_POLICIES = 'information';

export const INFO_DETAIL_ROUTES = {
  MOVEMENTS: 'movimientos',
  CERTIFICATE: 'certificado',
  PERSONS: 'personas',
  RISKS: 'riesgos',
  COVERAGES: 'coberturas',
  CLAIMS: 'sinisters_tab',
  MOD_TAKER: 'modtomador',
  MOD_INSURED: 'modasegurado',
  PLATE: 'placa',
  ACCESSORY: 'accesorio',
  RISKS_DATA: 'datosriesgo',
  AMPAROS: 'amparos'
};

export const DETAIL_ROUTES_BASE = {
  SINGLE_CARS_DETAIL: 'detalleinfopolautosindividual',
  OTHER_BRANCHES_DETAIL: 'detallesinformacionpolizas',
  ANNULMENT_POLICY: 'annulment',
  ANNULMENT_POLICY_OTHER_BRANCHES: 'otherbranchesannulments',
  TRACKING_POLICY: 'tracking'
};

export const CERTIFICATE_DETAIL = 'certificado';

export const DETAIL_ROUTES = [
  'movimientos',
  'personas',
  'riesgos',
  'coberturas',
  'sinisters_tab',
  'modtomador',
  'modasegurado',
  'placa',
  'accesorio',
  'datosriesgo',
  'amparos'
];
