export const SESSION_STORAGE_VARIABLES = {
  QUERY_SINISTERS: 'QUERY_SINISTERS',
  QUERY_CONSULTATION: 'QUERY_CONSULTATION',
  QUERY_RENOVATION: 'QUERY_RENOVATION',
  CLAIM_SELECTED: 'CLAIM_SELECTED',
  POLICY_SINGLE_CAR: 'POLICY_SINGLE_CAR',
  POLICY_OTHER_BRANCH: 'POLICY_OTHER_BRANCH',
  CERTIFICATE: 'CERTIFICATE'
};

export const LOCAL_STORAGE_VARIABLES = {
  PERSONS_UPDATING_AI: 'PERSONS_UPDATING_AI',
  MASSIVELY_RENOVATION_PENDING: 'MASSIVELY_RENOVATION_PENDING',
  DOWNLOAD_PDFS_PENDING: 'DOWNLOAD_PDFS_PENDING'
};
