import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import styles from './LibertyCustomTooltip.module.scss';
import { createPortal } from 'react-dom';

export enum PositionsTooltipTypes {
  top = 'top',
  bottom = 'bottom',
  right = 'right',
  left = 'left'
}

interface IPropsTooltip {
  tooltipInfo: ReactNode;
  position?: PositionsTooltipTypes;
  id: string;
  open?: boolean;
  boxRight?: number;
}

const stylesType = {
  top: styles.top,
  bottom: styles.bottom,
  right: styles.right,
  left: styles.left
};

const stylesTriangle = {
  top: styles.triangle_down,
  bottom: styles.triangle_up,
  right: styles.triangle_left,
  left: styles.triangle_right
};

export const LibertyCustomTooltip = ({
  tooltipInfo,
  position = PositionsTooltipTypes.top,
  id = '',
  open = false,
  boxRight = 0
}: IPropsTooltip) => {
  const refTooltip = useRef(null);
  const arrowRef = useRef(null);
  const portalNode = document.createElement('div');

  const styleTriangle = useMemo(() => stylesTriangle[position] || styles.triangle_down, [position]);

  const createPositionTooltip = useCallback(
    (positionElement, elementHeight, elementWidth, tooltipHeight, tooltipWidth) => {
      const positionTooltipToTarget = {
        top: {
          boxTop: positionElement.y - tooltipHeight + window.pageYOffset,
          boxLeft: positionElement.x - boxRight + (elementWidth - tooltipWidth) / 2,
          marginArrow: `0 auto 0 ${
            boxRight == 0 ? 'auto' : tooltipWidth / 2 + boxRight - 12 + 'px'
          }`
        },
        bottom: {
          boxTop: positionElement.y + elementHeight + window.pageYOffset,
          boxLeft: positionElement.x + (elementWidth - tooltipWidth) / 2,
          marginArrow: 'auto'
        },
        left: {
          boxTop: positionElement.y + window.pageYOffset + (elementHeight - tooltipHeight) / 2,
          boxLeft: positionElement.x - tooltipWidth,
          marginArrow: 'auto'
        },
        right: {
          boxTop: positionElement.y + window.pageYOffset + (elementHeight - tooltipHeight) / 2,
          boxLeft: positionElement.x + elementWidth,
          marginArrow: 'auto'
        }
      };

      return (
        positionTooltipToTarget[position] || positionTooltipToTarget[PositionsTooltipTypes.top]
      );
    },
    [boxRight, position]
  );

  const isHover = useCallback(
    (elementTarget) => {
      if (refTooltip.current && arrowRef.current && elementTarget) {
        refTooltip.current.style.display = 'flex';
        const positionElement = elementTarget.getBoundingClientRect?.();
        const { boxTop, boxLeft, marginArrow } = createPositionTooltip(
          positionElement,
          elementTarget.clientHeight,
          elementTarget.clientWidth,
          refTooltip.current.clientHeight,
          refTooltip.current.clientWidth
        );
        refTooltip.current.style.top = boxTop + 'px';
        refTooltip.current.style.left = boxLeft + 'px';
        arrowRef.current.style.margin = marginArrow;
      }
    },
    [createPositionTooltip]
  );

  const isNotHover = useCallback((elementTarget) => {
    if (refTooltip.current && elementTarget) {
      refTooltip.current.style.display = 'none';
    }
  }, []);

  useEffect(() => {
    const elementTarget = document.getElementById(id);
    document.body.appendChild(portalNode);
    elementTarget?.addEventListener('mouseover', () => isHover(elementTarget));
    elementTarget?.addEventListener('mouseout', () => isNotHover(elementTarget));
    if (open) isHover(elementTarget);
    else isNotHover(elementTarget);
    return () => {
      portalNode.remove();
      elementTarget?.removeEventListener('mouseover', () => isHover(elementTarget));
      elementTarget?.removeEventListener('mouseout', () => isNotHover(elementTarget));
    };
  }, [position, portalNode, isHover, isNotHover, id, open]);

  if (!tooltipInfo) return null;

  return createPortal(
    <div ref={refTooltip} className={`${styles.tooltip} ${stylesType[position] || styles.top}`}>
      <div className={styles.info_tooltip}>{tooltipInfo}</div>
      <div className={styleTriangle} ref={arrowRef} />
    </div>,
    portalNode
  );
};
