import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { PathRoutes } from '.';
import { ActionsPageQueries } from 'components/ActionsPages/queries/ActionsPageQueries';
import { ActionsPageRenovations } from 'components/ActionsPages/renovations/ActionsPageRenovations';
import { ActionsPageSinisters } from 'components/ActionsPages/sinisters/ActionPageSinisters';
import { ActionsPageSupplementsAnnulments } from 'components/ActionsPages/supplementsAnnulments/ActionPageSupplementsAnnulments';

const actionsPage = {
  [PathRoutes.QUERIES]: <ActionsPageQueries />,
  [PathRoutes.RENOVATIONS]: <ActionsPageRenovations />,
  [PathRoutes.SINISTERS]: <ActionsPageSinisters />,
  [PathRoutes.SUPPLEMENTS_ANNULMENTS]: <ActionsPageSupplementsAnnulments />
};

export const goTracking = {
  [PathRoutes.QUERIES]: PathRoutes.QUERIES_TRACKING,
  [PathRoutes.RENOVATIONS]: PathRoutes.RENOVATIONS_TRACKING,
  [PathRoutes.SINISTERS]: PathRoutes.SINISTERS_TRACKING,
  [PathRoutes.SUPPLEMENTS_ANNULMENTS]: PathRoutes.SUPPLE_ANNUL_TRACKING
};

export const goSingleCar = {
  [PathRoutes.QUERIES]: PathRoutes.QUERIES_INFO_DETAIL_MOVEMENTS,
  [PathRoutes.RENOVATIONS]: PathRoutes.RENOVATIONS_INFO_DETAIL_MOVEMENTS,
  [PathRoutes.SINISTERS]: PathRoutes.SINISTERS_INFO_DETAIL_CLAIMS,
  [PathRoutes.SUPPLEMENTS_ANNULMENTS]: PathRoutes.SUPPLE_ANNUL_INFO_DETAIL_MOVEMENTS
};

export const goPolicyDetail = {
  [PathRoutes.QUERIES]: PathRoutes.QUERIES_INFO_POLICIES_MOVIMIENTOS,
  [PathRoutes.RENOVATIONS]: PathRoutes.RENOVATIONS_INFO_POLICIES_MOVIMIENTOS,
  [PathRoutes.SINISTERS]: PathRoutes.SINISTERS_INFO_POLICIES_CLAIMS,
  [PathRoutes.SUPPLEMENTS_ANNULMENTS]: PathRoutes.SUPPLE_ANNUL_INFO_POLICIES_MOVIMIENTOS
};

export const goAnnulment = {
  [PathRoutes.QUERIES]: PathRoutes.QUERIES_ANNULMENT_POLICY,
  [PathRoutes.RENOVATIONS]: PathRoutes.RENOVATIONS_ANNULMENT_POLICY,
  [PathRoutes.SINISTERS]: PathRoutes.SINISTERS_ANNULMENT_POLICY,
  [PathRoutes.SUPPLEMENTS_ANNULMENTS]: PathRoutes.SUPPLE_ANNUL_ANNULMENT_POLICY
};

export const goAnnulmentOtherBranches = {
  [PathRoutes.QUERIES]: PathRoutes.QUERIES_ANNULMENT_POLICY_OTHER_BRANCHES,
  [PathRoutes.RENOVATIONS]: PathRoutes.RENOVATIONS_ANNULMENT_POLICY_OTHER_BRANCHES,
  [PathRoutes.SINISTERS]: PathRoutes.SINISTERS_ANNULMENT_POLICY_OTHER_BRANCHES,
  [PathRoutes.SUPPLEMENTS_ANNULMENTS]: PathRoutes.SUPPLE_ANNUL_ANNULMENT_POLICY_OTHER_BRANCHES
};

export const goModifyPersonOtherBranch = {
  [PathRoutes.QUERIES]: PathRoutes.QUERIES_INFO_POLICIES_MOD_TAKER,
  [PathRoutes.RENOVATIONS]: PathRoutes.RENOVATIONS_INFO_POLICIES_MOD_TAKER,
  [PathRoutes.SINISTERS]: PathRoutes.SINISTERS_INFO_POLICIES_MOD_TAKER,
  [PathRoutes.SUPPLEMENTS_ANNULMENTS]: PathRoutes.SUPPLE_ANNUL_INFO_POLICIES_MOD_TAKER
};

export const goModifyAmparos = {
  [PathRoutes.QUERIES]: PathRoutes.QUERIES_INFO_DETAIL_AMPAROS,
  [PathRoutes.RENOVATIONS]: PathRoutes.RENOVATIONS_INFO_DETAIL_AMPAROS,
  [PathRoutes.SINISTERS]: PathRoutes.SINISTERS_INFO_DETAIL_AMPAROS,
  [PathRoutes.SUPPLEMENTS_ANNULMENTS]: PathRoutes.SUPPLE_ANNUL_INFO_DETAIL_AMPAROS
};

export const goModifyPlate = {
  [PathRoutes.QUERIES]: PathRoutes.QUERIES_INFO_DETAIL_PLATE,
  [PathRoutes.RENOVATIONS]: PathRoutes.RENOVATIONS_INFO_DETAIL_PLATE,
  [PathRoutes.SINISTERS]: PathRoutes.SINISTERS_INFO_DETAIL_PLATE,
  [PathRoutes.SUPPLEMENTS_ANNULMENTS]: PathRoutes.SUPPLE_ANNUL_INFO_DETAIL_PLATE
};

export const goCertificateDetail = {
  [PathRoutes.QUERIES]: PathRoutes.QUERIES_INFO_POLICIES_CERTIFICATE_MOVIMIENTOS,
  [PathRoutes.RENOVATIONS]: PathRoutes.RENOVATIONS_INFO_POLICIES_CERTIFICATE_MOVIMIENTOS,
  [PathRoutes.SINISTERS]: PathRoutes.SINISTERS_INFO_POLICIES_CERTIFICATE_CLAIMS,
  [PathRoutes.SUPPLEMENTS_ANNULMENTS]: PathRoutes.SUPPLE_ANNUL_INFO_POLICIES_CERTIFICATE_MOVIMIENTOS
};

export const goModifyPerson = {
  [PathRoutes.QUERIES]: PathRoutes.QUERIES_INFO_DETAIL_MOD_TAKER,
  [PathRoutes.RENOVATIONS]: PathRoutes.RENOVATIONS_INFO_DETAIL_MOD_TAKER,
  [PathRoutes.SINISTERS]: PathRoutes.SINISTERS_INFO_DETAIL_MOD_TAKER,
  [PathRoutes.SUPPLEMENTS_ANNULMENTS]: PathRoutes.SUPPLE_ANNUL_INFO_DETAIL_MOD_TAKER
};

export const goModifyWarranties = {
  [PathRoutes.QUERIES]: PathRoutes.QUERIES_INFO_DETAIL_AMPAROS,
  [PathRoutes.RENOVATIONS]: PathRoutes.RENOVATIONS_INFO_DETAIL_AMPAROS,
  [PathRoutes.SINISTERS]: PathRoutes.SINISTERS_INFO_DETAIL_AMPAROS
};

export const goModifyRisk = {
  [PathRoutes.QUERIES]: PathRoutes.QUERIES_INFO_DETAIL_PLATE,
  [PathRoutes.RENOVATIONS]: PathRoutes.RENOVATIONS_INFO_DETAIL_PLATE,
  [PathRoutes.SINISTERS]: PathRoutes.SINISTERS_INFO_DETAIL_PLATE
};

export const useBasePath = () => {
  const { pathname } = useLocation();
  const basePath = useMemo(() => '/' + pathname.split('/')[1], [pathname]);
  const pathIsConsultation = useMemo(() => basePath == PathRoutes.QUERIES, [basePath]);
  const pathIsRenovations = useMemo(() => basePath == PathRoutes.RENOVATIONS, [basePath]);
  const pathIsSinisters = useMemo(() => basePath == PathRoutes.SINISTERS, [basePath]);
  const pathIsSuppleAnnul = useMemo(
    () => basePath == PathRoutes.SUPPLEMENTS_ANNULMENTS,
    [basePath]
  );

  const actionPage = useMemo(() => actionsPage[basePath] || <ActionsPageQueries />, [basePath]);

  const routRedirectApp = useMemo(
    () => ({
      pathTracking: goTracking[basePath] || PathRoutes.QUERIES_TRACKING,
      pathReturnSingleCar: goSingleCar[basePath] || PathRoutes.QUERIES_INFO_DETAIL_MOVEMENTS,
      pathReturnAnnulment: goAnnulment[basePath] || PathRoutes.QUERIES_ANNULMENT_POLICY,
      pathModifyPersons: goModifyPerson[basePath] || PathRoutes.QUERIES_INFO_DETAIL_PERSONS,
      pathModifyWarranties: goModifyWarranties[basePath] || PathRoutes.QUERIES_INFO_DETAIL_AMPAROS,
      pathModifyRisk: goModifyRisk[basePath] || PathRoutes.QUERIES_INFO_DETAIL_PLATE,
      pathReturnPolicyDetail:
        goPolicyDetail[basePath] || PathRoutes.QUERIES_INFO_POLICIES_MOVIMIENTOS,
      pathReturnCertificateDetail:
        goCertificateDetail[basePath] || PathRoutes.QUERIES_INFO_POLICIES_CERTIFICATE_MOVIMIENTOS,
      pathReturnAnnulmentOtherBranches:
        goAnnulmentOtherBranches[basePath] || PathRoutes.QUERIES_ANNULMENT_POLICY_OTHER_BRANCHES,
      pathSupplemmentsReturnModifyPerson:
        goModifyPerson[basePath] || PathRoutes.QUERIES_INFO_DETAIL_MOD_TAKER,
      pathSupplemmentsReturnModifyPersonOtherBranch:
        goModifyPersonOtherBranch[basePath] || PathRoutes.SUPPLE_ANNUL_INFO_POLICIES_MOD_TAKER,
      pathSupplemmentsReturnModifyAmparos:
        goModifyAmparos[basePath] || PathRoutes.QUERIES_INFO_DETAIL_AMPAROS,
      pathSupplemmentsReturnModifyPlates:
        goModifyPlate[basePath] || PathRoutes.QUERIES_INFO_DETAIL_PLATE
    }),
    [basePath]
  );

  return {
    basePath,
    pathIsConsultation,
    pathIsRenovations,
    pathIsSinisters,
    pathIsSuppleAnnul,
    actionPage,
    ...routRedirectApp
  };
};
