import { SEND_TRIGGERS_QUALTRICS } from 'common/sendTriggersQualtrics';

const triggerQSI = (flagTrigger) => {
  const QSI_Supplements =
    flagTrigger === SEND_TRIGGERS_QUALTRICS.QSI_ANNULMENT_POLICY_OTHER_BRANCH ||
    flagTrigger === SEND_TRIGGERS_QUALTRICS.QSI_ANNULMENT_POLICY_AUTOS ||
    flagTrigger === SEND_TRIGGERS_QUALTRICS.QSI_MODIFY_PERSON_POLICY_OTHER_BRANCH;
  if (!QSI_Supplements) {
    if (!sessionStorage.getItem(flagTrigger)) {
      try {
        const QSI = window.QSI;
        QSI.API.unload();
        QSI.API.load().then(QSI.API.run());
        sessionStorage.setItem(flagTrigger, 'CREATED');
      } catch (error) {
        console.error('Error en la encuesta QSI.', error);
      }
    }
  } else {
    try {
      const QSI = window.QSI;
      QSI.API.unload();
      QSI.API.load().then(QSI.API.run());
    } catch (error) {
      console.error('Error en la encuesta QSI.', error);
    }
  }
};

export default triggerQSI;
