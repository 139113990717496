import { createSlice } from '@reduxjs/toolkit';
import {
  fetchLoadCertificateDetail,
  fetchLoadPoliceOthers,
  reLoadPoliceOthers
} from '../policesOthers/ActionsPoliceOthersSlice';

const initialState = {
  movements: [],
  notes: []
};

export const movementsSlice = createSlice({
  name: 'policeMovements',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchLoadPoliceOthers.fulfilled, (state, { payload }) => {
      const { movements, notes } = payload.storageMovementsSlice;
      state.movements = movements;
      state.notes = notes;
    });
    builder.addCase(reLoadPoliceOthers.fulfilled, (state, { payload }) => {
      const { movements, notes } = payload.storageMovementsSlice;
      state.movements = movements;
      state.notes = notes;
    });
    builder.addCase(fetchLoadCertificateDetail.fulfilled, (state, { payload }) => {
      const { movements, notes } = payload.storageMovementsSlice;
      state.movements = movements;
      state.notes = notes;
    });
  },
  reducers: {
    onRestartStateMovement: (state) => {
      state.movements = [];
      state.notes = [];
    }
  }
});

// Action creators are generated for each case reducer function
export const { onRestartStateMovement } = movementsSlice.actions;
