import { configureStore } from '@reduxjs/toolkit';
import { PolicesSliceAutosIndividualSlice } from './policesAutosIndividual/PolicesSliceAutosIndividualSlice';
import { policesOthersSlice } from './policesOthers/policesOthersSlice';
import { renewalSlice } from './renewals/renewalsSlice';
import { downloadAlertsSlice } from './downloadAlerts/downloadAlertsSlices';
import { isDev } from '../utils';
import { generalSlice } from './general/generalSlice';
import { annulmentSlice } from './annulment/annulmentSlice';
import { OtherBranchAnnulmentSlice } from './OtherBranchAnnulment/OtherBranchAnnulmentSlice';
import { sinistersSlice } from './sinisters/sinistersSlice';
import { consultation } from './consultation/consultationSlice';
import { movementsSlice } from './movements/movementsSlice';
import { personsSlice } from './persons/personsSlice';
import { riskSlice } from './risks/riskSlice';
import { claimsSlice } from './Claims/claimSlice';
import { coverageSlice } from './coverages/coverageSlice';
import { ProcessRenovationSlice } from './processRenovation/ProcessRenovationSlice';
import { SupplementsAnnultments } from './supplementsAnnulments/SuppleAnnulSlice';
import { OptionsActionsSlice } from './actionsOptionsPolicyDasboard/OptionPolicyDashboardSlice';
import { ProcessDownloadSlice } from './processDownloadPDF/ProcessDownloadSlice';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  reducer: {
    consultation: consultation.reducer,
    policesAI: PolicesSliceAutosIndividualSlice.reducer,
    policesOthers: policesOthersSlice.reducer,
    renewals: renewalSlice.reducer,
    alertDownload: downloadAlertsSlice.reducer,
    general: generalSlice.reducer,
    annulment: annulmentSlice.reducer,
    annulmentOtherBranch: OtherBranchAnnulmentSlice.reducer,
    sinisters: sinistersSlice.reducer,
    movements: movementsSlice.reducer,
    persons: personsSlice.reducer,
    risks: riskSlice.reducer,
    claims: claimsSlice.reducer,
    coverages: coverageSlice.reducer,
    SupplementsAnnultments: SupplementsAnnultments.reducer,
    OptionsActions: OptionsActionsSlice.reducer,
    processRenovation: ProcessRenovationSlice.reducer,
    processDownloadPDF: ProcessDownloadSlice.reducer
  },
  devTools: isDev()
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
