import { postRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';

export const getRenewalState = async (query) => {
  try {
    const body = {
      broker: query?.broker,
      policies: [{ sseguro: query?.sseguro, product: query?.product_code }]
    };
    const { data } = await postRequest('renewal_states', body, BaseTypes.renewals);
    return {
      renewal_status: data[0].state,
      dateRenewal: data[0].dateRenewal,
      expirationDate: data[0].expirationDate
    };
  } catch (error) {
    console.error('Error getState :', error);
    return {
      renewal_status: null,
      dateRenewal: null,
      expirationDate: null
    };
  }
};
