import { FC, HTMLAttributes } from 'react';
import styles from './Container.module.scss';

export const Container: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <div className={styles.root}>
      <div className={styles.container} {...props}>
        {children}
      </div>
    </div>
  );
};

export default Container;
