import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getTokens } from 'context/SessionContext/utils';
import { BaseTypes } from './const';

const baseURLS = {
  session: process.env.REACT_APP_URL_API_SESSION,
  policies: process.env.REACT_APP_URL_API_POLICIES,
  renewals: process.env.REACT_APP_URL_API_RENEWALS,
  endorsement: process.env.REACT_APP_URL_API_ENDORSEMENT,
  sinisters: process.env.REACT_APP_URL_API_SINISTERS,
  cancellation: process.env.REACT_APP_URL_API_CANCELLATION
};

export const clientPolicies = axios.create({
  baseURL: baseURLS.policies
});

// Client with interceptors
const client = axios.create();

// Client to renew policies for single cars
const clientRenew = axios.create({
  baseURL: baseURLS.renewals,
  timeout: 28500
});

// Client comunicate to S3
export const clientS3 = axios.create();

//Add interceptors
for (const instance of [client, clientPolicies, clientRenew]) {
  instance.interceptors.request.use(
    (config) => {
      const { jwt, jwtRefer } = getTokens();
      if (jwt) config.headers.Authorization = `Bearer ${jwt}`;
      if (jwtRefer) config.headers.jwt_refer = `${jwtRefer}`;
      return config;
    },
    (error) => Promise.reject(error.status)
  );
}

export const postRequest = async <T>(
  path,
  body = {},
  base: BaseTypes,
  conf?: AxiosRequestConfig<any>
): Promise<AxiosResponse<T, any>> => {
  return await client.post<T>(`${baseURLS[base]}/${path}`, body, conf);
};

export const getRequest = async <T>(
  path,
  base: BaseTypes,
  conf?: AxiosRequestConfig<any>
): Promise<AxiosResponse<T, any>> => {
  return await client.get<T>(`${baseURLS[base]}/${path}`, conf);
};

export const putRequest = async <T>(
  path,
  body = {},
  base: BaseTypes
): Promise<AxiosResponse<T, any>> => {
  return await client.put<T>(`${baseURLS[base]}/${path}`, body);
};

export const deleteRequest = async <T>(
  path,
  body = {},
  base: BaseTypes
): Promise<AxiosResponse<T, any>> => {
  return await client.delete<T>(`${baseURLS[base]}/${path}`, body);
};

// Client Without interceptors
const basicClient = axios.create();

export const endSessionRequest = async <T>(
  path,
  base: BaseTypes,
  data: any,
  config?: AxiosRequestConfig<any>
): Promise<AxiosResponse<T, any>> => {
  return await basicClient.post<T>(`${baseURLS[base]}/${path}`, data, config);
};

export const basicGetRequest = async <T>(
  path,
  base: BaseTypes,
  conf?: AxiosRequestConfig<any>
): Promise<AxiosResponse<T, any>> => {
  return await basicClient.get<T>(`${baseURLS[base]}/${path}`, conf);
};

export const postRenew = async (options) => {
  if (!options.body && !options.url) return;
  const { body, url } = options;
  return await clientRenew.post(url, { policy: { ...body } });
};

export const putToS3 = async (url: string, file: File | Blob) => {
  return await clientS3.put(url, file);
};
