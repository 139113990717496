import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGroup } from 'components/ActionsPages/renovations/Tables/Policies/utils';
import { postRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';
import { parseDate } from 'utils/formatDate';
import { ILoadCertificateDetail, ILoadPoliceOthers, IPageListCertificates } from './actionsTypes';
import { errorBodyDashboard, filterCertificatesOnline, getInfoPolicesOthers } from './services';
import { validitionDownloadPDF } from 'utils/options';

export const fetchLoadPoliceOthers = createAsyncThunk(
  'policesOthers/fetchLoadPoliceOthers',
  async ({ query, isRenewals = false, isSuppleAnnul = false }: ILoadPoliceOthers) =>
    getInfoPolicesOthers(query, isRenewals, isSuppleAnnul)
);

export const reLoadPoliceOthers = createAsyncThunk(
  'policesOthers/reLoadPoliceOthers',
  async ({ query, isRenewals = false, where = '', isSuppleAnnul = false }: ILoadPoliceOthers) =>
    getInfoPolicesOthers(query, isRenewals, isSuppleAnnul)
);

export const fetchInfoHolderStatus = createAsyncThunk(
  'infoHolderStatus/fetchInfoHolderStatus',
  async ({ query }: { query: any }) => {
    try {
      const bodyPolicy = {
        data: {
          ...query
        }
      };
      const {
        data: { data }
      } = await postRequest<{ data: any }>('info-axis-collective', bodyPolicy, BaseTypes.policies);
      return {
        ...data
      };
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchLoadCertificateDetail = createAsyncThunk(
  'policesOthers/fetchLoadCertificateDetail',
  async ({
    queryDashboard,
    queryCertificate,
    isRenewals = false,
    isfromSupple = false
  }: ILoadCertificateDetail) => {
    let dataToLoad = {
      dataDashboard: {
        ...queryDashboard,
        branch: {
          ...queryDashboard.branch,
          group: isRenewals ? getGroup(queryDashboard.branch.group) : queryDashboard.branch.group
        }
      },
      dataCertificateDetail: {
        ...queryCertificate
      },
      movements: null,
      persons: null,
      risks_tab: null,
      claims: null,
      notes: null,
      coverages: null
    };

    if (queryDashboard.newCore != '0' || queryDashboard.new_core != 0) {
      try {
        const bodyPolicy = {
          data: {
            sseguro: queryDashboard.sseguro
          }
        };
        const {
          data: { data: dataPolicy }
        } = await postRequest<{ data: any }>('policy-detail', bodyPolicy, BaseTypes.policies);

        dataToLoad = {
          ...dataToLoad,
          dataDashboard: {
            ...dataPolicy,
            newCore: queryDashboard.newCore || queryDashboard?.new_core,
            downloadPDF:
              queryDashboard.downloadPDF ||
              queryDashboard?.download_pdf ||
              validitionDownloadPDF(queryDashboard),
            isIndividual: queryDashboard.isIndividual || queryDashboard?.is_individual
          }
        };
      } catch (error) {
        console.error('Error:', error);
        return errorBodyDashboard(error);
      }

      try {
        const bodyCertificate = {
          data: {
            sseguro: !isfromSupple ? queryCertificate.sseguroCertif : queryCertificate?.sseguro
          }
        };
        const {
          data: { data: dataCertificate }
        } = await postRequest<{ data: any }>(
          'certificate-detail',
          bodyCertificate,
          BaseTypes.policies
        );

        const { movements, notes, persons, risks_tab, claims, coverages, ...certificateDetail } =
          dataCertificate;

        dataToLoad = {
          ...dataToLoad,
          dataCertificateDetail: {
            ...certificateDetail,
            descargaPdf:
              queryCertificate?.descargaPdf ||
              queryCertificate?.download_pdf ||
              validitionDownloadPDF(queryCertificate),
            errorCertificateDetail: null
          },
          movements,
          notes,
          persons,
          risks_tab,
          claims,
          coverages
        };
      } catch (error) {
        console.error(error);
        return {
          storagePoliceSlice: {
            policyDashboard: dataToLoad.dataDashboard,
            certificateDetail: null,
            errorCertificateDetail: error
          },
          storageMovementsSlice: {
            movements: [],
            notes: []
          },
          storagePersonsSlice: {
            persons: []
          },
          storageRiskSlice: {
            risks: []
          },
          storageClaimsSlice: {
            claims: []
          },
          storageCoverageSlice: {
            coverages: []
          }
        };
      }
      if (queryDashboard.branch.code == 105) {
        try {
          const bodyRisks = {
            data: {
              codigoProducto: !isfromSupple
                ? dataToLoad?.dataDashboard?.product_code
                : queryDashboard?.product_code,
              numeroPoliza: !isfromSupple
                ? dataToLoad?.dataDashboard?.policy_number
                : queryDashboard?.policy_number,
              sseguro: !isfromSupple ? queryCertificate.sseguroCertif : queryCertificate?.sseguro
            }
          };
          const {
            data: { data: risks_tab }
          } = await postRequest<{ data: any }>('risks', bodyRisks, BaseTypes.policies);
          dataToLoad = {
            ...dataToLoad,
            risks_tab
          };
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }

    return {
      storagePoliceSlice: {
        policyDashboard: dataToLoad.dataDashboard,
        certificateDetail: dataToLoad.dataCertificateDetail
      },
      storageMovementsSlice: {
        movements: dataToLoad.movements,
        notes: dataToLoad.notes
      },
      storagePersonsSlice: {
        persons: dataToLoad.persons
      },
      storageRiskSlice: {
        risks: dataToLoad.risks_tab
      },
      storageClaimsSlice: {
        claims: dataToLoad.claims
      },
      storageCoverageSlice: {
        coverages: dataToLoad.coverages
      },
      error: null
    };
  }
);

export const changePageListCertificates = createAsyncThunk(
  'policiesOthers/changePageListCertificates',
  async ({
    policyDashboard,
    query,
    page,
    per_page,
    isRenewals = false,
    isSuppleAnnul = false
  }: IPageListCertificates) => {
    try {
      const agrupacion = isRenewals
        ? getGroup(policyDashboard.branch.group)
        : policyDashboard.branch.group;
      const body = !isSuppleAnnul
        ? {
            data: {
              numeroPoliza: policyDashboard.policy_number || policyDashboard.policyNumber,
              agrupacion,
              producto: policyDashboard.product_code,
              fechaEmision: parseDate(policyDashboard.issue_date || policyDashboard.issueDate),
              fechaFin: parseDate(
                policyDashboard.expiration_date || policyDashboard.expirationDate
              ),
              filters: query ? { ...query } : {},
              pagination: {
                page,
                per_page
              },
              fromRenewals: isRenewals
            }
          }
        : {
            data: {
              product_code: policyDashboard.product_code,
              policy_number: policyDashboard.policy_number || policyDashboard.policyNumber,
              from_supplements: isSuppleAnnul,
              filters: query ? { ...query } : {},
              pagination: { page, per_page }
            }
          };
      const endPoint = !isSuppleAnnul ? 'certificates' : 'certificates-online';
      const {
        data: { data }
      } = await postRequest<{ data: any }>(endPoint, body, BaseTypes.policies);

      const newData = {
        queryCertificates: query,
        listCertificatesFiltered: !query ? [] : data.certificates,
        infoPage: {
          page: data.page,
          total_items: data.total_items,
          total_pages: data.total_pages
        }
      };
      return !query ? { ...newData, listCertificates: data.certificates } : { ...newData };
    } catch (error) {
      console.error('Error:', error);
      return {
        queryCertificates: null,
        listCertificates: [],
        listCertificatesFiltered: [],
        infoPage: {
          page: 1,
          total_items: 0,
          total_pages: 0
        }
      };
    }
  }
);

export const fetchFilterCertificatesOnline = createAsyncThunk(
  'policiesOthers/fetchFilterCertificatesOnline',
  async (query: any) => filterCertificatesOnline(query)
);
