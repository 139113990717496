//*REACT
import { useEffect, useMemo, useRef, useState } from 'react';
//*ICONS
import arrowDown from 'assets/icons/arrowDown.svg';
import addFileIcon from 'assets/icons/addFile.svg';
import alertErrorIcon from 'assets/icons/error.svg';
//*STYLES
import styles from './LibertyCustomSelectAddOptions.module.scss';
import { verifyEmail } from './utils';

export const LibertyCustomSelectAddOptions = ({
  list = [],
  min = 0,
  maxLength = 0,
  selected,
  onChange,
  firstOptionName = 'Nuevo elemento',
  type = 'text',
  reset,
  label = 'Seleccionar o agregar dirección',
  width = '100%',
  errorMessage = 'Este campo es obligatorio',
  isRequired = false
}) => {
  const headerRef = useRef(null);
  const inputRef = useRef(null);
  const boxOptionsRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [focus, setFocus] = useState(false);

  const listToComponent = useMemo(
    () => [
      {
        icon: addFileIcon,
        name: firstOptionName,
        value: 'add'
      },
      ...(list.length ? list : [])
    ],
    [list, firstOptionName]
  );

  const clearAll = () => {
    setChangeOption({
      newInputOption: '',
      option: ''
    });
    setIsOpen(false);
  };

  const styleError = useMemo(() => {
    if (
      (type == 'email' && selected?.newInputOption && !verifyEmail(selected?.newInputOption)) ||
      (selected?.newInputOption && selected?.newInputOption?.length < min) ||
      (isRequired && !selected?.newInputOption && !selected?.option) ||
      (isRequired && !selected?.newInputOption && selected?.option)
    ) {
      if (focus) {
        return styles.error_focus;
      } else {
        return styles.error;
      }
    }
    return '';
  }, [selected?.newInputOption, type, focus, isRequired]);

  const setChangeOption = (optSelected) => {
    if (optSelected.option == 'add') {
      const valueChange = optSelected.newInputOption;
      let errorMinCharacters = false;
      const lowerCaseValue = valueChange.toLowerCase();
      let errorMessageMinCharacters = '';
      if (lowerCaseValue?.length > maxLength) {
        return;
      }
      if (lowerCaseValue?.length < min) {
        errorMinCharacters = true;
        errorMessageMinCharacters = `Este campo debe contener ${min} caracteres`;
      }
      if (lowerCaseValue?.includes('ñ') || lowerCaseValue?.includes('Ñ')) {
        return;
      }

      if (type === 'number' && lowerCaseValue) {
        const newInputOption = lowerCaseValue.replace(/[^0-9]/g, '');
        return onChange({
          ...optSelected,
          newInputOption,
          error: errorMinCharacters,
          errorMessage: errorMessageMinCharacters
        });
      }
      if (type == 'email') {
        const newInputOption = lowerCaseValue.replace(/\s/g, '');
        return onChange({
          ...optSelected,
          newInputOption,
          error: !verifyEmail(newInputOption) || errorMinCharacters,
          errorMessage: !verifyEmail(newInputOption) && 'Formato de correo electrónico incorrecto'
        });
      }
      return onChange({ ...optSelected, error: false });
    }
    if (optSelected.option.trim() !== '') {
      if (type == 'email') {
        return onChange({
          ...optSelected,
          newInputOption: optSelected.option,
          error: false,
          errorMessage: 'Formato de correo electrónico incorrecto'
        });
      }
      if (type == 'number') {
        return onChange({
          ...optSelected,
          newInputOption: optSelected.option,
          error: false,
          errorMessage: `Este campo debe contener ${min} caracteres`
        });
      }
    } else {
      onChange({
        ...optSelected,
        newInputOption: optSelected.option,
        error: false
      });
    }
  };

  const closeSelect = (e) => {
    if (
      !e.composedPath()?.includes(headerRef.current) &&
      !e.composedPath()?.includes(boxOptionsRef.current) &&
      !e.composedPath()?.includes(inputRef.current)
    ) {
      if (selected?.option == 'add' && !selected?.newInputOption) {
        clearAll();
      }
      setIsOpen(false);
      return setFocus(false);
    } else if (e.composedPath().includes(boxOptionsRef.current)) {
      setIsOpen(false);
      setFocus(true);
    }
  };

  useEffect(() => {
    if (reset) {
      clearAll();
    }
  }, [reset]);

  useEffect(() => {
    if (isOpen) return;
    if (selected?.option == 'add') return inputRef?.current?.focus();
    setFocus(false);
  }, [isOpen, selected?.option]);

  useEffect(() => {
    document.body.addEventListener('click', closeSelect);
    return () => {
      document.body.removeEventListener('click', closeSelect);
    };
  }, [selected?.newInputOption, selected?.option]);

  return (
    <div className={styles.container_component} style={{ maxWidth: width }}>
      <div
        ref={headerRef}
        className={`${styles.header} ${focus && styles.header_focus} ${styleError}`}
        onClick={() => {
          setIsOpen(!isOpen);
          setFocus(true);
        }}
      >
        <label
          className={styles.placeholder}
          style={{
            fontSize: selected?.option ? '12px' : '16px',
            top: selected?.option ? '4px' : '33%'
          }}
        >
          {label}
        </label>
        {selected?.option && (
          <input
            type="text"
            className={styles.input_new_option}
            ref={inputRef}
            onChange={(e) =>
              setChangeOption({
                option: 'add',
                newInputOption: e.target.value
              })
            }
            value={selected?.newInputOption}
          />
        )}
        <div className={styles.arrow}>
          <img src={arrowDown} alt="arrowDown" />
        </div>
      </div>
      {styleError && (
        <div className={styles.container_msg_error}>
          <div className={styles.msg_error}>
            <div className={styles.icon}>
              <img src={alertErrorIcon} alt="alertErrorIcon" />
            </div>
            <span className={styles.msg}>{selected.errorMessage || errorMessage}</span>
          </div>
        </div>
      )}
      <div
        className={styles.options_box}
        ref={boxOptionsRef}
        style={{
          height: isOpen ? `${48 * (list.length + 1)}px` : 0
        }}
      >
        {listToComponent.map(({ name, value, icon }, i) => {
          return (
            <div
              key={i}
              className={styles.option}
              onClick={() => {
                setChangeOption({ newInputOption: '', option: value });
              }}
            >
              <div className={styles.icon_option}>{icon && <img src={icon} alt="icon" />}</div>

              <span>{name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
