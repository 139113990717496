import styles from './LibertyAlertDownloadResponsive.module.scss';

import close from 'assets/icons/close.svg';
import { useState, useEffect } from 'react';
import { useDownloadAlertsStore } from '../../../../hooks/useDownloadAlertsStore';

import goodDownload from './check.svg';
import Container from 'components/Container';

export const LibertyAlertDownloadResponsive = () => {
  const { good, nameDocument } = useDownloadAlertsStore();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!good) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [good]);

  return (
    <Container>
      <div
        className={styles.alert_download_complete}
        style={{
          display: isOpen ? 'flex' : 'none'
        }}
      >
        <div className={styles.icon}>
          <img src={goodDownload} alt="goodDownload" />
        </div>

        <div className={styles.info}>
          <span className={styles.title}>Descarga completa</span>
          <span className={styles.sub_title}>{nameDocument}</span>
        </div>

        <div
          className={styles.close}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <img src={close} alt="close" />
        </div>
      </div>
    </Container>
  );
};
