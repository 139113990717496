import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StatesDownload } from './types';

const initialState: StatesDownload = {
  success: [],
  errors: [],
  good: false,
  bad: false,
  nameDocument: '',
  title: '',
  description: '',
  data: {},
  isLoading: false,
  isLoadingRePrint: false,
  errorReprintPDF: false,
  error: ''
};

export const downloadAlertsSlice = createSlice({
  name: 'downloadAlerts',
  initialState,
  reducers: {
    onRestartState: (state) => {
      state.success = [];
      state.errors = [];
      state.good = false;
      state.bad = false;
      state.data = {};
      state.isLoading = false;
      state.isLoadingRePrint = false;
      state.errorReprintPDF = false;
      state.error = '';
      state.nameDocument = '';
      state.title = '';
      state.description = '';
    },
    onSetSuccessDownload: (state, { payload }) => {
      state.success = payload;
    },
    onSetErrorsDownload: (state, { payload }: PayloadAction<any[]>) => {
      state.errors = payload;
    },
    onStoreDocument: (state, { payload }) => {
      state.nameDocument = payload.name;
      state.data = payload.data;
    },
    onStartLoad: (state) => {
      state.isLoading = true;
    },
    onStopLoad: (state) => {
      state.isLoading = false;
    },
    onStartLoadReprint: (state) => {
      state.isLoadingRePrint = true;
    },
    onStopLoadRePrint: (state) => {
      state.isLoadingRePrint = false;
    },
    onErrorReprintPDF: (state, { payload }) => {
      state.errorReprintPDF = payload;
    },
    onError: (state, { payload }) => {
      state.error = payload;
    },
  }
});

export const {
  onStoreDocument,
  onRestartState,
  onStartLoad,
  onStopLoad,
  onStartLoadReprint,
  onStopLoadRePrint,
  onErrorReprintPDF,
  onError,
  onSetSuccessDownload,
  onSetErrorsDownload
} = downloadAlertsSlice.actions;
