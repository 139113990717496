import LibertyButton from 'components/basics/LibertyButton/LibertyButton';
import clock from 'assets/icons/clock.svg';
import { Header, LibertyModalBase } from 'components/basics/LibertyModal/components';
import Grid from '@mui/material/Grid';
import DigitalClock from './DigitalClock';
import styles from './TimeOut.module.scss';
import clsx from 'clsx';

interface TimeoutProps {
  open: boolean;
  /**
   * The number of milliseconds to count down from.
   */
  counter: number;
  onChange: (milliseconds: number) => void;
  onClose: () => void;
  onTimeout: () => void;
  onCloseSession: () => void;
}

export const Timeout = ({
  open,
  counter,
  onChange,
  onClose,
  onTimeout,
  onCloseSession
}: TimeoutProps) => {
  return (
    <LibertyModalBase open={open} containerClassName={styles.container} onClose={onClose}>
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
        className={styles.body}
      >
        <Grid container>
          <Grid item xs={12}>
            <Header onClose={onClose} />
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <img src={clock} />
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <h1 className="font-medium text-center dark-blue">
              <span>¿Estás ahí? </span>
              Tu sesión finalizará en:
            </h1>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            {open && (
              <DigitalClock
                time={counter}
                onChangeSecond={(newCount) => {
                  if (newCount === 0) {
                    onTimeout();
                  } else onChange(newCount);
                }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <p className={styles.title}>
              Si deseas continuar en sesión,
              <br />
              presiona el botón
              <b className={styles.str}>
                {' "'}Continuar{'"'}
              </b>
            </p>
          </Grid>
        </Grid>
      </div>
      <div className={styles.footer}>
        <div className={styles.buttons}>
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs="auto">
              <LibertyButton id="btn_Timeout_CerrarSesion" className={clsx('secondary', styles.button)} onClick={onCloseSession}>
                Cerrar sesión
              </LibertyButton>
            </Grid>
            <Grid item xs="auto">
              <LibertyButton id="btn_Timeout_Continuar" className={clsx('primary', styles.button)} onClick={onClose}>
                Continuar
              </LibertyButton>
            </Grid>
          </Grid>
        </div>
      </div>
    </LibertyModalBase>
  );
};
