import { createSlice } from '@reduxjs/toolkit';
import { fetchCheckProcess } from './ActionsProcessRenovation';
import { reLoadPoliceOthers } from 'store/policesOthers/ActionsPoliceOthersSlice';

const initialState = {
  pending: [],
  success: [],
  errors: []
};

export const ProcessRenovationSlice = createSlice({
  name: 'ProcessRenovation',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCheckProcess.fulfilled, (state, { payload }) => {
      state.pending = payload.pending || [];
      state.success = payload.success || [];
      state.errors = payload.errors || [];
    });
    builder.addCase(reLoadPoliceOthers.fulfilled, (state, { payload }) => {
      const policyNumber = payload.storagePoliceSlice.policyDashboard.policy_number;
      state.success = state.success.map((policyItem) => ({
        ...policyItem,
        reload: policyNumber == policyItem.policy ? true : policyItem.reload
      }));
      state.errors = state.errors.map((policyItem) => ({
        ...policyItem,
        reload: policyNumber == policyItem.policy ? true : policyItem.reload
      }));
    });
  },
  reducers: {
    onRestartProcessRenovation: (state) => {
      state.pending = [];
      state.success = [];
      state.errors = [];
    }
  }
});

// Action creators are generated for each case reducer function
export const { onRestartProcessRenovation } = ProcessRenovationSlice.actions;
