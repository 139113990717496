import { useNavigate, Link } from 'react-router-dom';
import arrowLeft from 'assets/icons/arrowLeft.svg';
import { useMemo } from 'react';

export const LibertyCrumbsResponsive = ({ e, b, location, styles }) => {
  const pathToGo = useMemo(() => {
    if (e.path.includes('/request-detail/:quote')) {
      return `${e.path}${b[0]}`;
    } else {
      return e.pathToGo(location);
    }
  }, [e, b, location]);
  const navigate = useNavigate();

  return (
    <span className={styles.title}>
      <img
        src={arrowLeft}
        alt="arrow"
        className={styles.arrow}
        onClick={() => {
          if (b !== b[0]) {
            navigate(-1);
          }
        }}
      />
      <Link
        onClick={() => {
          window.location.replace(pathToGo);
        }}
        to={pathToGo}
        style={e.style}
      >
        {e.title}
      </Link>
    </span>
  );
};
