import { lazy, useMemo } from 'react';
import { PathRoutes } from 'common/routes';
import useBreadcrumbInformation from './useBreadcrumbInformation';

const Consultation = lazy(() => import('pages/Consultation/Consultation'));
const InformacionPolizas = lazy(() => import('pages/ConsultationInfo/ConsultationInfo'));
const SingleCarsDetail = lazy(() => import('pages/SingleCarsDetail/SingleCarsDetail'));
const OtherBranchDetail = lazy(() => import('pages/OtherBranchDetail/OtherBranchDetail'));
const CertificateDetail = lazy(() => import('pages/CertificateDetail/CertificateDetail'));
const AnnulmentPolicy = lazy(() => import('pages/AnnulmentPolicy/AnnulmentPolicy'));
const AnnulmentPolicyOtherBranch = lazy(() => import('pages/AnnulmentPolicyOtherBranches/AnnulmentPolicy'));
const Tracking = lazy(() => import('pages/Tracking/Tracking'));

export const useQueriesRoutes = () => {
  const { policyNumber, certificateNumber } = useBreadcrumbInformation();

  return useMemo(
    () => [
      {
        path: PathRoutes.QUERIES,
        exact: true,
        name: 'Gestión de consultas',
        title: 'Gestión de consultas',
        pathToGo: () => {
          return PathRoutes.QUERIES;
        },
        element: Consultation
      },
      {
        path: PathRoutes.QUERIES_INFO,
        exact: true,
        name: `Información de pólizas`,
        title: 'Información de pólizas',
        pathToGo: () => {
          return PathRoutes.QUERIES_INFO;
        },
        element: InformacionPolizas
      },
      {
        path: PathRoutes.QUERIES_INFO_DETAIL + '/*',
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.QUERIES_INFO_DETAIL_MOVEMENTS;
        },
        element: SingleCarsDetail
      },
      {
        path: PathRoutes.QUERIES_INFO_POLICIES + '/*',
        exact: true,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.QUERIES_INFO_POLICIES;
        },
        element: OtherBranchDetail
      },
      {
        path: PathRoutes.QUERIES_INFO_POLICIES_CERTIFICATE + '/*',
        exact: true,
        name: `Certificado ${certificateNumber}`,
        title: `Certificado ${certificateNumber}`,
        pathToGo: () => {
          return PathRoutes.QUERIES_INFO_POLICIES_CERTIFICATE;
        },
        element: CertificateDetail
      },
      {
        path: PathRoutes.QUERIES_ANNULMENT_POLICY,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.QUERIES_INFO_DETAIL_MOVEMENTS;
        },
        element: AnnulmentPolicy
      },
      {
        path: PathRoutes.QUERIES_ANNULMENT_POLICY_OTHER_BRANCHES,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.QUERIES_INFO_POLICIES_MOVIMIENTOS;
        },
        element: AnnulmentPolicyOtherBranch
      },
      {
        path: PathRoutes.QUERIES_TRACKING,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.QUERIES_INFO_DETAIL_CLAIMS;
        },
        element: Tracking
      }
    ],
    [certificateNumber, policyNumber]
  );
};
