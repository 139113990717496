import { LOCAL_STORAGE_VARIABLES } from 'common/sessionStorageVariables';

export const getDownloadPdfsPending = () =>
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_VARIABLES.DOWNLOAD_PDFS_PENDING))?.[
    LOCAL_STORAGE_VARIABLES.DOWNLOAD_PDFS_PENDING
  ] || [];

export const setDownloadPdfsPending = (newPendingDownload = []) => {
  const DPP = LOCAL_STORAGE_VARIABLES.DOWNLOAD_PDFS_PENDING;
  localStorage.setItem(DPP, JSON.stringify({ [DPP]: [...newPendingDownload] }));
};
