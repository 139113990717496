import libertyArm from 'assets/icons/liberty-arm.svg';
import loader from 'assets/icons/loader.svg';
import React from 'react';
import styles from './LibertyLoader.module.scss';

const LibertyLoader = () => {
  return (
    <div className={styles.loaderContainer}>
      <img className={styles.loaderArm} src={libertyArm} alt="liberty-arm" />
      <img className={styles.loader} src={loader} alt="loader" />
    </div>
  );
};

export default LibertyLoader;
