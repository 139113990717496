import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest, postRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';

export const fetchLoadSearch = createAsyncThunk(
  'consultation/fetchSearch',
  async ({ query, page, per_page }) => {
    if (!query) {
      return {
        query: null,
        results: [],
        totalResults: 0,
        totalPages: 0,
        error: null
      };
    }
    try {
      const body = {
        data: {
          intermediary: {},
          filters: {
            ...query
          },
          pagination: {
            page,
            per_page
          }
        }
      };

      const {
        data: { data }
      } = await postRequest('index', body, BaseTypes.policies);
      return {
        query: { ...data.filters },
        results: data.policies,
        totalResults: data.total_items,
        totalPages: data.total_pages,
        error: null
      };
    } catch (error) {
      console.error(error);
      return {
        query: { ...query },
        results: [],
        totalResults: 0,
        totalPages: 0,
        error
      };
    }
  }
);

export const fetchLoadCarousel = createAsyncThunk('consultation/fetchLoadCarousel', async () => {
  try {
    const body = {
      data: {
        intermediary: {}
      }
    };
    const {
      data: { data }
    } = await postRequest('dashboard', body, BaseTypes.policies);
    return {
      results: data.branches,
      error: null
    };
  } catch (error) {
    console.error(error);
    return {
      results: [],
      error
    };
  }
});

export const fetchLoadCarouselSinisters = createAsyncThunk(
  'consultation/fetchLoadCarouselSinisters',
  async () => {
    try {
      const {
        data: { data }
      } = await getRequest('dashboard', BaseTypes.sinisters);
      return {
        results: data.branches,
        error: null
      };
    } catch (error) {
      console.error(error);
      return {
        results: [],
        error
      };
    }
  }
);
