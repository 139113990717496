import { Grid } from '@mui/material';
import { ReactNode } from 'react';

import styles from './Item.module.scss';

interface ItemProps {
  icon: ReactNode;
  button: ReactNode;
  title: ReactNode;
  className?: string;
  itemTitleStyle?: object;
}

export const Item = ({ icon, title, button, className, itemTitleStyle }: ItemProps) => (
  <Grid container className={className}>
    <Grid item xs="auto">
      {icon}
    </Grid>
    <Grid item xs>
      <Grid container className={styles.section}>
        <Grid item xs="auto" className={styles.title}>
          <span style={itemTitleStyle}>{title}</span>
        </Grid>
        <Grid item xs={12} className={styles.button}>
          {button}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default Item;
