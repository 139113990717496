import styles from './LibertyCustomInputCheck.module.scss';

export const LibertyCustomInputCheck = ({
  id = '',
  onChange,
  isChecked,
  blocked = false,
  styleBox = {},
  classNameBox = '',
  styleCheck = {},
  classNameCheck = '',
  disable = false
}) => {
  return (
    <label className={`${styles.container} ${classNameBox}`} style={{ ...styleBox }}>
      <input
        type="checkbox"
        onChange={(e) => !blocked && onChange(e.target.checked)}
        checked={isChecked}
        disabled={disable}
      />
      <span
        id={id}
        className={`${styles.checkmark} ${blocked && styles.blocked} ${classNameCheck}`}
        style={{ ...styleCheck }}
      />
    </label>
  );
};
