import styles from './LibertyIncompleteDownload.module.scss';
import close from 'assets/icons/close.svg';
import { useEffect } from 'react';

import badDownload from './warningRed.svg';

export const LibertyIncompleteDownload = ({ title, message, id, closeAlert }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      closeAlert(id);
    }, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [closeAlert, id]);

  return (
    <div className={styles.alert_download_incomplete}>
      <img className={styles.icon_bad} src={badDownload} alt="badDownload" />
      <div className={styles.info}>
        <span className={styles.title}>{title}</span>
        {message}
      </div>

      <img className={styles.close} onClick={() => closeAlert(id)} src={close} alt="close" />
    </div>
  );
};
