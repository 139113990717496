import { createSlice } from '@reduxjs/toolkit';
import { Persons } from './types';
import {
  fetchLoadCertificateDetail,
  fetchLoadPoliceOthers
} from 'store/policesOthers/ActionsPoliceOthersSlice';
import { fetchPersonsStatus } from './ActionsPersonsSlice';
import { reLoadPoliceOthers } from '../policesOthers/ActionsPoliceOthersSlice';

interface State extends Persons {
  loading: boolean;
  error: boolean | null;
}

const initialState: State = {
  holders: null,
  insured: null,
  beneficiaries: null,
  dental_beneficiaries: null,
  drivers: null,
  personsUpdating: [],
  loading: false,
  error: null
};

export const personsSlice = createSlice({
  name: 'policePersons',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchLoadPoliceOthers.fulfilled, (state, { payload }) => {
      const { persons } = payload.storagePersonsSlice;
      if (persons) {
        const { beneficiaries, holders, insured, dental_beneficiaries, drivers, persons_updating } =
          persons;
        state.beneficiaries = beneficiaries;
        state.holders = holders;
        state.insured = insured;
        state.dental_beneficiaries = dental_beneficiaries;
        state.drivers = drivers;
        state.personsUpdating = persons_updating;
      }
    });
    builder.addCase(reLoadPoliceOthers.fulfilled, (state, { payload }) => {
      const { persons } = payload.storagePersonsSlice;
      if (persons) {
        const { beneficiaries, holders, insured, dental_beneficiaries, drivers, persons_updating } =
          persons;
        state.beneficiaries = beneficiaries;
        state.holders = holders;
        state.insured = insured;
        state.dental_beneficiaries = dental_beneficiaries;
        state.drivers = drivers;
        state.personsUpdating = persons_updating;
      }
    });
    builder.addCase(fetchLoadCertificateDetail.fulfilled, (state, { payload }) => {
      const { persons } = payload.storagePersonsSlice;
      if (persons) {
        const { beneficiaries, holders, insured, dental_beneficiaries, drivers, persons_updating } =
          persons;
        state.beneficiaries = beneficiaries;
        state.holders = holders;
        state.insured = insured;
        state.dental_beneficiaries = dental_beneficiaries;
        state.drivers = drivers;
        state.personsUpdating = persons_updating;
      }
    });
    builder.addCase(fetchPersonsStatus.fulfilled, (state, { payload }) => {
      if (payload.persons) {
        const { beneficiaries, holders, insured, dental_beneficiaries, drivers, persons_updating } =
          payload.persons;
        state.beneficiaries = beneficiaries;
        state.holders = holders;
        state.insured = insured;
        state.dental_beneficiaries = dental_beneficiaries;
        state.drivers = drivers;
        state.personsUpdating = persons_updating;
      }
    });
  },

  reducers: {
    onRestartPersonsState: (state) => {
      state.holders = null;
      state.beneficiaries = null;
      state.insured = null;
      state.personsUpdating = [];
    }
  }
});

export const { onRestartPersonsState } = personsSlice.actions;
