import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { PathRoutes } from 'common';
import LibertyChangePageButton from 'components/basics/LibertyChangePageButton/LibertyChangePageButton';

export const SwitchQueries = () => {
  const navigate = useNavigate();
  const optionsList = useMemo(
    () => [
      {
        name: 'Renovación',
        action: () => navigate(PathRoutes.RENOVATIONS),
        id: 'id_to_renovation_button'
      },
      {
        name: 'Siniestros',
        action: () => navigate(PathRoutes.SINISTERS),
        id: 'id_to_sinister_button'
      },
      {
        name: 'Suplementos y anulaciones',
        action: () => navigate(PathRoutes.SUPPLEMENTS_ANNULMENTS),
        id: 'id_to_supplements_button'
      }
    ],
    [navigate]
  );

  return <LibertyChangePageButton title={'Otras consultas'} options={optionsList} />;
};
