export enum Branches {
  cars = 'Autos',
  singleCarsAdvanceQuery = 'Autos individual',
  singleCars = 'AUTOS INDIVIDUAL',
  compliance = 'Cumplimiento',
  business = 'Empresariales',
  home = 'Hogar',
  engineering = 'Ingeniería',
  civilLiability = 'Responsabilidad Civil',
  health = 'Salud',
  soat = 'SOAT',
  transportation = 'Transportes',
  life = 'Vida'
}
