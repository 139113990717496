import styles from './LibertyCompleteDownload.module.scss';
import close from 'assets/icons/close.svg';

import goodDownload from './check.svg';
import { useEffect } from 'react';

export const LibertyCompleteDownload = ({ nameDocument, id, closeAlert }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      closeAlert(id);
    }, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [closeAlert, id]);

  return (
    <div className={styles.alert_download_complete}>
      <img className={styles.icon_good} src={goodDownload} alt="goodDownload" />

      <div className={styles.info}>
        <span className={styles.title}>Descarga completa</span>
        <span className={styles.sub_title}>{nameDocument}</span>
      </div>
      <img className={styles.close} onClick={() => closeAlert(id)} src={close} alt="close" />
    </div>
  );
};
