import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';

interface IResponseDateSinisters {
  date: Date | string;
}

interface IResponseLastDateUpdate {
  data: IResponseDateSinisters;
}

interface IPropsFetchLoadLastDateUpdate {
  url: string;
  baseURL: BaseTypes;
}

export const fetchLoadLastDateUpdate = createAsyncThunk(
  'general/fetchLoadLastDateUpdate',
  async ({ url, baseURL }: IPropsFetchLoadLastDateUpdate) => {
    const {
      data: { data }
    } = await getRequest<IResponseLastDateUpdate>(url, baseURL);
    return data.date;
  }
);
