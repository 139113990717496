import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RenewalsQuery, RenewalsSlice } from './types';
import {
  fetchLoadDashboardCards,
  fetchLoadSearchRenew,
  reloadPoliciesPending,
  reloadSearchRenew
} from './ActionsRenewSlice';

const initialState: RenewalsSlice = {
  selected: [],
  isLoading: false,
  isLoadingDashboard: false,
  branchesDashboard: [],
  responseAdvanceSearch: [],
  results: [],
  totalPages: 0,
  totalItems: 0,
  date: new Date(),
  query: null,
  errorDashboard: null,
  errorSearch: null,
  pagination: {
    page: 1,
    limit: 10
  }
};
export const renewalSlice = createSlice({
  name: 'renewal',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchLoadSearchRenew.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLoadSearchRenew.fulfilled, (state, { payload }: { payload: any }) => {
      state.query = payload.query;
      state.responseAdvanceSearch = payload.policies;
      state.results = payload.policies;
      state.totalPages = payload.totalPages;
      state.totalItems = payload.totalItems;
      state.errorSearch = payload.errorSearch;
      state.pagination = payload.pagination;
      state.isLoading = false;
    });
    builder.addCase(reloadSearchRenew.fulfilled, (state, { payload }: { payload: any }) => {
      state.query = payload.query;
      state.responseAdvanceSearch = payload.policies;
      state.results = payload.policies;
      state.totalPages = payload.totalPages;
      state.totalItems = payload.totalItems;
      state.errorSearch = payload.errorSearch;
      state.pagination = payload.pagination;
      state.isLoading = false;
    });
    builder.addCase(reloadPoliciesPending.fulfilled, (state, { payload }: { payload: any }) => {
      const loadedPolicies = state.responseAdvanceSearch || state.results;
      const changesPolicies = loadedPolicies.map((loadedPolicy) => {
        const policyToChange = payload.find(
          (policy) => policy.policy_number == loadedPolicy.policy_number
        );
        if (policyToChange) {
          return { ...policyToChange };
        } else {
          return { ...loadedPolicy };
        }
      });
      state.responseAdvanceSearch = changesPolicies;
      state.results = changesPolicies;
    });
    builder.addCase(fetchLoadDashboardCards.pending, (state) => {
      state.isLoadingDashboard = true;
    });
    builder.addCase(fetchLoadDashboardCards.fulfilled, (state, { payload }) => {
      state.branchesDashboard = payload.results;
      state.errorDashboard = payload.errorDashboard;
      state.isLoadingDashboard = false;
    });
  },
  reducers: {
    restartRenewals: (state) => {
      state.selected = [];
      state.isLoading = false;
      state.isLoadingDashboard = false;
      state.branchesDashboard = [];
      state.responseAdvanceSearch = [];
      state.results = [];
      state.totalPages = 0;
      state.totalItems = 0;
      state.date = new Date();
      state.query = null;
      state.errorDashboard = null;
      state.errorSearch = null;
      state.pagination = {
        page: 1,
        limit: 10
      };
    },
    onResetSearch: (state) => {
      state.selected = [];
      state.isLoading = false;
      state.responseAdvanceSearch = [];
      state.results = [];
      state.totalPages = 0;
      state.totalItems = 0;
      state.query = null;
      state.errorSearch = null;
    },
    onReloadAdvanceSearch: (state) => {
      state.isLoading = false;
      state.responseAdvanceSearch = [];
      state.results = [];
      state.totalPages = 0;
      state.totalItems = 0;
      state.query = null;
      state.errorSearch = null;
      state.pagination = {
        page: 1,
        limit: 10
      };
    },
    onLoadRenewalsSelected: (state, { payload }) => {
      state.results = payload;
      state.responseAdvanceSearch = payload;
      state.totalItems = payload.length;
      state.totalPages = 1;
      state.isLoading = false;
    },
    setQuery: (state, { payload }: PayloadAction<RenewalsQuery>) => {
      state.query = payload;
    },
    setDate: (state, { payload }: PayloadAction<Date>) => {
      state.date = payload;
    },
    setSelectedMassively: (state, { payload }) => {
      state.selected = payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  restartRenewals,
  onLoadRenewalsSelected,
  setQuery,
  setDate,
  onReloadAdvanceSearch,
  setSelectedMassively,
  onResetSearch
} = renewalSlice.actions;
