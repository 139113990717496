import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import isNumeric from 'validator/lib/isNumeric';
import clsx from 'clsx';

import searchIcon from 'assets/icons/searchNotFound.svg';
import alertErrorIcon from 'assets/icons/error.svg';
import { InputProps, InputType, LocationType } from './types';
import styles from './LibertyCustomInput.module.scss';

export const LibertyCustomInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id = '',
      capitalLetterOn = true,
      errors = false,
      error_message = 'Los datos ingresados no son validos',
      separation_error_message = '-60%',
      location_error_message = LocationType.top,
      search_icon = false,
      reset = false,
      warning = false,
      placeholder = 'introduzca un valor',
      disabled = false,
      inactive = false,
      maxLength = 12,
      minLength = 0,
      fontSize = 16,
      width = '100%',
      height = '100%',
      type = InputType.text,
      border = true,
      inputClassName,
      className,
      value,
      ...props
    },
    ref
  ) => {
    const [startWrite, setStartWrite] = useState('placeholder');
    const [errorActive, setErrorActive] = useState(false);
    const labelRef = useRef();

    const inputRef = useRef<HTMLInputElement>();

    const canWrite = () => {
      if (!inactive && !disabled) {
        setStartWrite('startWrite');
        inputRef.current?.focus();
      }
    };

    const restartInput = (e) => {
      const input = inputRef.current;
      const container = input?.parentElement;
      if (
        e.composedPath()[0] !== container &&
        e.composedPath()[0] !== labelRef.current &&
        e.composedPath()[0] !== input &&
        !input?.value
      ) {
        setStartWrite('placeholder');
      }
    };

    const verifyNumbers = (e) => {
      const valueN = e.target.value;
      if ((!isNumeric(valueN) && valueN) || e.keyCode === 69) {
        e.preventDefault();
      }
    };

    const error = useMemo(() => {
      return errors;
    }, [errors]);

    useEffect(() => {
      if (inactive && !inputRef?.current?.value) setStartWrite('placeholder');
      else if (inputRef?.current?.value) setStartWrite('startWrite');
    }, [inactive, inputRef?.current?.value]);

    useEffect(() => {
      const input = inputRef.current;
      if (reset) {
        setStartWrite('placeholder');
        input.value = '';
        input.blur();
      }
    }, [reset]);

    useEffect(() => {
      document.body.addEventListener('click', restartInput);
      return () => {
        document.body.removeEventListener('click', restartInput);
      };
    }, [placeholder]);

    const onChange = (e) => {
      const valueChange = e.target.value;
      setErrorActive(valueChange ? valueChange.length < minLength : false);
      if (type === 'number' && valueChange != '') {
        e.target.value = valueChange.replace(/[^0-9]/g, '');
      } else {
        if (capitalLetterOn) e.target.value = valueChange.toString().toUpperCase();
        else e.target.value = valueChange.toString();
      }
      props.onChange?.(e);
    };

    const stylesActive = useMemo(() => {
      if (error || errorActive) return styles.liberty_error_form;
      if (warning) return styles.liberty_warning_form;
      if (disabled) return styles.liberty_disable_form;
      else return '';
    }, [error, errorActive, warning, disabled]);

    return (
      <div
        id={id}
        className={clsx(styles.liberty_form, stylesActive, className, inactive && styles.inactive)}
        style={{ width, height }}
      >
        {(errorActive || errors) && (
          <div
            className={styles.error_msg}
            style={{
              width,
              top: location_error_message == LocationType.top && separation_error_message,
              bottom: location_error_message == LocationType.bottom && separation_error_message
            }}
          >
            <div className={styles.container_error_icon}>
              <img src={alertErrorIcon} alt="alertErrorIcon" />
            </div>
            <span>{error_message}</span>
          </div>
        )}

        {search_icon && startWrite !== 'startWrite' && !inputRef?.current?.value && (
          <div
            className={clsx(
              error || errorActive ? styles.search_icon : warning ? styles.search_icon : '',
              styles.search_icon
            )}
          >
            <img src={searchIcon} alt="searchIcon" />
          </div>
        )}

        <input
          className={clsx(styles.input, inputClassName)}
          {...props}
          onKeyDown={(event) => {
            type === 'number' && verifyNumbers(event);
          }}
          onChange={onChange}
          onKeyUp={({ currentTarget }) => {
            currentTarget.value =
              type !== 'number'
                ? capitalLetterOn
                  ? currentTarget.value.toString().toUpperCase()
                  : currentTarget.value.toString()
                : currentTarget.value;
          }}
          onClick={canWrite}
          disabled={inactive || disabled}
          type="text"
          maxLength={maxLength}
          autoComplete="off"
          placeholder=" "
          ref={(input) => {
            if (typeof ref === 'function') ref?.(input);
            else if (ref) {
              ref.current = input;
            }
            inputRef.current = input;
          }}
          style={{
            fontSize: `${fontSize}px`,
            border: !border && 'none'
          }}
          value={value}
        />
        <label
          htmlFor="title"
          className={clsx(styles.placeholder)}
          style={{
            paddingLeft:
              search_icon && startWrite !== 'startWrite' && !inputRef?.current?.value
                ? '40px'
                : '7px',
            fontSize: startWrite !== 'startWrite' ? `${fontSize}px` : '12px'
          }}
        >
          {placeholder}
        </label>
      </div>
    );
  }
);
export default LibertyCustomInput;
