import { PayloadAction, createSlice } from '@reduxjs/toolkit';
//*TYPES
import { ISinistersSlice } from 'common/sinisters';
import { fetchLoadCarouselSinisters, fetchLoadSearchSinister } from './ActionsSinisterSlice';

const initialState: ISinistersSlice = {
  carouselSinister: {
    results: [],
    isLoading: false,
    error: null
  },
  searchSinisters: {
    infoSearch: {
      page: 1,
      totalPages: 0,
      pageItems: 0,
      totalItems: 0
    },
    querySearch: null,
    resultsBase: [],
    errorSearch: null,
    isLoadingSearch: false
  }
};

export const sinistersSlice = createSlice({
  name: 'sinisters',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchLoadSearchSinister.pending, (state) => {
      state.searchSinisters = {
        ...state.searchSinisters,
        isLoadingSearch: true
      };
    });
    builder.addCase(fetchLoadSearchSinister.fulfilled, (state, { payload }: PayloadAction<any>) => {
      state.searchSinisters = {
        ...state.searchSinisters,
        ...payload,
        isLoadingSearch: false
      };
    });
    builder.addCase(fetchLoadCarouselSinisters.pending, (state) => {
      state.carouselSinister = {
        ...state.carouselSinister,
        isLoading: true
      };
    });
    builder.addCase(fetchLoadCarouselSinisters.fulfilled, (state, { payload }) => {
      state.carouselSinister = {
        ...state.carouselSinister,
        ...payload,
        isLoading: false
      };
    });
  },
  reducers: {
    onResetStateSinister: (state) => {
      state.carouselSinister = initialState.carouselSinister;
      state.searchSinisters = initialState.searchSinisters;
    },
    onResetSearchSinisters: (state) => {
      state.searchSinisters = initialState.searchSinisters;
    },
    onSetResultsSelectedClaims: (state, { payload }) => {
      state.searchSinisters = {
        ...payload,
        isLoadingSearch: false
      };
    }
  }
});

// Action creators are generated for each case reducer function
export const { onResetStateSinister, onSetResultsSelectedClaims, onResetSearchSinisters } =
  sinistersSlice.actions;
