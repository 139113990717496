import { createAsyncThunk } from '@reduxjs/toolkit';
import { postRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';

export const fetchPersonsStatus = createAsyncThunk(
  'personsStatus/fetchPersonsStatus',
  async ({ query }: { query: any }) => {
    try {
      const bodyPolicy = {
        data: {
          sseguro: query.sseguro
        }
      };
      const {
        data: { data: dataPolicy }
      } = await postRequest<{ data: any }>('policy-detail', bodyPolicy, BaseTypes.policies);

      return { persons: dataPolicy?.persons };
    } catch (error) {
      console.error(error);
    }
  }
);
