import styles from './LibertyCustomPagination.module.scss';

//*Icon
import arrow from './arrow.svg';

import { useMemo, useCallback } from 'react';
import { PaginationProps } from './types';

export const LibertyCustomPagination = ({
  currentPage = 1,
  onChange,
  onAlert,
  minPages = 1,
  maxPages = 5,
  totalPages = 10,
  btnLeft = true,
  btnRight = true,
  limitPagesShown = 10
}: PaginationProps) => {
  const getPageNumber = useCallback(
    (newPage, lastPageNumber, numbersToShow) => {
      const currentPageNumber = newPage <= lastPageNumber ? newPage : lastPageNumber;
      const maxPageBefCurrPage = Math.floor(numbersToShow / 2);
      const maxPageAftCurrPage = Math.ceil(numbersToShow / 2) - 1;
      let startPage = minPages;
      let endPage = lastPageNumber;
      let pageNumber: (string | number)[] = [];

      if (lastPageNumber < 1) {
        return pageNumber;
      }

      if (lastPageNumber > 1 && lastPageNumber <= maxPages) {
        return Array.from(Array(lastPageNumber).keys()).map((pn) => {
          return startPage + pn;
        });
      }

      if (currentPageNumber <= maxPageBefCurrPage) {
        startPage = 1;
        endPage = numbersToShow;
      } else if (currentPageNumber + maxPageAftCurrPage >= lastPageNumber) {
        startPage = lastPageNumber - numbersToShow + 1;
      } else {
        startPage = currentPageNumber - maxPageBefCurrPage;
        endPage = currentPageNumber + maxPageAftCurrPage;
      }

      pageNumber = Array.from(Array(endPage + 1 - startPage).keys())
        .map((pn) => {
          return startPage + pn;
        })
        .filter((pn) => pn <= lastPageNumber && pn > 0);

      if (pageNumber[0] > 1) {
        if (pageNumber[0] <= 2) {
          pageNumber = [1, ...pageNumber];
        } else {
          const threeDots = pageNumber[0] >= 3 ? '...' : 2;
          pageNumber = [1, threeDots, ...pageNumber];
        }
      }

      if (pageNumber[pageNumber.length - 1] !== lastPageNumber) {
        if (pageNumber[pageNumber.length - 1] === lastPageNumber - 1) {
          pageNumber = [...pageNumber, lastPageNumber];
        } else {
          const threeDots =
            pageNumber[pageNumber.length - 1] <= lastPageNumber - 2 ? '...' : lastPageNumber - 1;
          pageNumber = [...pageNumber, threeDots, lastPageNumber];
        }
      }

      return pageNumber;
    },
    [maxPages, minPages]
  );

  const newButtons = useMemo(() => {
    return getPageNumber(currentPage, totalPages, 3);
  }, [currentPage, getPageNumber, totalPages]);

  const changePage = (element) => {
    if (currentPage === element) return;
    if (element === '...') return;
    if (element > limitPagesShown) return onAlert();
    onChange?.(element);
  };

  return (
    <div className={styles.pagination}>
      {btnLeft && (
        <button
          className={`${styles.btnLeft} ${styles.btn} ${styles.btnArrows}`}
          onClick={() => {
            onChange?.(currentPage - 1);
          }}
          disabled={currentPage === 1}
        >
          <img src={arrow} alt="arrow" />
        </button>
      )}
      {newButtons.map((element, i) =>
        element === '...' ? (
          <span
            key={`${i}`}
            className={`
            ${styles.threePoints} 
            ${i === 1 ? styles.left_treePoints : styles.right_treePoints}
          `}
          >
            {element}
          </span>
        ) : (
          <button
            className={`${styles.btn} 
          ${styles.btnPages} 
          ${currentPage === element ? styles.btnSelected : ''}
          ${i == 0 ? styles.first : ''}
          ${i == newButtons.length - 1 ? styles.last : ''}
          `}
            key={`${i}`}
            onClick={() => {
              changePage(element);
            }}
          >
            {element}
          </button>
        )
      )}
      {btnRight && (
        <button
          className={`${styles.btnRight} ${styles.btn} ${styles.btnArrows}`}
          onClick={() => {
            currentPage < limitPagesShown ? onChange?.(currentPage + 1) : onAlert();
          }}
          disabled={currentPage === totalPages}
        >
          <img src={arrow} alt="arrow" />
        </button>
      )}
    </div>
  );
};
