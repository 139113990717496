import capitalize from 'lodash/capitalize';
import format from 'date-fns/format';
import { es } from 'date-fns/esm/locale';
import parse from 'date-fns/parse';
import enUS from 'date-fns/locale/en-US';
import enAU from 'date-fns/locale/en-AU';
import parseISO from 'date-fns/parseISO';

const separator = '/';
const formatter = `dd${separator}MMM${separator}yyyy`;
const formatterFullMonth = `dd${separator}MMMM${separator}yyyy`;

/**
 * @param date Date to format
 * @param fullMonth All de name of the month
 * @returns a string with the Liberty date Format
 */
export const formatDate = (date: Date, fullMonth = false, inputFormat = formatter): string => {
  const dateFormat = format(date, fullMonth ? formatterFullMonth : inputFormat, { locale: es });
  const month = dateFormat.split(separator, 2)[1];
  return dateFormat.replace(month, capitalize(month));
};

export const changeFormatDate = (date: string, inputFormat: string = 'dd/MMM/yyyy'): string => {
  const formatIso = parse(date, inputFormat, new Date(), { locale: es });
  const dateFormat = format(formatIso, formatterFullMonth, { locale: es });
  const month = dateFormat.split(separator, 2)[1];
  return dateFormat.replace(month, capitalize(month));
};

export const getDateISO = (date: string | Date, inputFormat: string = 'dd/MMM/yyyy'): Date => {
  if (date instanceof Date) return date;
  return parse(date, inputFormat, new Date(), { locale: es });
};

export const parseDate = (stringDate) => {
  if (stringDate instanceof Date && !isNaN(+stringDate)) return stringDate;
  if (stringDate === null || stringDate === undefined) return stringDate;

  const langs = [es, enUS, enAU, undefined];

  const now = new Date();
  try {
    let date = parseISO(stringDate);
    if (!isNaN(+date)) return date;
  } catch (error) {
    //
  }
  for (const lang of langs) {
    const formats = ['dd/MM/yyyy', 'dd/MMM/yyyy', 'yyyy-MM-dd', 'dd-MM-yyyy'];
    for (const format of formats) {
      let date = parse(stringDate, format, now, {
        locale: lang
      });
      if (!isNaN(+date)) return date;
    }
  }
  return new Date(stringDate.replace(' ', 'T'));
};

export const dateToString = (date: string | Date, outputFormat = 'dd/MMM/yyyy') => {
  if (!date) return '-';
  const dateFormat = parseDate(date);
  const dateString = format(dateFormat, outputFormat, { locale: es });
  const month = dateString.split(separator, 2)[1];
  return dateString.replace(month, capitalize(month));
};

export const parseDateToString = (date: string, outputFormat = 'dd/MMM/yyyy') => {
  if (!date) return '-';
  const dateFormat = parseISO(date.slice(0, -1));
  const dateString = format(dateFormat, outputFormat, { locale: es });
  const month = dateString.split(separator, 2)[1];
  return dateString.replace(month, capitalize(month));
};

export const parseDateHours = (date: Date) => {
  const time = {
    hour: '-',
    minutes: '-'
  };
  if (!date) return `${time.hour}:${time.minutes}`;
  return format(date, 'hh:mm aaa');
};
