import { URL_OFFICE } from 'common';
import React, { createContext, useEffect, useState } from 'react';
import { useRestoreSession } from './hooks';
import { Inactivity } from './Inactivity';
import { buildSession, clearAll, getTokens } from './utils';
import { endSessionRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';

interface ISessionData {
  ip: string;
  last_session: string;
  email: string;
  BrokerCode: number;
  user_name: string;
}
interface IDynamoData {
  message: string;
  session_data: ISessionData | null;
}

interface Session {
  broker: string;
  name: string;
  accessToken: string;
  email: string;
  dynamoData: IDynamoData;
  ip: string;
}

interface SessionContextProps {
  session: Session | null;
  loading: boolean;
  logout: () => void;
}

export const SessionContext = createContext<SessionContextProps>({
  session: null,
  loading: false,
  logout: () => {
    //
  }
});

export const useSession = () => React.useContext(SessionContext);

export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState<Session>(null);
  const [loading, setLoading] = useState(true);
  const { restore } = useRestoreSession();

  useEffect(() => {
    setLoading(true);
    restore()
      .then((data) => {
        setLoading(false);
        try {
          setSession(buildSession(data));
        } catch (error) {
          //
        }
      })
      .catch(() => {
        logout();
      });
  }, [restore]);

  const logout = async () => {
    const header = {
      headers: {
        refer: getTokens().refer
      }
    };
    const body = {
      data: {
        last_session: new Date()
      }
    };
    try {
      await endSessionRequest('end-session', BaseTypes.session, body, header);
    } catch (error) {
      console.error(error);
    } finally {
      setSession(null);
      clearAll();
      document.location.replace(URL_OFFICE);
    }
  };
  return (
    <SessionContext.Provider value={{ session, loading, logout }}>
      <Inactivity logout={logout} />
      {children}
    </SessionContext.Provider>
  );
};
export const SessionConsumer = SessionContext.Consumer;
export default SessionContext;
