import clsx from 'clsx';
import React from 'react';
import styles from './ClockDigitContainer.module.scss';

export const ClockDigitContainer = (
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
) => (
  <div {...props} className={clsx(styles.root, props.className)}>
    <div className={styles.container}>{props.children}</div>
  </div>
);
