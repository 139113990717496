import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import arrowRight from 'assets/icons/arrowRight.svg';

import {
  currentAndPreviousMonth,
  initDateContainer,
  subMonths,
  addMonths,
  firstDateOfTheMonth,
  getNumDaysMonth,
  isEqual,
  getMonthById,
  formatDate,
  transformDate,
  createArrayDays
} from '../utils';

import styles from './LibertyCustomCalendar.module.scss';
import { TooltipCalendar } from '../SubComponents/TooltipCalendar';

export const LibertyCustomCalendar = ({
  onChange,
  maxDate = null,
  minDate = null,
  children,
  selected,
  margin = '0px',
  width = 'max-content',
  top = '-170px',
  left = '-40%',
  pastTooltip = 'No es posible seleccionar una fecha en el pasado.',
  futureTooltip = 'No es posible seleccionar una fecha superior a 30 días a partir de hoy.',
  showToolTip = true
}) => {
  const calendarRef = useRef();
  const cancelBtnRef = useRef();
  const okBtnRef = useRef();
  const childrenRef = useRef();

  const [isShow, setIsShow] = useState(false);

  const [dateContainer, setDateContainer] = useState(initDateContainer(minDate));
  const [date, setDate] = useState(currentAndPreviousMonth(minDate));

  const addMonth = () => {
    const { currentMonth, previousMonth } = date;
    setDate({
      currentMonth: addMonths(currentMonth, 1),
      previousMonth: addMonths(previousMonth, 1)
    });
  };

  const subtractMonth = () => {
    const { currentMonth, previousMonth } = date;
    setDate({
      currentMonth: subMonths(currentMonth, 1),
      previousMonth: subMonths(previousMonth, 1)
    });
  };

  const chooseDate = () => {
    onChange?.(dateContainer.selectedDate);
    setIsShow(false);
    setDateContainer({
      ...dateContainer,
      currentDate: dateContainer.selectedDate
    });
  };

  const validDate = useCallback(
    (dateIn) => {
      const comparedDate = dateIn.getTime();
      const topDate = maxDate instanceof Date ? maxDate.getTime() : null;
      const bottomDate = minDate instanceof Date ? minDate.getTime() : null;

      if (topDate && topDate - comparedDate < 0) {
        return false;
      } else if (bottomDate && comparedDate - bottomDate < 0) {
        return false;
      } else {
        return true;
      }
    },
    [maxDate, minDate]
  );

  const changeDate = (dateDay) => {
    if (validDate(dateDay)) {
      setDateContainer({
        ...dateContainer,
        selectedDate: dateDay
      });
      setDate({
        currentMonth: dateDay,
        previousMonth: subMonths(dateDay, 1)
      });
    }
  };

  const openCloseSelect = (e) => {
    if (
      e.composedPath().find((element) => element === calendarRef.current) &&
      e.target !== cancelBtnRef.current &&
      e.target !== okBtnRef.current
    ) {
      if (!isShow) {
        const nextDate = selected ? selected : initDateContainer(minDate).selectedDate;
        setDateContainer({
          ...dateContainer,
          selectedDate: nextDate
        });
        setDate({
          currentMonth: nextDate,
          previousMonth: subMonths(nextDate, 1)
        });
        setIsShow(true);
      }
    } else {
      setIsShow(false);
    }
  };

  const validOrInvalidStyle = useCallback(
    (comparedDate) => {
      if (!comparedDate) return '';
      return validDate(comparedDate) ? styles.valid_date : styles.invalid_date;
    },
    [date.currentMonth, validDate]
  );

  const selectedDayStyle = useCallback(
    (comparedDate) => {
      if (!comparedDate) return '';
      if (
        validDate(comparedDate) &&
        isEqual(
          transformDate(
            dateContainer.selectedDate.getDate(),
            dateContainer.selectedDate.getMonth() + 1,
            dateContainer.selectedDate.getFullYear()
          ),
          comparedDate
        )
      ) {
        return styles.selected;
      }

      return '';
    },
    [dateContainer.selectedDate]
  );

  const styleDays = (dateOfDate) => {
    return `${styles.days} ${validOrInvalidStyle(dateOfDate)} ${selectedDayStyle(dateOfDate)}`;
  };

  const arrayDays = useMemo(() => {
    if (!date) return [];
    const { currentMonth, previousMonth } = date;
    let tempDays = [];
    //*Cantidad de días que tiene el mes actual
    const numDaysCurrentMonth = getNumDaysMonth(currentMonth);
    //*Cantidad de días que tiene el mes anterior
    const numDaysPreviousMonth = getNumDaysMonth(previousMonth);
    //*El mes actual inicia un domingo ?
    const firstDayOfWeakOfCurrentMonth = firstDateOfTheMonth(currentMonth).getDay();

    if (firstDayOfWeakOfCurrentMonth != 0) {
      tempDays = [
        ...createArrayDays(
          numDaysPreviousMonth - firstDayOfWeakOfCurrentMonth + 1,
          numDaysPreviousMonth,
          previousMonth,
          minDate,
          maxDate,
          { pastTooltip, futureTooltip }
        )
      ];
    }
    tempDays = [
      ...tempDays,
      ...createArrayDays(1, numDaysCurrentMonth, currentMonth, minDate, maxDate, {
        pastTooltip,
        futureTooltip
      })
    ];
    return tempDays;
  }, [date?.currentMonth, date?.previousMonth, pastTooltip, futureTooltip]);

  const dayMonthHeader = useMemo(() => {
    if (!dateContainer) return '';
    const dateToFormat = dateContainer.selectedDate || dateContainer.currentDate;
    return formatDate(dateToFormat);
  }, [dateContainer]);

  useEffect(() => {
    document.body.addEventListener('click', openCloseSelect);
    return () => {
      document.body.removeEventListener('click', openCloseSelect);
    };
  }, [isShow, selected]);

  return (
    <div
      ref={calendarRef}
      style={{
        height: 'max-content',
        width,
        position: 'relative',
        margin
      }}
    >
      <div ref={childrenRef} style={{ width: '100%' }}>
        {children}
      </div>
      <div
        className={styles.calendar}
        style={{
          display: isShow ? 'flex' : 'none',
          top,
          left,
          zIndex: isShow ? '1000' : '0'
        }}
      >
        <div className={styles.header_calendar}>
          <span className={styles.year_calendar}>
            {dateContainer?.selectedDate?.getFullYear() || ''}
          </span>
          <span className={styles.day_month_calendar}>{dayMonthHeader}</span>
        </div>
        <div className={styles.body_calendar}>
          <div className={styles.month}>
            <button type="button" className={styles.btn_left} onClick={subtractMonth}>
              <img style={{ transform: 'rotate(180deg)' }} src={arrowRight} alt="arrowRight" />
            </button>
            <span>{`${
              date?.currentMonth ? getMonthById(date.currentMonth.getMonth()).complete : ''
            } ${date?.currentMonth ? date.currentMonth.getFullYear() : ''}`}</span>
            <button type="button" className={styles.btn_right} onClick={addMonth}>
              <img src={arrowRight} alt="arrowRight" />
            </button>
          </div>
          <ol className={styles.days_calendar}>
            <li className={styles.day_name}>Do</li>
            <li className={styles.day_name}>Lu</li>
            <li className={styles.day_name}>Ma</li>
            <li className={styles.day_name}>Mi</li>
            <li className={styles.day_name}>Ju</li>
            <li className={styles.day_name}>Vi</li>
            <li className={styles.day_name}>Sa</li>

            {showToolTip
              ? arrayDays.map(({ numDay, dateOfDay, tooltipDate }) => (
                  <TooltipCalendar tooltipInfo={tooltipDate} key={dateOfDay}>
                    <li
                      onClick={() => {
                        changeDate(dateOfDay);
                      }}
                      className={styleDays(dateOfDay)}
                    >
                      {numDay}
                    </li>
                  </TooltipCalendar>
                ))
              : arrayDays.map(({ numDay, dateOfDay }) => (
                  <li
                    onClick={() => {
                      changeDate(dateOfDay);
                    }}
                    className={styleDays(dateOfDay)}
                  >
                    {numDay}
                  </li>
                ))}
          </ol>
          <div className={styles.container_buttons}>
            <button
              type="button"
              ref={cancelBtnRef}
              onClick={() => {
                setIsShow(false);
                setDateContainer({
                  ...dateContainer,
                  selectDate: dateContainer.currentDate
                });
              }}
              className={styles.cancel_button}
            >
              Cancelar
            </button>
            <button type="button" ref={okBtnRef} onClick={chooseDate} className={styles.ok_button}>
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
