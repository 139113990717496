import { PersonRoles } from 'common';
import { RenewalsQuerySearch } from 'store/renewals/types';

import { PolicyAdvanceSearch } from 'models/renovation';

type AllPartial<T> = {
  [P in keyof T]?: Partial<T[P]>;
};
export type RenovationFilters = AllPartial<PolicyAdvanceSearch> & {
  validity?: string;
  role?: string;
  id_type?: string;
  id_number?: string;
};

export const mapFilters = (query: RenewalsQuerySearch) => {
  const { identification, plate, policy, ...rest } = query;
  const { number, type, role } = { ...identification };
  return {
    ...rest,
    validity: typeof query.period === 'string' ? query.period : query.period?.toISOString(),
    branch: {
      name: query.branch
    },
    holder: {
      id_number: role === PersonRoles.taker ? number : undefined,
      id_type: role === PersonRoles.taker ? type : undefined
    },
    insured: {
      id_number: role === PersonRoles.insured ? number : undefined,
      id_type: role === PersonRoles.insured ? type : undefined
    },
    plate,
    policy_number: policy?.value
  };
};
