import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from 'router/AppRouter';
import { store } from 'store/store';
import { SessionProvider } from 'context/SessionContext';
import { RouterProvider } from 'router';
import './styles/styles.scss';

function App() {
  return (
    <React.Fragment>
      <Provider store={store}>
        <BrowserRouter>
          <SessionProvider>
            <RouterProvider>
              <AppRouter />
            </RouterProvider>
          </SessionProvider>
        </BrowserRouter>
      </Provider>
    </React.Fragment>
  );
}

export default App;
