import { postRequest } from 'services/client';
import { BaseTypes } from 'services/client/const';
import { ILoadPersons, PolicyPersons } from './types';
import { IDetailDeductibles } from 'models/modifications';
import { LOCAL_STORAGE_VARIABLES, SESSION_STORAGE_VARIABLES } from 'common/sessionStorageVariables';
import { validitionDownloadPDF } from 'utils/options';
import { getRenewalState } from 'services/getRenewalState/getRenewalState';
import { policyMessageStatus } from 'services/policyMessageStatus/policyMessageStatus';

export const LoadSingleCarPage = async (query) => {
  try {
    sessionStorage.setItem(
      SESSION_STORAGE_VARIABLES.POLICY_SINGLE_CAR,
      JSON.stringify({ ...query })
    );
    const respPolicyDetail = await getPolicyDetail(query);
    const respState = await getRenewalState(query);
    const respPersons = await getPersons({
      codigoProducto: query.productCode || query.product_code.toString(),
      sseguro: query.sseguro,
      numeroPoliza: query.policyNumber || query.policy_number.toString()
    });

    const renewalMessageStatus = await policyMessageStatus({
      ...respPolicyDetail,
      ...respState
    });

    return {
      policyDashboard: {
        ...query,
        ...respPolicyDetail,
        ...respState,
        downloadPDF: validitionDownloadPDF(query),
        renewalMessageStatus,
        personsUpdating:
          JSON.parse(localStorage.getItem(LOCAL_STORAGE_VARIABLES.PERSONS_UPDATING_AI))
            ?.personsUpdating || []
      },
      persons: respPersons,
      error: null
    };
  } catch (error) {
    console.error(error);
    return {
      policyDashboard: null,
      persons: null,
      error
    };
  }
};

export const getPolicyDetail = async (query) => {
  const body = {
    data: {
      sseguro: query.sseguro
    }
  };
  const {
    data: { data }
  } = await postRequest<{ data: any }>('policy-detail', body, BaseTypes.policies);
  return data;
};

export const getPersons = async (query: ILoadPersons) => {
  try {
    const body = {
      data: {
        ...query
      }
    };
    const {
      data: { data }
    } = await postRequest<{ data: PolicyPersons }>('persons', body, BaseTypes.policies);
    return { ...data, errorPersons: null, modTakerSuccess: null };
  } catch (error) {
    console.error('Error:', error);
    return {
      beneficiaries: null,
      dental_beneficiaries: null,
      drivers: null,
      holders: null,
      insured: null,
      modTakerSuccess: null,
      errorPersons: error
    };
  }
};

export const getWarranties = async (body) => {
  try {
    const {
      data: { data }
    } = await postRequest<{ data: IDetailDeductibles }>(
      'getWarranties',
      body,
      BaseTypes.endorsement
    );

    return data;
  } catch (error) {
    console.error(error);
  }
};


