const optionsIniciales = [
  { name: 'Placa', value: 'Placa' },
  { name: 'Número de póliza', value: 'Número de póliza' },
  { name: 'Identificación', value: 'Tipo de ID' },
  { name: 'Periodo', value: 'Periodo' }
];

const initialOptionsClaims = [
  { name: 'Siniestro', value: 'Siniestro' },
  { name: 'Placa', value: 'Placa' },
  { name: 'Número de póliza', value: 'Número de póliza' },
  { name: 'Identificación asegurado', value: 'Identificación asegurado' }
];

const initialOptionsSupleAnnul = [
  { name: 'Número de póliza', value: 'Número de póliza', id: 'id_numero_poliza' },
  {
    name: 'Identificación del tomador',
    value: 'Identificación del tomador',
    id: 'id_identificacion_tomador'
  },
  {
    name: 'Identificación del asegurado',
    value: 'Identificación del asegurado',
    id: 'id_identificacion_asegurado'
  },
  { name: 'Placa', value: 'Placa', id: 'id_placa' }
];

const optionsTest = [
  {
    name: 'Numero de poliza',
    value: 'Numero de poliza'
  },
  {
    name: 'Fecha de vencimiento',
    value: 'Fecha de vencimiento'
  },
  {
    name: 'Id tomador',
    value: 'Id tomador'
  },
  {
    name: 'Id asegurado',
    value: 'Id asegurado'
  },
  {
    name: 'Nombre tomador',
    value: 'Nombre tomador'
  },
  {
    name: 'Nombre asegurado',
    value: 'Nombre asegurado'
  }
];

const idTypesTest = [
  { name: 'c.c', value: 'c.c' },
  { name: 'c.e', value: 'c.e' },
  { name: 'Nit', value: 'Nit' },
  { name: 'Nit E', value: 'Nit E' }
];

const optionsIds = [
  { name: 'ID Tomador', value: 'Tomador' },
  { name: 'ID Asegurado', value: 'Asegurado' }
];

const optionsRamo = [
  { name: 'Autos', value: 'Autos' },
  { name: 'Cumplimiento', value: 'Cumplimiento' },
  { name: 'Empresariales', value: 'Empresariales' },
  { name: 'Hogar', value: 'Hogar' },
  { name: 'Ingeniería', value: 'Ingeniería' },
  { name: 'Responsabilidad civil', value: 'Responsabilidad Civil' },
  { name: 'Salud', value: 'Salud' },
  { name: 'SOAT', value: 'SOAT' },
  { name: 'Transportes', value: 'Transportes' },
  { name: 'Vida', value: 'Vida' }
];

const optionsIdTomador = [
  { name: 'Cédula de ciudadanía - (C.C.)', shortName: 'C.C.', value: 'C.C.' },
  { name: 'NIT', shortName: 'NIT', value: 'NIT' },
  { name: 'NIT extranjero - (NIT E.)', shortName: 'NIT E.', value: 'NIT E.' },
  { name: 'Cédula extranjería - (C.E.)', shortName: 'C.E.', value: 'C.E.' },
  { name: 'Pasaporte - (P.P.)', shortName: 'P.P.', value: 'P.P.' },
  { name: 'Tarjeta de Identidad - (T.I.)', shortName: 'T.I.', value: 'T.I.' },
  { name: 'BIC', shortName: 'BIC', value: 'BIC' },
  { name: 'Carnet Diplomático - (C.D.)', shortName: 'C.D.', value: 'C.D.' },
  { name: 'Identificador del Sistema - (I.S.)', shortName: 'I.S.', value: 'I.S.' },
  { name: 'Identificador Simulaciones - (I.SIM.)', shortName: 'I.SIM.', value: 'I.SIM.' },
  {
    name: 'Número único identificación Personal - (N.U.I.P.)',
    shortName: 'N.U.I.P.',
    value: 'N.U.I.P.'
  },
  { name: 'PECP', shortName: 'PECP', value: 'PECP' },
  { name: 'Permiso por Protección Temporal - (P.P.T.)', shortName: 'P.P.T.', value: 'P.P.T.' },
  { name: 'Permiso especial de permanencia - (P.E.P.)', shortName: 'P.E.P.', value: 'P.E.P.' },
  { name: 'Registro Civil - (R.C.)', shortName: 'R.C.', value: 'R.C.' }
];

const meses = [
  { name: 'Enero', value: '01' },
  { name: 'Febrero', value: '02' },
  { name: 'Marzo', value: '03' },
  { name: 'Abril', value: '04' },
  { name: 'Mayo', value: '05' },
  { name: 'Junio', value: '06' },
  { name: 'Julio', value: '07' },
  { name: 'Agosto', value: '08' },
  { name: 'Septiembre', value: '09' },
  { name: 'Octubre', value: '10' },
  { name: 'Noviembre', value: '11' },
  { name: 'Diciembre', value: '12' }
];

const years = [
  { name: new Date().getFullYear() - 1, value: new Date().getFullYear() - 1 },
  { name: new Date().getFullYear(), value: new Date().getFullYear() }
];

const estadoPoliza = [
  {
    value: 'vigente',
    name: 'VIGENTE',
    color: '#008040'
  },
  {
    value: 'vencida',
    name: 'VENCIDA',
    color: '#d32f2f'
  },
  {
    value: 'Prop. suplem.',
    name: 'PROP. SUPLEM.',
    color: '#28a3af'
  },
  {
    value: 'Prop. cartera',
    name: 'PROP. CARTERA',
    color: '#b85d00'
  },
  {
    value: 'Cancelada',
    name: 'CANCELADA',
    color: '#565656'
  },
  {
    value: 'anulada',
    name: 'ANULADA',
    color: '#323232'
  }
];

const RenovarPolis = [
  { value: 'Descargar previo de renovación', name: 'Descargar previo de renovación' },
  { value: 'Renovar', name: 'Renovar' }
];

const listCertificatesCO = [
  { value: 'id_number', name: 'ID Asegurado' },
  { value: 'certificate', name: 'Número de certificado' }
];

const listCertificatesAC = [
  { value: 'id_number', name: 'ID Asegurado' },
  { value: 'certificate', name: 'Número de certificado' },
  { value: 'plate', name: 'Placa' }
];

//TODO: elimiar esto cuando la tabla de siniestros traiga el pdf
const validitionDownloadPDF = (query) =>
  query.newCore == '1' &&
  !['1', '10004', '10005', 'lb', 'bo', 'ao', '15', 's30', 'u61', '900730'].includes(
    String(query.product_code).toLowerCase()
  )
    ? 1
    : 0;

export {
  optionsIniciales,
  initialOptionsClaims,
  initialOptionsSupleAnnul,
  optionsTest,
  idTypesTest,
  optionsIds,
  optionsIdTomador,
  optionsRamo,
  years,
  meses,
  estadoPoliza,
  RenovarPolis,
  listCertificatesCO,
  validitionDownloadPDF,
  listCertificatesAC
};
