import { lazy, useMemo } from 'react';
import { PathRoutes } from 'common/routes';
import useBreadcrumbInformation from './useBreadcrumbInformation';

const Sinisters = lazy(() => import('pages/Claims/Claims'));
const ClaimsInformation = lazy(() => import('pages/ClaimsInfo/ClaimsInfo'));
const SingleCarDetail = lazy(() => import('pages/SingleCarsDetail/SingleCarsDetail'));
const OtherBranchDetail = lazy(() => import('pages/OtherBranchDetail/OtherBranchDetail'));
const Certificate = lazy(() => import('pages/CertificateDetail/CertificateDetail'));
const AnnulmentPolicy = lazy(() => import('pages/AnnulmentPolicy/AnnulmentPolicy'));
const AnnulmentPolicyOtherBranch = lazy(() => import('pages/AnnulmentPolicyOtherBranches/AnnulmentPolicy'));
const Tracking = lazy(() => import('pages/Tracking/Tracking'));

export const useSinistersRoutes = () => {
  const { policyNumber, certificateNumber } = useBreadcrumbInformation();
  return useMemo(
    () => [
      {
        path: PathRoutes.SINISTERS,
        exact: true,
        name: 'Gestión de siniestros',
        title: 'Gestión de siniestros',
        pathToGo: () => {
          return PathRoutes.SINISTERS;
        },
        element: Sinisters
      },
      {
        path: PathRoutes.SINISTERS_INFO,
        exact: true,
        name: 'Información de siniestros',
        title: 'Información de siniestros',
        pathToGo: () => {
          return PathRoutes.SINISTERS_INFO;
        },
        element: ClaimsInformation
      },
      {
        path: PathRoutes.SINISTERS_INFO_DETAIL + '/*',
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.SINISTERS_INFO_DETAIL_CLAIMS;
        },
        element: SingleCarDetail
      },
      {
        path: PathRoutes.SINISTERS_INFO_POLICIES + '/*',
        exact: true,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.SINISTERS_INFO_POLICIES_CLAIMS;
        },
        element: OtherBranchDetail
      },
      {
        path: PathRoutes.SINISTERS_INFO_POLICIES_CERTIFICATE + '/*',
        exact: true,
        name: `Certificado ${certificateNumber || ''}`,
        title: `Certificado ${certificateNumber || ''}`,
        pathToGo: () => {
          return PathRoutes.SINISTERS_INFO_POLICIES_CERTIFICATE_CLAIMS;
        },
        element: Certificate
      },
      {
        path: PathRoutes.SINISTERS_ANNULMENT_POLICY,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.SINISTERS_INFO_DETAIL_MOVEMENTS;
        },
        element: AnnulmentPolicy
      },
      {
        path: PathRoutes.SINISTERS_ANNULMENT_POLICY_OTHER_BRANCHES,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.SINISTERS_INFO_POLICIES_MOVIMIENTOS;
        },
        element: AnnulmentPolicyOtherBranch
      },
      {
        path: PathRoutes.SINISTERS_TRACKING,
        name: `Póliza ${policyNumber}`,
        title: `Póliza ${policyNumber}`,
        pathToGo: () => {
          return PathRoutes.SINISTERS_INFO_DETAIL_CLAIMS;
        },
        element: Tracking
      }
    ],
    [certificateNumber, policyNumber]
  );
};
