import { RenewalsQuery } from './types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { actionBaseSearch, changePoliciesPending, getDashboardCards } from './services';

export const fetchLoadSearchRenew = createAsyncThunk(
  'renovation/fetchLoadSearchRenew',
  async ({ query, page, perPage }: { query: RenewalsQuery; page?: number; perPage?: number }) =>
    actionBaseSearch(query, page, perPage)
);

export const reloadSearchRenew = createAsyncThunk(
  'renovation/reloadSearchRenew',
  async ({ query, page, perPage }: { query: RenewalsQuery; page?: number; perPage?: number }) =>
    actionBaseSearch(query, page, perPage)
);

export const reloadPoliciesPending = createAsyncThunk(
  'renovation/reloadPoliciesPending',
  async ({ policies }: { policies: any[] }) => changePoliciesPending(policies)
);

export const fetchLoadDashboardCards = createAsyncThunk(
  'renovation/fetchLoadDashboardCards',
  async ({ dateSelected }: { dateSelected: Date }) => {
    try {
      const { data } = await getDashboardCards(dateSelected);
      return {
        results: data.branches,
        errorDashboard: null
      };
    } catch (errorDashboard) {
      console.error(errorDashboard);
      return {
        results: [],
        errorDashboard
      };
    }
  }
);
