import { BaseTypes } from 'services/client/const';
import { basicGetRequest, postRequest } from '../client';

/**
 * @param {string} refer
 */
export const startSession = async (refer) => {
  const headers = {
    refer: refer
  };
  return await postRequest('start_session', null, BaseTypes.session, { headers });
};

export const getUser = async (refer) => {
  const headers = {
    refer: refer
  };
  return await basicGetRequest('user', BaseTypes.session, { headers });
};
