import { Check } from '../Check/Check';
import styles from './Option.module.scss';

export const Option = ({ color, name, onChange, isChecked = false }) => {
  return (
    <label className={styles.container_option}>
      <Check onChange={onChange} isChecked={isChecked} />
      <div className={styles.container_info}>
        {color && <div className={styles.color_option} style={{ backgroundColor: color }} />}
        {<div className={`${styles.name_option} ${isChecked && styles.checked}`}>{name}</div>}
      </div>
    </label>
  );
};
