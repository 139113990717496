import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import group18171 from 'assets/icons/group18171.svg';
import closeIcon from 'assets/icons/Icon-System-Close-16px.svg';

import reinsurance from 'assets/icons/renovations/reinsurance.svg';
import Item from './Item';
import { DownloadPdf } from './DownloandPdf';
import { DownloadExcel } from './DownloandExcel';
import styles from './Notification.module.scss';
import parseISO from 'date-fns/parseISO';
import { useNotifications } from 'components/collections/Notifications/context';
import { getDateFormatDDMesAAAA } from 'utils/getDateFormatDDMesAAAA';

const dateFormat = 'dd/MMM/yyyy - hh:mm a';

const parseTitlesNotification = (renovationInfo) => {
  const isCollectiveRenovation = renovationInfo.type === 'COLLECTIVE_RENOVATION_REPORT';
  const countSuccess = renovationInfo?.content?.totalData?.totalSuccess;
  const countErrors = renovationInfo?.content?.totalData?.totalErrors;
  let titleCard = 'Renovación masiva';
  let titleError = `${countErrors} errores de renovación`;
  let titleRenew = `${countSuccess} renovadas`;
  let fontSize = '12px';
  let totalCountPolicies = `${countErrors + countSuccess || 0} Pólizas`;

  if (countErrors === 1) titleError = `${countErrors} error de renovación`;
  if (countSuccess === 1) titleRenew = `${countSuccess} renovada`;

  if (isCollectiveRenovation) {
    titleError = `${countErrors} Certificado(s) con error`;
    titleRenew = `${countSuccess} Certificado(s) renovado(s)`;
    titleCard = 'Renovación colectiva';
    fontSize = '9px';
    totalCountPolicies = `Póliza ${renovationInfo?.content?.payload?.policyNumber}`;
  }

  return { titleError, titleRenew, titleCard, fontSize, totalCountPolicies };
};

export function RenovationNotification({ renovation }) {

  const isCollectiveRenovation = renovation.type === 'COLLECTIVE_RENOVATION_REPORT';
  const countSuccess = renovation?.content?.totalData?.totalSuccess;
  const countErrors = renovation?.content?.totalData?.totalErrors;
  const createDateFormat = format(parseISO(renovation?.createDate), 'yyyy-MM-dd');
  const { titleError, titleRenew, titleCard, fontSize, totalCountPolicies } =
    parseTitlesNotification(renovation);
  const { deleteNotification } = useNotifications();

  return (
    <Grid
      container
      style={{
        paddingLeft: '12px',
        paddingTop: '6px',
        paddingBottom: '11px',
        backgroundColor: !renovation?.content.file || renovation?.downloadAt ? '' : '#F0FBFC'
      }}
    >
      <Grid item xs={11}>
        <Grid container>
          <Grid item xs="auto" className={styles.icon}>
            <img src={group18171} className={styles.icon} alt="icon" />
          </Grid>
          <Grid item xs>
            <Grid container>
              <Grid item xs>
                <span className={styles.title}>{titleCard}</span>
              </Grid>
              <Grid item xs="auto">
                <span className={styles.titleCount}>{totalCountPolicies}</span>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.dateRoot}>
                  <span className={styles.date}>
                    {format(new Date(renovation?.createDate), dateFormat, {
                      locale: es
                    })}
                  </span>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs="auto">
                <span className={clsx(styles.line, styles.green)}></span>
              </Grid>
              <Grid item xs>
                <Item
                  icon={<img src={reinsurance} alt="icon" />}
                  button={
                    isCollectiveRenovation ? (
                      <DownloadPdf
                        disable={countSuccess == 0 || !renovation?.content.file.zipFileKey}
                        idNotification={renovation?.id}
                        createDate={createDateFormat}
                        pdfName={`${totalCountPolicies}.zip`}
                        showIconInfo={countSuccess != 0 && !renovation?.content.file.zipFileKey}
                      />
                    ) : (
                      <DownloadPdf
                        disable={countSuccess == 0 || !renovation?.content.file.zipFileKey}
                        idNotification={renovation?.id}
                        createDate={createDateFormat}
                        pdfName={`Renovaciones_${getDateFormatDDMesAAAA()}.zip`}
                        showIconInfo={countSuccess != 0 && !renovation?.content.file.zipFileKey}
                      />
                    )
                  }
                  title={titleRenew}
                  className={styles.item}
                  itemTitleStyle={{
                    fontSize: fontSize
                  }}
                />
              </Grid>
              <Grid item xs="auto">
                <span className={clsx(styles.line, styles.red)}></span>
              </Grid>
              <Grid item xs>
                <Item
                  icon={<img src={reinsurance} alt="icon" />}
                  button={
                    <DownloadExcel
                      disable={countErrors == 0 || !countErrors}
                      idNotification={renovation?.id}
                      createDate={createDateFormat}
                      excelName={`Renovación_detalle_errores_${getDateFormatDDMesAAAA()}.xlsx`}
                    />
                  }
                  title={titleError}
                  className={styles.item}
                  itemTitleStyle={{
                    fontSize: fontSize
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <img
          className={styles.icon_close}
          src={closeIcon}
          alt="closeIcon"
          onClick={() => {
            deleteNotification(renovation?.id);
          }}
        />
      </Grid>
    </Grid>
  );
}
