import { LOCAL_STORAGE_VARIABLES } from 'common/sessionStorageVariables';

export const getMassivelyRenovationPending = () =>
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_VARIABLES.MASSIVELY_RENOVATION_PENDING))?.[
    LOCAL_STORAGE_VARIABLES.MASSIVELY_RENOVATION_PENDING
  ] || [];

export const setMassivelyRenovationPending = (newPendingRenovations = []) => {
  const MRP = LOCAL_STORAGE_VARIABLES.MASSIVELY_RENOVATION_PENDING;
  localStorage.setItem(MRP, JSON.stringify({ [MRP]: [...newPendingRenovations] }));
};
