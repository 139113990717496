import clsx from 'clsx';
import { Grid } from '@material-ui/core';

import LibertyHeader from 'components/basics/LibertyHeader/LibertyHeader';
import { useSession } from 'context/SessionContext';
import advertenciaTwo from 'assets/icons/advertenciaTwo.svg';

import LibertyButton from 'components/basics/LibertyButton/LibertyButton';

import styles from './NotFound.module.scss';

const NotFound = ({ onLogout }) => {
  const { session } = useSession();
  return (
    <div>
      <LibertyHeader user={session} />
      <div className={styles.container}>
        <img className={styles.root} src={advertenciaTwo} alt="advertenciaTwo" />
      </div>
      <div className={styles.title}>No tienes permiso para acceder a esta página</div>
      <div className={styles.subtitle}>
        <span>Debes iniciar sesión desde oficina en línea</span>
      </div>
      <div className={styles.description}>
        <span>
          Si tienes alguna duda comunícate con tu ejecutivo comercial para mayor información.
        </span>
      </div>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs="auto">
          <LibertyButton id="btn_NotFound_OficinaEnLinea" className={clsx('secondary', styles.button)} onClick={onLogout}>
            Ir a oficina en línea
          </LibertyButton>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <div className={styles.line}></div>
      </Grid>

      <div className={styles.footer}>
        <span>Liberty 2022. Todos los derechos reservados</span>
      </div>
    </div>
  );
};

export default NotFound;
