import closeIcon from 'assets/icons/close-small.svg';
import styles from './Header.module.scss';
import clsx from 'clsx';
import React from 'react';

interface HeaderProps {
  onClose?: () => void;
  className?: string;
  containerClassName?: string;
  style?: React.CSSProperties;
}

export const Header = ({ onClose, containerClassName, style, className }: HeaderProps) => (
  <div className={clsx(styles.root, containerClassName)}>
    <button type="button" onClick={onClose}>
      <img src={closeIcon} alt="close" style={style} className={clsx(styles.close, className)} />
    </button>
  </div>
);

export default Header;
