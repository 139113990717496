import styles from './Check.module.scss';

export const Check = ({ onChange, isChecked, blocked }) => {
  return (
    <label className={styles.container}>
      <input
        type="checkbox"
        onChange={(e) => !blocked && onChange?.(e.target.checked)}
        checked={isChecked}
      />
      <span className={`${styles.checkmark} ${blocked && styles.blocked}`}></span>
    </label>
  );
};
