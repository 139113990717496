import { CSSProperties, ElementType, ReactNode } from 'react';

import helpIcon from 'assets/icons/help.svg';
import { OverridableComponent } from 'models/Overridable';
import LibertyTooltip from '../LibertyTooltip/LibertyTooltip';
import { ButtonTypes } from './constants';
import './LibertyButton.scss';
import clsx from 'clsx';

interface LibertyButtonProps {
  type?: ButtonTypes;
  disabled?: boolean;
  className?: any;
  id?: string;
  style?: CSSProperties;
  tooltipHelper?: boolean;
  tooltipLabel?: any;
  children: ReactNode;
}

interface LibertyButtonMap<P = Record<any, any>, D extends ElementType = 'button'> {
  props: P & LibertyButtonProps;
  defaultComponent: D;
}

const LibertyButton: OverridableComponent<LibertyButtonMap> = ({
  type,
  disabled,
  className,
  style,
  tooltipHelper = false,
  tooltipLabel,
  children,
  onClick,
  component: Component = 'button',
  ...rest
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <Component
        disabled={disabled}
        className={clsx(`libertyButton ${type} ${className}`, disabled && 'disabled')}
        style={style}
        onClick={(e) => {
          if (!disabled) onClick?.(e);
        }}
        {...rest}
      >
        {children}
      </Component>
      {tooltipHelper && (
        <div className="libertyButton__tooltip">
          <LibertyTooltip
            placement="top"
            className="libertyButton__tooltip--container"
            container={<span style={{ textAlign: 'left' }}>{tooltipLabel}</span>}
          >
            <img src={helpIcon} alt="icon" />
          </LibertyTooltip>
        </div>
      )}
    </div>
  );
};

LibertyButton.defaultProps = {
  type: ButtonTypes.primary,
  disabled: false,
  className: ''
};

export default LibertyButton;
