import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { PoliciesMassively } from 'models/renovation';
import Button from '../Button';
import styles from './DownloandPdf.module.scss';
import { useDownloadAlertsStore } from 'hooks/useDownloadAlertsStore';

interface DownloadExcelProps {
  idNotification: PoliciesMassively;
  createDate: string;
  disable: boolean;
  excelName?: string;
}

export const DownloadExcel = ({
  disable,
  idNotification,
  createDate,
  excelName = `${createDate}_errores.xlsx`
}: DownloadExcelProps) => {
  const { startDownloadExcel } = useDownloadAlertsStore();
  const [loading, setLoading] = useState(false);
  return (
    <div className={styles.root}>
      <Button
        onClick={async () => {
          setLoading(true);
          await startDownloadExcel(idNotification, excelName, { type: 'excel' });
          setLoading(false);
        }}
        disabled={disable}
      >
        Descargar excel {'>'}
      </Button>
      {loading && (
        <div className={styles.loading}>
          <CircularProgress size={'17px'} color="inherit" />
        </div>
      )}
    </div>
  );
};
