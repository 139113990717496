import { createSlice } from '@reduxjs/toolkit';
import {
  fetchLoadCertificateDetail,
  fetchLoadPoliceOthers,
  reLoadPoliceOthers
} from '../policesOthers/ActionsPoliceOthersSlice';

const initialState = {
  coverages: []
};

export const coverageSlice = createSlice({
  name: 'policyCoverages',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchLoadPoliceOthers.fulfilled, (state, { payload }) => {
      const { coverages } = payload.storageCoverageSlice;
      state.coverages = coverages;
    });
    builder.addCase(reLoadPoliceOthers.fulfilled, (state, { payload }) => {
      const { coverages } = payload.storageCoverageSlice;
      state.coverages = coverages;
    });
    builder.addCase(fetchLoadCertificateDetail.fulfilled, (state, { payload }) => {
      const { coverages } = payload.storageCoverageSlice;
      state.coverages = coverages;
    });
  },
  reducers: {
    onRestartStateCoverages: (state) => {
      state.coverages = [];
    }
  }
});

// Action creators are generated for each case reducer function
export const { onRestartStateCoverages } = coverageSlice.actions;
